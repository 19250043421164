import ClassNames from "Utils/ClassNames";
import Spinner from "./Spinner";

function LoaderSection(props: {
  title?: string;
  titlePosition?: "start" | "center" | "end";
}) {
  return (
    <div
      className={ClassNames(
        "h-full",
        "text-primary-9 bg-primary-1/50 bg-gradient-to-br from-secondary-1/50 dark:bg-accent-9 rounded-tr-leaf rounded-bl-leaf p-4",
        "text-base font-bold",
        "align-middle",
        "flex items-center",
        {
          "justify-start": props.titlePosition === "start",
          "justify-end": props.titlePosition === "end",
          "justify-center": !props.titlePosition || props.titlePosition === "center",
        }
      )}
    >
      <Spinner size="lg" />
      {props.title && <span className="ml-3">{props.title}</span>}
    </div>
  );
}

export default LoaderSection;
