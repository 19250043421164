import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FlexCol, FlexRow } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import { NavLink } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";
import { Nullable } from "Utils/HelperTypes";
import {
  DivProps,
  HeadingProps,
  LegendProps,
  NavLinkProps,
  ParagraphProps,
} from "Utils/ReactElementsTypes";
import { ButtonProps } from "Utils/ReactElementsTypes";
import ItemDetails from "./ItemDetails";
import Leaf from "./Leaf";
import LeafStyles from "./LeafStyles.module.css";

function Details(props: DivProps) {
  const { className, ...rest } = props;
  return <FlexCol {...rest} className={ClassNames(className, "lg:justify-between gap-8")} />;
}

Details.Item = ItemDetails;

function DetailsChildList(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexCol
      {...rest}
      wrap={false}
      className={ClassNames(
        className,
        "w-full max-w-[360px] gap-6 px-4 pt-9 pb-8 max-h-[90vh] mt-4 lg:mt-6 lg:min-h-[70vh]",
        "items-center",
        "overflow-y-auto",
        LeafStyles.leafRightLg,
        LeafStyles.leafUi
      )}
    />
  );
}

Details.ChildList = DetailsChildList;

function DetailsChildListLegend(props: LegendProps) {
  const { className, ...rest } = props;
  return (
    <legend
      className={ClassNames(
        "absolute flex",
        "min-w-[220px] max-w-[310px] p-2 font-bold -mt-14 ml-4 self-center text-center rounded-full",
        "bg-secondary-3 text-white",
        className
      )}
      {...rest}
    />
  );
}

DetailsChildList.Legend = DetailsChildListLegend;

type LegendActionProps = Omit<ButtonProps & { to?: string }, "children" | "className">;

function DetailsChildListLegendAddButton(props: LegendActionProps) {
  if (props.to)
    return (
      <NavLink
        className={ClassNames(
          LeafStyles.leafLegendaryAddButton,
          LeafStyles.leafLegendAddButtonSecondary
        )}
        {...(props as NavLinkProps)}
      >
        <FontAwesome className="" icon={solid("plus")} />
      </NavLink>
    );
  return (
    <button
      className={ClassNames(
        LeafStyles.leafLegendaryAddButton,
        LeafStyles.leafLegendAddButtonSecondary
      )}
      {...(props as ButtonProps)}
    >
      <FontAwesome icon={solid("plus")} />
    </button>
  );
}

DetailsChildListLegend.AddButton = DetailsChildListLegendAddButton;

function DetailsChildListLegendHeading(props: HeadingProps) {
  const { className, ...rest } = props;
  return (
    <span
      {...rest}
      className={ClassNames(
        "md:text-lg md:leading-6 text-inherit mx-auto -translate-x-1 md:-translate-x-2",
        className
      )}
    />
  );
}

DetailsChildListLegend.Heading = DetailsChildListLegendHeading;

function DetailsSubDetails(props: DivProps) {
  const { className, ...rest } = props;
  return <FlexRow wrap {...rest} className={ClassNames(className, "gap-8")} />;
}

Details.SubDetails = DetailsSubDetails;

export default Details;
