import { useFormikContext } from "formik";
import { HasErrors, NavigateToFirstErrorField } from "../../Utils/FormikErrorUtils";

interface PreventableEvent {
  preventDefault: () => void;
}

interface SubmitField {
  onClick: (e: PreventableEvent) => boolean | void | undefined;
  onSubmit: (e: PreventableEvent) => boolean | void | undefined;
  type: "submit";
}

const handleSubmit = (e: PreventableEvent, isValid?: boolean) => {
  if (HasErrors() || !isValid) {
    e.preventDefault();
    NavigateToFirstErrorField();
    return false;
  }
  return true;
};

export function FormikSubmitField(props: {
  children: (props: {
    field: SubmitField;
    isSubmitting: boolean;
    handleSubmit: (e: PreventableEvent) => boolean;
  }) => JSX.Element;
}) {
  const { isValid, isSubmitting } = useFormikContext();
  const submit = (e: PreventableEvent) => handleSubmit(e, isValid);
  const field: SubmitField = {
    onClick: (e: PreventableEvent) => handleSubmit(e, isValid),
    onSubmit: submit,
    type: "submit",
  };

  return props.children({ field, isSubmitting, handleSubmit: submit });
}
