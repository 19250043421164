import { Component, ReactNode } from "react";

class ErrorBoundary extends Component<
  Record<string, JSX.Element | ReactNode>,
  { hasError: boolean }
> {
  constructor(props: Record<string, JSX.Element | ReactNode>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    console.error(error); // Update state so the next render will show the fallback UI.
    return { hasError: true };
  } // You can also log the error to an error reporting service
  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    console.error(error);
    console.error(errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <span>failed loading this component.</span>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
