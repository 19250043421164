import { Outlet } from "react-router-dom";
import AppHeading from "Components/Project/AppHeading";
import { useStore } from "App/Stores/StoreContext";
import { useEffect } from "react";
import LoaderSection from "Components/UI/LoaderSection";

function CompaniesPage() {
  const { loadItems, loadingItems } = useStore("UsersStore");

  useEffect(() => {
    if (!loadingItems) loadItems();
  });
  return (
    <>
      <AppHeading title="Users" newPath="/app/users/new" />
      {loadingItems === true && <LoaderSection />}
      {loadingItems !== true && <Outlet />}
    </>
  );
}

export default CompaniesPage;
