import { Outlet } from "react-router-dom";
import AppHeading from "Components/Project/AppHeading";

function ProjectsPage() {
  return (
    <>
      <AppHeading title="Projects" newPath="/app/projects/new" />
      <Outlet></Outlet>
    </>
  );
}

export default ProjectsPage;
