import { LabelHTMLAttributes } from "react";
import ClassNames from "../../Utils/ClassNames";
import { Variant } from "./UI";

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  variant?: Variant;
}

export default function Label(props: LabelProps) {
  const { children, variant, className, ...rest } = props;
  return (
    <label
      {...rest}
      className={ClassNames(
        { "text-black dark:text-white": !variant },
        { "text-primary-6 dark:text-primary-4": variant === "primary" },
        { "text-secondary-6 dark:text-secondary-4": variant === "secondary" },
        { "text-accent-6 dark:text-accent-4": variant === "accent" },
        { "text-warning-6 dark:text-warning-4": variant === "warning" },
        { "text-danger-6 dark:text-danger-4": variant === "danger" },
        { "text-success-6 dark:text-success-4": variant === "success" },
        { "text-info-6 dark:text-info-4": variant === "info" },
        className
      )}
    >
      {children}
    </label>
  );
}
