import * as Yup from "yup";
import {
  ApiPromise,
  ApiQuery,
  ProductStatus,
  RemovalStatus,
  RestoreStatus,
} from "App/Models";
import { ApiDate, Nullable } from "Utils/HelperTypes";
import YupValidations from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";

// *******************************************************
// * Product                                             *
// *******************************************************

const basePath = "/product";

export interface Product {
  id: string;
  name: string;
  details?: Nullable<string>;
  photoUrl?: Nullable<string>;
  categoryName: string;
  status: ProductStatus;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

// *****************************************
// * Add Product

export interface AddProductData {
  categoryName: string;
  name: string;
  details?: Nullable<string>;
  status: ProductStatus;
}

export const AddProductDataValidator: Yup.SchemaOf<AddProductData> = Yup.object({
  categoryName: YupValidations.RequiredString("Category"),
  name: YupValidations.RequiredName("Name"),
  details: YupValidations.OptionalDetails("Details"),
  status: YupValidations.RequiredEnum("Product Status", ProductStatus),
});

export interface Api {
  AddProduct: ApiPromise<Product, AddProductData>;
}

// *****************************************
// * Edit Product

export interface EditProductData {
  data: Product;
}

export interface Api {
  EditProduct: ApiPromise<Product, EditProductData>;
}

// *****************************************
// * List Products

export type ListProductsQuery = ApiQuery;

export interface Api {
  ListProduct: ApiPromise<Product[], ListProductsQuery>;
}

// *****************************************
// * Remove & Restore Product

export interface RemoveProductData {
  id: string;
}

export interface RestoreProductData {
  id: string;
}

export interface Api {
  RemoveProduct: ApiPromise<RemovalStatus, RemoveProductData>;
  RestoreProduct: ApiPromise<RestoreStatus, RestoreProductData>;
}

// *******************************************************
// * Product Category                                    *
// *******************************************************

export interface ProductCategory {
  name: string;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

export interface ProductCategoryDetails extends ProductCategory {
  /** @format date-time */
  createdAt: ApiDate;

  products?: Nullable<Product[]>;
}

// *****************************************
// * Add Product Category

export interface AddProductCategoryData {
  name: string;
}

export const AddProductCategoryDataValidator: Yup.SchemaOf<AddProductCategoryData> =
  Yup.object({
    name: YupValidations.RequiredName("Name"),
  });

export interface Api {
  AddProductCategory: ApiPromise<ProductCategory, AddProductCategoryData>;
}

// *****************************************
// * List Product Categories

export type ListProductCategoriesQuery = ApiQuery;

export interface Api {
  ListProductCategories: ApiPromise<ProductCategory[], ListProductCategoriesQuery>;
}

// *****************************************
// * Remove & Restore Product Categories

export interface RemoveProductCategoryData {
  name: string;
}

export interface RestoreProductCategoryData {
  name: string;
}

export interface Api {
  RemoveProductCategory: ApiPromise<RemovalStatus, RemoveProductCategoryData>;
  RestoreProductCategory: ApiPromise<RestoreStatus, RestoreProductCategoryData>;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  // ** Categories **
  AddProductCategory: async (data: AddProductCategoryData) =>
    await AxiosRequests.post<ProductCategory>(`${basePath}/categories`, data),
  ListProductCategories: async (query?: ListProductCategoriesQuery) =>
    await AxiosRequests.get<ProductCategory[]>(`${basePath}/categories`, query),
  RemoveProductCategory: async (data: RemoveProductCategoryData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/categories`, data),
  RestoreProductCategory: async (data: RestoreProductCategoryData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/categories/restore`, data),
  // ** Products **
  AddProduct: async (data: AddProductData) =>
    await AxiosRequests.post<Product>(basePath, data),
  ListProduct: async (query?: ListProductsQuery) =>
    await AxiosRequests.get<Product[]>(basePath, query),
  EditProduct: async (data: EditProductData) =>
    await AxiosRequests.put<Product>(basePath, data),
  RemoveProduct: async (data: RemoveProductData) =>
    await AxiosRequests.delete<RemovalStatus>(basePath, data),
  RestoreProduct: async (data: RestoreProductData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/restore`, data),
};
