import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FlexCol, FlexRow } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import { NavLink } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";
import { Nullable } from "Utils/HelperTypes";
import { DivProps, HeadingProps, ParagraphProps } from "Utils/ReactElementsTypes";

function ItemDetails(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexCol
      {...rest}
      className={ClassNames(className, "w-full max-w-[640px] gap-4 md:gap-10")}
    />
  );
}

function ItemDetailsMain(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        className,
        "bg-white border shadow-ui rounded-tr-leaf rounded-bl-leaf max-w-[640px] pt-4 pb-6 pl-8 pr-6"
      )}
    />
  );
}

ItemDetails.Main = ItemDetailsMain;

function DetailsMainTitle(props: HeadingProps) {
  const { className, ...rest } = props;
  return (
    <h2
      {...rest}
      className={ClassNames(className, "font-bold text-secondary-4 text-2xl m-0 lg:mb-4 p-0")}
    />
  );
}

ItemDetailsMain.Title = DetailsMainTitle;

export type ItemDetailsDetailProps = {
  icon?: Nullable<IconProp>;
  title?: Nullable<string>;
  info?: Nullable<string | number>;
};

function ItemDetailsMainDetailsList(props: DivProps & { values?: ItemDetailsDetailProps[] }) {
  const { values, children, ...rest } = props;
  return (
    <div {...rest}>
      {children}
      {!!values?.length && (
        <For each={values}>{(item) => <ItemDetailsMainDetailsList.Detail {...item} />}</For>
      )}
    </div>
  );
}

ItemDetailsMain.DetailsList = ItemDetailsMainDetailsList;

function ItemDetailsMainDetail(props: Omit<DivProps, "title"> & ItemDetailsDetailProps) {
  const { className, children, icon, title, info, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        className,
        "group text-base text-primary-6 hover:bg-primary-1/20",
        "w-4/5 lg:pl-2 lg:pr-4 py-2 lg:py-0 lg:mb-2 border-b last:border-b-0 lg:border-none"
      )}
    >
      {!!icon && <ItemDetailsMainDetail.Icon icon={icon} />}
      {!!title && <ItemDetailsMainDetail.Title>{title}</ItemDetailsMainDetail.Title>}
      {!!info && <ItemDetailsMainDetail.Info>{info}</ItemDetailsMainDetail.Info>}
      {children}
    </div>
  );
}

ItemDetailsMain.Detail = ItemDetailsMainDetail;
ItemDetailsMainDetailsList.Detail = ItemDetailsMainDetail;

function ItemDetailsMainDetailIcon(props: FontAwesomeIconProps) {
  const { className, ...rest } = props;
  return (
    <FontAwesome
      {...rest}
      className={ClassNames(
        className,
        "inline-block group-hover:text-primary-6 text-gray-5 mr-1 md:mr-2 w-6"
      )}
    />
  );
}

ItemDetailsMainDetail.Icon = ItemDetailsMainDetailIcon;

function ItemDetailsMainDetailTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p
      {...rest}
      className={ClassNames(
        className,
        "text-primary-6 font-bold inline-block text-xl mr-1 md:mr-2"
      )}
    />
  );
}

ItemDetailsMainDetail.Title = ItemDetailsMainDetailTitle;

function ItemDetailsMainDetailInfo(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={ClassNames(className, "text-primary-6 text-xl inline")} />;
}

ItemDetailsMainDetail.Info = ItemDetailsMainDetailInfo;

function ItemDetailsMainActions(props: Omit<DivProps, "className">) {
  return (
    <FlexRow
      {...props}
      className={ClassNames("gap-4 justify-end", "mt-4 md:mt-2 -mb-2 md:-mb-10")}
    />
  );
}

ItemDetailsMain.Actions = ItemDetailsMainActions;

function ItemDetailsMainActionsEditButton() {
  return (
    <NavLink
      to="edit"
      className={ClassNames(
        "group transition-all text-center inline",
        "px-4 py-2 min-w-[94px]",
        "rounded-full font-bold",
        "bg-secondary-4 text-white",
        "hover:bg-secondary-5 focus-visible:bg-secondary-6 active:bg-secondary-7"
      )}
    >
      <FontAwesome icon={solid("pen")} size="lg" className="group-hover:inline hidden" />
      <span className="group-hover:hidden inline">Edit</span>
    </NavLink>
  );
}

ItemDetailsMainActions.Edit = ItemDetailsMainActionsEditButton;

function ItemDetailsExtra(props: DivProps & { values?: ItemDetailsDetailProps[] }) {
  const { className, values, children, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        className,
        "bg-white border shadow-ui rounded-tr-leaf rounded-bl-leaf max-w-[640px] px-6 py-4 lg:py-6"
      )}
    >
      {children}
      {!!values?.length && (
        <For each={values}>{(item) => <ItemDetailsExtra.Detail {...item} />}</For>
      )}
    </div>
  );
}

ItemDetails.Extra = ItemDetailsExtra;

function ItemDetailsExtraDetail(props: Omit<DivProps, "title"> & ItemDetailsDetailProps) {
  const { className, children, icon, title, info, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        className,
        "group text-base text-primary-6 hover:bg-primary-1/20",
        "lg:pl-2 lg:pr-4 py-2 border-t first:border-t-0 first:rounded-tr-3xl last:rounded-bl-3xl"
      )}
    >
      {!!icon && <ItemDetailsExtraDetail.Icon icon={icon} />}
      {!!title && <ItemDetailsExtraDetail.Title>{title}</ItemDetailsExtraDetail.Title>}
      {!!info && <ItemDetailsExtraDetail.Info>{info}</ItemDetailsExtraDetail.Info>}
      {children}
    </div>
  );
}

ItemDetailsExtra.Detail = ItemDetailsExtraDetail;

function ItemDetailsExtraDetailIcon(props: FontAwesomeIconProps) {
  const { className, ...rest } = props;
  return (
    <FontAwesome
      {...rest}
      className={ClassNames(
        className,
        "inline-block group-hover:text-primary-6 text-gray-5 mr-1 md:mr-2 w-6"
      )}
    />
  );
}

ItemDetailsExtraDetail.Icon = ItemDetailsExtraDetailIcon;

function ItemDetailsExtraDetailTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p
      {...rest}
      className={ClassNames(
        className,
        "text-primary-6 font-bold inline-block text-xl mr-1 md:mr-2"
      )}
    />
  );
}

ItemDetailsExtraDetail.Title = ItemDetailsExtraDetailTitle;

function ItemDetailsExtraDetailInfo(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={ClassNames(className, "text-primary-6 text-xl inline")} />;
}

ItemDetailsExtraDetail.Info = ItemDetailsExtraDetailInfo;

export default ItemDetails;
