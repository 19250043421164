import { useStore } from "App/Stores/StoreContext";
import { observer } from "mobx-react-lite";

export default observer(() => {
  const { AppIsOnline } = useStore("CommonStore");
  return (
    <>
      {!AppIsOnline && (
        <button
          className="z-[99999999] fixed w-screen h-screen top-0 left-0 bg-secondary-6/80 text-white"
          autoFocus
          disabled
        >
          {"You're"} Offline! <div>Reconnect to the internet to continue using the app.</div>
        </button>
      )}
    </>
  );
});
