import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
// import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Sleep from "Utils/Sleep";
import { useStore } from "App/Stores/StoreContext";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { history } from "index";
import LoaderSection from "Components/UI/LoaderSection";
import { FormatDate } from "Utils/DateUtils";
import { ProductStatus } from "App/Models";

export function ProductDetail() {
  const prodId =
    useParams()["prodId"] ?? (history.push("/app/not-found") as unknown as string);

  const productStore = useStore("ProductStore");

  const selectedProduct = useMemo(
    () => productStore.itemsMap.get(prodId)?.data,
    [prodId, productStore]
  );

  useEffect(() => {
    const prod = productStore.itemsMap.get(prodId);
    if (!prod || !prod.detailsLoaded) productStore.loadItems();
  }, [prodId, productStore]);

  return (
    <>
      {!selectedProduct && <LoaderSection title="Loading Product..." />}
      {!!selectedProduct && (
        <Details>
          <Details.Item>
            <Details.Item.Main>
              <Details.Item.Main.Title>{selectedProduct.name}</Details.Item.Main.Title>
              <Details.Item.Main.DetailsList
                values={[
                  {
                    icon: solid("info-circle"),
                    title: "category",
                    info: selectedProduct.categoryName,
                  },
                  {
                    icon: solid("info-circle"),
                    title: "status",
                    info: ProductStatus[selectedProduct.status],
                  },
                ]}
              />
              <Details.Item.Main.Actions>
                <Details.Item.Main.Actions.Edit />
                <PopUp actions={{ ok: () => productStore.removeItem(prodId).then() }}>
                  <PopUp.Open.AsDeleteButton />
                  <PopUp.DeleteDialog
                    title="Delete This"
                    message="Are you sure you want to remove that?"
                  />
                </PopUp>
              </Details.Item.Main.Actions>
            </Details.Item.Main>
            <Details.Item.Extra
              values={[
                {
                  icon: solid("info"),
                  title: "Created On",
                  info: FormatDate(selectedProduct.createdAt),
                },
                ...(selectedProduct.details
                  ? [
                      {
                        icon: solid("info"),
                        title: "Details",
                        info: selectedProduct.details,
                      },
                    ]
                  : []),
                ...(selectedProduct.isRemoved
                  ? [
                      {
                        icon: solid("x"),
                        title: "Pending Remove",
                      },
                      {
                        icon: solid("x"),
                        info: "Must Remove all projects using this item first.",
                      },
                    ]
                  : []),
              ]}
            />
          </Details.Item>
          {/* <Details.ChildList>
            <Details.ChildList.Legend>
              <Details.ChildList.Legend.AddButton to="/" />
              <Details.ChildList.Legend.Heading>Longer</Details.ChildList.Legend.Heading>
            </Details.ChildList.Legend>
            <Summary.Card>
              <Summary.Card.Title>Title</Summary.Card.Title>
              <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
              <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
            </Summary.Card>
            <Summary.Card>
              <Summary.Card.Title>Title</Summary.Card.Title>
              <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
              <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
            </Summary.Card>
            <Summary.Card>
              <Summary.Card.Title>Title</Summary.Card.Title>
              <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
              <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
            </Summary.Card>
            <Summary.Card>
              <Summary.Card.Title>Title</Summary.Card.Title>
              <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
              <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
            </Summary.Card>
            <Summary.Card>
              <Summary.Card.Title>Title</Summary.Card.Title>
              <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
              <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
            </Summary.Card>
          </Details.ChildList> */}
        </Details>
      )}
    </>
  );
}
