import { UserManagementAgent } from "App/Api";
import { AddProjectModeratorDataValidator } from "App/Api/ProjectAgent";
import { ModeratorRole, User } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import { LeafFormStyles } from "Assets/Styles/Styles";
import FormikAlert from "Components/Forms/FormikAlert";
import FormikField from "Components/Forms/FormikField";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import { Form, Formik } from "formik";
import { pushToNotFound } from "index";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";
import _ from "lodash";
import { EnumAsOptions } from "Utils/ReactElementUtils";

function ProjectAddEdit() {
  const { addModerator, newItemModerator, getItem } = useStore("ProjectStore");
  const projId = useParams()["projId"] ?? pushToNotFound();
  const [searchUsers, setSearchUsers] = useState<User[]>();

  const loadUsers = (val: string) => {
    UserManagementAgent.Api.SearchByEmail({ email: val }).then((v) => setSearchUsers(v));
  };

  return (
    <>
      <AddEdit>
        <Formik
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={addModerator}
          initialValues={newItemModerator(projId)}
          validationSchema={AddProjectModeratorDataValidator}
        >
          {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
            <Form
              onSubmit={handleSubmit}
              onBlur={handleBlur}
              onReset={handleReset}
              onChange={handleChange}
            >
              <fieldset className={ClassNames(LeafFormStyles.FieldSet, "group")}>
                <label
                  className={ClassNames(
                    LeafFormStyles.Label,
                    "group-hover:text-primary-7 group-focus-within:text-primary-8"
                  )}
                >
                  Project
                </label>
                <input
                  disabled
                  value={getItem(projId)?.data.name ?? "-"}
                  className={ClassNames(LeafFormStyles.Input)}
                />
              </fieldset>
              <FormikField name="userEmail">
                {({ field, meta, isInvalid, isValid }) => (
                  <>
                    <fieldset className={ClassNames(LeafFormStyles.FieldSet)}>
                      <label className={ClassNames(LeafFormStyles.Label)}>User Email</label>
                      <input
                        {...field}
                        type="search"
                        list="userEmailList"
                        onChange={(e) => {
                          field.onChange(e);
                          loadUsers(e.target.value);
                        }}
                        className={ClassNames(LeafFormStyles.Input, {
                          "border-danger-5": isInvalid,
                        })}
                      />
                      <datalist id="userEmailList">
                        <For each={searchUsers}>
                          {(item) => (
                            <option
                              title={item.displayName ?? ""}
                              label={(item.displayName ?? "") + " - " + (item.email ?? "")}
                              value={item.email ?? ""}
                            >
                              {(item.displayName ?? "") + " - " + (item.email ?? "")}
                            </option>
                          )}
                        </For>
                      </datalist>
                      <div className={isValid ? "hidden" : "w-full"}>
                        <div className={ClassNames(LeafFormStyles.ErrorFeedback)}>
                          {meta.error}
                        </div>
                      </div>
                    </fieldset>
                  </>
                )}
              </FormikField>
              <AddEdit.SelectField name="role" label="Role">
                <AddEdit.SelectOption
                  label="Select Role..."
                  title="Select Role..."
                  value={ModeratorRole.None}
                />
                <EnumAsOptions enum={ModeratorRole} />
              </AddEdit.SelectField>
              <AddEdit.TextField name="details" placeholder="Details" label="Details" />

              <FormikAlert />

              <AddEdit.Actions>
                <FormikSubmitField>
                  {({ field, isSubmitting }) => (
                    <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                  )}
                </FormikSubmitField>
                <AddEdit.Actions.Cancel />
              </AddEdit.Actions>
            </Form>
          )}
        </Formik>
      </AddEdit>
    </>
  );
}

export default observer(ProjectAddEdit);
