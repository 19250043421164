import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import Leaf from "Components/Project/Leaf";
import LoaderSection from "Components/UI/LoaderSection";
import SubscriptionsCategories from "../Subscriptions/SubscriptionsCategories";
import { NewSubscriptionCategoryModal } from "./NewSubscriptionCategoryModal";
import { FlexCol } from "Components/Grid/FlexBox";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Subscription } from "App/Api/SubscriptionAgent";

export default observer(function SubscriptionsList() {
  const { itemsByCategory, filterBy, loadingItems } = useStore("SubscriptionStore");
  return (
    <>
      <FlexCol md="rowRev" wrap className="w-full items-center md:items-start">
        <FlexCol className="w-full md:w-[30%] md:h-full md:min-h-[70vh] md:max-h-[90vh] md:overflow-y-auto min-w-[280px] max-w-[360px] rounded-tl-leaf rounded-br-leaf bg-white mt-6 border shadow-ui pt-10 pb-8 px-6">
          <Leaf.Legend className={Leaf.className.Legend.Primary}>
            <NewSubscriptionCategoryModal />
          </Leaf.Legend>
          <SubscriptionsCategories />
        </FlexCol>
        <Summary className="w-full md:w-[55%] lg:w-[68%] md:mr-4 py-4 md:py-0">
          {loadingItems === true && <LoaderSection title="Loading Subscriptions..." />}
          {loadingItems === "success" && (
            <For each={itemsByCategory(filterBy)}>{SubscriptionSummary}</For>
          )}
        </Summary>
      </FlexCol>
    </>
  );
});

function SubscriptionSummary(item: Subscription) {
  return (
    <Summary.Card to={item.id} key={item.id}>
      <Summary.Card.Title>{item.name}</Summary.Card.Title>
      {item.details && (
        <Summary.Card.Detail
          icon={solid("info-circle")}
          title="Details:"
          info={item.details}
        />
      )}
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category:"
        info={item.categoryName}
      />
      <Summary.Card.Detail />
      <Summary.Card.FooterImage
        fallbackIcon={solid("building")}
        // src="http://placekitten.com/300/500"
        // src="http://placekitten.com/300/300"
        // src="http://via.placeholder.com/640x360"
      />
    </Summary.Card>
  );
}
