import * as Yup from "yup";
import {
  ApiPromise,
  ApiQuery,
  SubscriptionStatus,
  RemovalStatus,
  RestoreStatus,
} from "App/Models";
import { ApiDate, Nullable } from "Utils/HelperTypes";
import YupValidations from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";

// *******************************************************
// * Subscription                                             *
// *******************************************************

const basePath = "/subscription";

export interface Subscription {
  id: string;
  name: string;
  details?: Nullable<string>;
  photoUrl?: Nullable<string>;
  categoryName: string;
  status: SubscriptionStatus;

  /** @format double */
  cost: number;
  costCurrencyName: string;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

// *****************************************
// * Add Subscription

export interface AddSubscriptionData {
  categoryName: string;
  name: string;
  details?: Nullable<string>;
  status: SubscriptionStatus;

  /** @format double */
  cost: number;
  costCurrencyName: string;
}

export const AddSubscriptionDataValidator: Yup.SchemaOf<AddSubscriptionData> = Yup.object({
  categoryName: YupValidations.RequiredString("Category"),
  name: YupValidations.RequiredName(),
  details: YupValidations.OptionalDetails(),
  status: YupValidations.RequiredEnum("Subscription Status", SubscriptionStatus),
  cost: YupValidations.Money("Cost"),
  costCurrencyName: YupValidations.RequiredString("Currency").default("USD"),
});

export interface Api {
  AddSubscription: ApiPromise<Subscription, AddSubscriptionData>;
}

// *****************************************
// * Edit Subscription

export interface EditSubscriptionData {
  data: Subscription;
}

export interface Api {
  EditSubscription: ApiPromise<Subscription, EditSubscriptionData>;
}

// *****************************************
// * List Subscriptions

export type ListSubscriptionsQuery = ApiQuery;

export interface Api {
  ListSubscription: ApiPromise<Subscription[], ListSubscriptionsQuery>;
}

// *****************************************
// * Remove & Restore Subscription

export interface RemoveSubscriptionData {
  id: string;
}

export interface RestoreSubscriptionData {
  id: string;
}

export interface Api {
  RemoveSubscription: ApiPromise<RemovalStatus, RemoveSubscriptionData>;
  RestoreSubscription: ApiPromise<RestoreStatus, RestoreSubscriptionData>;
}

// *******************************************************
// * Subscription Category                                    *
// *******************************************************

export interface SubscriptionCategory {
  name: string;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

export interface SubscriptionCategoryDetails extends SubscriptionCategory {
  /** @format date-time */
  removedAt: ApiDate;

  subscriptions?: Nullable<Subscription[]>;
}

// *****************************************
// * Add Subscription Category

export interface AddSubscriptionCategoryData {
  name: string;
}

export const AddSubscriptionCategoryDataValidator: Yup.SchemaOf<AddSubscriptionCategoryData> =
  Yup.object({
    name: YupValidations.RequiredName("Category Name"),
  });

export interface Api {
  AddSubscriptionCategory: ApiPromise<SubscriptionCategory, AddSubscriptionCategoryData>;
}

// *****************************************
// * List Subscription Categories

export type ListSubscriptionCategoriesQuery = ApiQuery;

export interface Api {
  ListSubscriptionCategories: ApiPromise<
    SubscriptionCategory[],
    ListSubscriptionCategoriesQuery
  >;
}

// *****************************************
// * Remove & Restore Subscription Categories

export interface RemoveSubscriptionCategoryData {
  name: string;
}

export interface RestoreSubscriptionCategoryData {
  name: string;
}

export interface Api {
  RemoveSubscriptionCategory: ApiPromise<RemovalStatus, RemoveSubscriptionCategoryData>;
  RestoreSubscriptionCategory: ApiPromise<RestoreStatus, RestoreSubscriptionCategoryData>;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  // ** Categories **
  AddSubscriptionCategory: async (data: AddSubscriptionCategoryData) =>
    await AxiosRequests.post<SubscriptionCategory>(`${basePath}/categories`, data),
  ListSubscriptionCategories: async (query?: ListSubscriptionCategoriesQuery) =>
    await AxiosRequests.get<SubscriptionCategory[]>(`${basePath}/categories`, query),
  RemoveSubscriptionCategory: async (data: RemoveSubscriptionCategoryData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/categories`, data),
  RestoreSubscriptionCategory: async (data: RestoreSubscriptionCategoryData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/categories/restore`, data),
  // ** Subscriptions **
  AddSubscription: async (data: AddSubscriptionData) =>
    await AxiosRequests.post<Subscription>(basePath, data),
  ListSubscription: async (query?: ListSubscriptionsQuery) =>
    await AxiosRequests.get<Subscription[]>(basePath, query),
  EditSubscription: async (data: EditSubscriptionData) =>
    await AxiosRequests.put<Subscription>(basePath, data),
  RemoveSubscription: async (data: RemoveSubscriptionData) =>
    await AxiosRequests.delete<RemovalStatus>(basePath, data),
  RestoreSubscription: async (data: RestoreSubscriptionData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/restore`, data),
};
