import {
  CreateNewUserDataValidator,
  RegisterNewAdminData,
  RegisterNewAdminDataValidator,
} from "App/Api/UserManagementAgent";
import { Gender } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import { Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import ClassNames from "Utils/ClassNames";

function UserAddEdit() {
  const { addNewAdmin, addNewItem, getNewOrExistingItem } = useStore("UsersStore");
  const [isAdmin, setIsAdmin] = useState(false);
  const handleSubmit = useCallback(
    async <T,>(v: RegisterNewAdminData, h: FormikHelpers<T>) => {
      h.setSubmitting(true);
      try {
        isAdmin ? await addNewAdmin(v, h) : await addNewItem(v, h);
      } finally {
        h.setSubmitting(false);
      }
    },
    [isAdmin, addNewAdmin, addNewItem]
  );
  return (
    <>
      <AddEdit>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ ...getNewOrExistingItem(), password: "" }}
          validationSchema={
            isAdmin ? RegisterNewAdminDataValidator : CreateNewUserDataValidator
          }
        >
          {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
            <Form
              onSubmit={handleSubmit}
              onBlur={handleBlur}
              onReset={handleReset}
              onChange={handleChange}
            >
              <AddEdit.TextField name="displayName" label="Display Name" placeholder="name" />
              <AddEdit.TextField
                name="email"
                placeholder="Email"
                label="Email"
                type="email"
              />
              <fieldset className="flex items-center mb-4 ">
                <input
                  type="checkbox"
                  value={isAdmin ? 1 : 0}
                  onClick={() => setIsAdmin((v) => !v)}
                  className="mr-2 p-0"
                />
                <label
                  className={ClassNames(
                    {
                      "text-gray-5": !isAdmin,
                      "text-primary-6": isAdmin,
                    },
                    "font-bold"
                  )}
                >
                  {isAdmin ? "Admin" : "Not an Admin"}
                </label>
              </fieldset>
              {isAdmin && (
                <AddEdit.TextField
                  name="password"
                  placeholder="Password"
                  label="Password"
                  type="password"
                />
              )}
              <hr className="mt-2 mb-4" />
              <AddEdit.TextField name="firstName" label="First Name" placeholder="name" />
              <AddEdit.TextField name="lastName" label="Last Name" placeholder="name" />
              <AddEdit.TextField name="phone" label="Phone" placeholder="Phone" />
              <AddEdit.SelectField name="gender" label="Gender" placeholder="Gender">
                <>
                  <AddEdit.SelectInput.Option
                    value={Gender.None}
                    label="Select Gender..."
                    title="Select Gender..."
                  />
                  <AddEdit.SelectInput.Option value={Gender.Male} label="Male" title="Male" />
                  <AddEdit.SelectInput.Option
                    value={Gender.Female}
                    label="Female"
                    title="Female"
                  />
                </>
              </AddEdit.SelectField>

              <FormikAlert />

              <AddEdit.Actions>
                <FormikSubmitField>
                  {({ field, isSubmitting }) => (
                    <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                  )}
                </FormikSubmitField>
                <AddEdit.Actions.Cancel />
              </AddEdit.Actions>
            </Form>
          )}
        </Formik>
      </AddEdit>
    </>
  );
}

export default observer(UserAddEdit);
