import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { useStore } from "App/Stores/StoreContext";
import NavigationBar from "Components/Project/UserSpace/NavigationBar";

export default observer(function UserSpace() {
  const userStore = useStore("AccountStore");
  if (!userStore.isLoggedIn) return <NavigateToLogin />;
  return (
    <>
      <NavigationBar
        userEmail={userStore.userEmail ?? ""}
        userImg={userStore.userPhoto}
        userDispName={userStore.userDisplayName ?? ""}
        userLogout={userStore.logout}
      ></NavigationBar>
      <div className="min-h-screen p-3 md:pl-28">
        <Outlet />
      </div>
    </>
  );
});

const NavigateToLogin = () => {
  return <Navigate to="/" />;
};
