import { useStore } from "App/Stores/StoreContext";
import { observer } from "mobx-react-lite";
import ClassNames from "Utils/ClassNames";

export default observer(function NotificationArea() {
  const notificationStore = useStore("NotificationStore");
  return (
    <div
      className={ClassNames("font-bold drop-shadow-sm border-b py-4", {
        "text-gray-8/75": notificationStore.newNotifications.length > 0,
        "text-gray-6/75": notificationStore.newNotifications.length === 0,
      })}
    >
      {notificationStore.newNotifications.length === 0 &&
        "There are no new notifications so far."}
      {notificationStore.newNotifications.length > 0 && (
        <>
          You have{" "}
          <span className="text-accent-6">{notificationStore.newNotifications.length}</span>{" "}
          new notification
          {notificationStore.newNotifications.length > 1 ? "s" : null}.
        </>
      )}
    </div>
  );
});
