import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useStore } from "App/Stores/StoreContext";
import AppHeading from "Components/Project/AppHeading";

function SubscriptionsPage() {
  const subscriptionStore = useStore("SubscriptionStore");
  useEffect(() => {
    if (!subscriptionStore.loadingCategories) subscriptionStore.loadCategories();
    if (!subscriptionStore.loadingItems) subscriptionStore.loadItems();
  });
  return (
    <>
      <AppHeading title="Subscriptions" newPath="/app/subscriptions/new" />
      <Outlet />
    </>
  );
}

export default SubscriptionsPage;
