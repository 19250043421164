import HeroLeftBG from "./Images/HeroLeftBg.png";
import Logo from "./Images/Logo.png";
import LeftHeroMask from "./Images/LeftHeroMask.svg";
import TopHeroMask from "./Images/TopHeroMask.svg";
import MobileHeroTop from "./Images/MobilePageHeroTop.png";
import MobileHeroBottom from "./Images/MobilePageHeroBottom.png";
import UserPlaceHolder from "./Images/userPlaceHolder.png";
const Images = {
  HeroLeftBG,
  Logo,
  LeftHeroMask,
  TopHeroMask,
  UserPlaceHolder,
  Mobile: {
    HeroTop: MobileHeroTop,
    HeroBottom: MobileHeroBottom,
  },
};

const Audios = {};

const Videos = {};

const Icons = {};

const Styles = {};

export default {
  Images,
  Audios,
  Videos,
  Icons,
  Styles,
};
