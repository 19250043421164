import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import ErrorBoundary from "../../Utils/ErrorBoundary";

function FontAwesome(props: FontAwesomeIconProps) {
  return (
    <ErrorBoundary>
      <FontAwesomeIcon {...props} />
    </ErrorBoundary>
  );
}

export default FontAwesome;
