import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState } from "react";
import { Link } from "react-router-dom";
import Flex from "../../Grid/Flex";
import FontAwesome from "../../UI/FontAwesome";
import ClassNames from "../../../Utils/ClassNames";
import MenuNavLink from "./MenuNavLink";
import Overlay from "../../UI/Overlay";
import { NavLink } from "react-router-dom";
import PopHelper from "Components/UI/Pop";
import { useStore } from "App/Stores/StoreContext";
import { observer } from "mobx-react-lite";

export default observer(function NavigationBar(props: {
  userImg: string;
  userEmail: string;
  userDispName: string;
  userLogout: () => void;
  children?: React.ReactNode | JSX.Element;
}) {
  const { newNotifications } = useStore("NotificationStore");
  return (
    <PopHelper>
      {({ show, setShow, open, close }) => (
        <>
          <Overlay
            width="screen"
            className="bg-blend-overlay bg-black opacity-25 z-[999990]"
            hidden={!show}
            onClick={close}
          />

          {/** Main Container - Position Navigation */}
          <Flex.Box
            className={ClassNames(Flex.iStretch, [
              "fixed p-0 z-[999991]", // Fixed in Place on All Devices
              "md:h-full md:left-0 md:top-0 md:p-4 md:w-24", // Always Appear on MD
              {
                // when closed on mobile only - fit button only
                "top-4 right-4 w-fit h-fit": !show,
                [ClassNames(
                  ["top-0 right-0", "min-h-screen max-h-screen h-fit"],
                  ["w-4/6 sm:w-1/2 md:w-1/4 lg:w-1/5 xl:w-1-6"]
                )]: show,
              },
            ])}
          >
            {/** Content - bg and padding */}
            <Flex.Box
              className={ClassNames(
                Flex.Col,
                Flex.jBetween,
                "w-full md:py-4",
                {
                  "py-0 h-full rounded-tr-leaf-sm rounded-br-leaf-sm bg-none": !show,
                  "py-4 bg-primary-0 bg-gradient-to-br rounded-none md:rounded-tr-leaf md:rounded-br-leaf":
                    show,
                },
                "md:bg-primary-0 md:bg-gradient-to-br from-primary-5 text-white"
              )}
            >
              <Flex.Box
                className={ClassNames(
                  Flex.Col,
                  Flex.Center,
                  "h-fit",
                  show ? "border-b" : "",
                  "md:border-b",
                  "mb-3"
                )}
              >
                <Flex.Box
                  className={ClassNames(
                    "flex-row-reverse",
                    show ? "px-4" : "",
                    // Flex.cCenter,
                    Flex.md.Row,
                    Flex.jBetween,
                    "w-full"
                  )}
                >
                  <button
                    onClick={() => {
                      setShow((v) => !v);
                    }}
                    className={ClassNames(
                      {
                        [ClassNames("mb-2 md:mr-auto")]: show,
                        [ClassNames("m-0 md:mx-auto border border-primary-6")]: !show,
                      },
                      "md:mb-4  md:mt-0",
                      "w-10 h-10 p-1",
                      "rounded-full",
                      "bg-white text-primary-6 hover:bg-white/90 md:border-none",
                      "self-end"
                    )}
                  >
                    <FontAwesome icon={show ? solid("close") : solid("bars")} size="1x" />

                    {!show && !!newNotifications.length && (
                      <FontAwesome
                        icon={solid("circle")}
                        size="xs"
                        className={ClassNames("text-danger-6 absolute", "-mr-2 -mt-2")}
                      />
                    )}
                  </button>
                  <span className="flex flex-row-reverse md:flex-row">
                    <NavLink
                      to=""
                      className="p-2 mx-1 h-fit hover:text-white/90"
                      hidden={!show}
                    >
                      <FontAwesome icon={solid("cogs")} size="lg" />
                    </NavLink>
                    <NavLink
                      to="/app/notifications"
                      className="p-2 mx-1 h-fit hover:text-white/90"
                      hidden={!show}
                    >
                      <FontAwesome icon={solid("bell")} size="lg" />
                      {!!newNotifications.length && (
                        <FontAwesome
                          icon={solid("circle")}
                          size="xs"
                          className={ClassNames(
                            "text-danger-6 absolute",
                            "left-9 md:left-auto md:right-10"
                          )}
                        />
                      )}
                    </NavLink>
                  </span>
                </Flex.Box>

                <div
                  className={ClassNames(
                    "flex flex-col justify-center text-center mb-4 w-full h-fit"
                  )}
                >
                  <Link to="/app/account">
                    <img
                      src={props.userImg}
                      className={ClassNames("mx-auto", "rounded-full md:block", {
                        "hidden w-10 h-10": !show,
                        "block w-18 max-w-[80px]": show,
                      })}
                    />
                  </Link>
                  <Link to={"/app/account"} hidden={!show}>
                    {props.userDispName}
                  </Link>
                  {show && (
                    <span
                      className={ClassNames(
                        "flex gap-1 mt-2 flex-row justify-center items-center font-bold text-sm cursor-pointer"
                      )}
                    >
                      <span className={ClassNames("currency-flag currency-flag-usd")} />{" "}
                      <span>USD</span>
                      {/* <FontAwesome icon={solid("chevron-circle-down")} /> */}
                    </span>
                  )}
                </div>
              </Flex.Box>

              <div
                className={ClassNames(
                  {
                    hidden: !show,
                    flex: show,
                  },
                  Flex.Col,
                  "md:flex",
                  "overflow-y-auto"
                )}
                onClick={close}
              >
                <Links open={show} />
              </div>
              <MenuNavLink
                icon={solid("sign-out-alt")}
                to="logout"
                open={show}
                onClick={props.userLogout}
                className={ClassNames("border-t md:flex mt-3", show ? "flex" : "hidden")}
              >
                Sign Out
              </MenuNavLink>
            </Flex.Box>
          </Flex.Box>
        </>
      )}
    </PopHelper>
  );
});

function Links(props: { open?: boolean }) {
  return (
    <>
      <MenuNavLink to="/app/dashboard" icon={solid("dashboard")} open={props.open}>
        Dashboard
      </MenuNavLink>
      <MenuNavLink to="/app/companies" icon={solid("building")} open={props.open}>
        Companies
      </MenuNavLink>
      <MenuNavLink to="/app/projects" icon={solid("briefcase")} open={props.open}>
        Projects
      </MenuNavLink>
      <MenuNavLink to="/app/products" icon={solid("gem")} open={props.open}>
        Products
      </MenuNavLink>
      <MenuNavLink to="/app/subscriptions" icon={solid("calendar-alt")} open={props.open}>
        Subscriptions
      </MenuNavLink>
      <MenuNavLink to="/app/users" icon={solid("user")} open={props.open}>
        Account
      </MenuNavLink>
    </>
  );
}
