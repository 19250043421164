// mutable functions

/**
 * Remove the first occurrence of value from array.
 *
 * @param arr Array to remove value from
 * @param value value to remove
 * @returns Array: [value, removed_or_not, new_array]
 *
 */
export function removeFirst<T>(arr: TArr<T>, value: T): [T, boolean, TArr<T>] {
  if (!arr || !arr.length) return [value, false, arr];
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
    return [value, true, arr];
  }
  return [value, false, arr];
}

/**
 * Remove all occurrences of value from array.
 *
 * @param arr Array to remove values from
 * @param value value to remove
 * @returns Array of two items: [value_removed, count: count of removed values, new_array]
 *
 */
export function removeAll<T>(arr: TArr<T>, value: T): [T, number, TArr<T>] {
  if (!arr || !arr.length) return [value, 0, arr];
  let i = 0;
  let count = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
      ++count;
    } else {
      ++i;
    }
  }

  return [value, count, arr];
}

/**
 * Remove items from array (mutable)
 *
 * @param arr Array to remove values from
 * @param predicate Predicate for selecting items to be removed
 * @returns Array: [found_or_not, removed_value, new_array]
 *
 */
export function removeFirstWhere<T>(
  arr: TArr<T>,
  predicate: (val: T) => boolean
): [boolean, T | null | undefined, TArr<T>] {
  // null guard
  if (!arr || !arr.length) return [false, undefined, arr];
  for (let i = 0; i < arr.length; i++) {
    if (predicate(arr[i])) {
      return [true, arr.splice(i, 1)[0], arr];
    }
  }
  return [false, undefined, arr];
}

/**
 * Remove items from array (mutable)
 *
 * @param arr Array to remove values from
 * @param predicate Predicate for selecting items to be removed
 * @returns Array: [removed_items, new_array]
 *
 */
export function removeAllWhere<T>(
  arr: T[] | null | undefined,
  predicate: (val: T) => boolean
): [T[], TArr<T>] {
  // guard against null and empty array
  if (!arr || !arr.length) return [[], arr];
  // Prepare Index Indicator
  let i = 0;
  const removed = [];
  // While current index is available
  while (i < arr.length) {
    // if predicate matches value at this index
    if (predicate(arr[i])) {
      // remove it and add removed item to the removed array
      removed.push(arr.splice(i, 1)[0]);
    }
    // else: move next
    else {
      ++i;
      // PS: move next is inside else, so the loop check the next item placed in this index.
      // if it's outside else, then index will increment, skipping the new item inserted at this index.
    }
  }
  return [removed, arr];
}

type TArr<T> = T[] | null | undefined;
