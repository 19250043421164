import { AddProductDataValidator } from "App/Api/ProductAgent";
import { ProductStatus } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import LoaderSection from "Components/UI/LoaderSection";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { For } from "Utils/For";

function ProductAddEdit() {
  const { products, editItem, addItem, categories } = useStore("ProductStore");
  const editItemId = useParams()["prodId"];
  const editMode = useMemo(() => !!editItemId, [editItemId]);
  const item = useMemo(
    () =>
      editItemId
        ? products.filter((x) => x.id === editItemId)[0]
        : { name: null, categoryName: null, details: "" },
    [editItemId, products]
  );
  // const saving = editMode ? editingItem === true : addingNewItem === true;

  return (
    <>
      <AddEdit>
        {editMode && !item && <LoaderSection title="Loading item..." />}
        {((editMode && !!item) || !editMode) && (
          <Formik
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={editMode ? (editItem as any) : addItem}
            initialValues={item}
            validationSchema={AddProductDataValidator}
            enableReinitialize
          >
            {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
              <Form
                onSubmit={handleSubmit}
                onBlur={handleBlur}
                onReset={handleReset}
                onChange={handleChange}
              >
                <AddEdit.SelectField
                  name="categoryName"
                  placeholder="Category"
                  label="Category"
                >
                  <>
                    <AddEdit.SelectInput.Option title="Choose a category..." />
                    <For each={categories.map((x) => x.data)}>
                      {(item) => (
                        <AddEdit.SelectInput.Option
                          label={item.name}
                          value={item.name}
                          title={item.name}
                        />
                      )}
                    </For>
                  </>
                </AddEdit.SelectField>
                <AddEdit.TextField name="name" placeholder="Name" label="Name" />
                <AddEdit.TextField name="details" placeholder="Details" label="Details" />
                <AddEdit.SelectField name="status" placeholder="Status" label="Status">
                  <>
                    <AddEdit.SelectInput.Option title="Product Status..." />
                    <AddEdit.SelectInput.Option
                      title="Available"
                      value={ProductStatus.available}
                      label="Available"
                    />
                    <AddEdit.SelectInput.Option
                      title="Deprecated"
                      value={ProductStatus.deprecated}
                      label="Deprecated"
                    />
                    <AddEdit.SelectInput.Option
                      title="Unavailable"
                      value={ProductStatus.temporarilyUnavailable}
                      label="Unavailable"
                    />
                  </>
                </AddEdit.SelectField>

                <FormikAlert />

                <AddEdit.Actions>
                  <FormikSubmitField>
                    {({ field, isSubmitting }) => (
                      <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                    )}
                  </FormikSubmitField>
                  <AddEdit.Actions.Cancel />
                </AddEdit.Actions>
              </Form>
            )}
          </Formik>
        )}
      </AddEdit>
    </>
  );
}

export default observer(ProductAddEdit);
