import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect } from "react";
import { FormatDate } from "Utils/DateUtils";
import { UserDetailsToAdmin } from "App/Models";

function UsersList() {
  const { users, loadingItems, loadItems } = useStore("UsersStore");
  useEffect(() => {
    if (!loadingItems) loadItems();
  });
  return (
    <>
      <Summary>
        {loadingItems === true && <LoaderSection title="Loading Users..." />}
        {loadingItems === "success" && <For each={users}>{ItemSummary}</For>}
        {loadingItems === "faulted" && (
          <button className="bg-primary-6 text-white">
            An Error Occurred! Click Here To Refresh.
          </button>
        )}
      </Summary>
    </>
  );
}

function ItemSummary(item: UserDetailsToAdmin) {
  return (
    <Summary.Card to={item.email} key={item.email}>
      <Summary.Card.Title>{item.displayName}</Summary.Card.Title>
      <Summary.Card.Detail icon={solid("at")} title="Email:" info={item.email} />
      <Summary.Card.Detail
        icon={solid("phone")}
        title="Phone:"
        info={item.phoneNumber ?? "-"}
      />
      <Summary.Card.FooterImage
        fallbackIcon={solid("user")}
        src={item.photoUrl}
        // src="http://placekitten.com/300/500"
        // src="http://placekitten.com/300/300"
        // src="http://via.placeholder.com/640x360"
      />
    </Summary.Card>
  );
}

export default observer(UsersList);
