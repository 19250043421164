import { ApiNotification } from "App/Api/CommonAgent";
import { useStore } from "App/Stores/StoreContext";
import { Table, TableStyles } from "Components/Grid/Table";
import AppHeading from "Components/Project/AppHeading";
import { history } from "index";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import ClassNames from "Utils/ClassNames";
import { FormatDate } from "Utils/DateUtils";
import { For } from "Utils/For";

function NotificationPage() {
  const { notifications } = useStore("NotificationStore");
  return (
    <>
      <AppHeading title="Notifications" />
      <div className="bg-white">
        <Table className="w-full">
          <Heading />
          <Table.Data>
            <For each={_.sortBy(notifications, (x) => x.createdAt)}>{ItemDetail}</For>
          </Table.Data>
        </Table>
      </div>
    </>
  );
}

export default observer(NotificationPage);

function Heading() {
  return (
    <Table.Heading>
      <Table.Heading.Row>
        <Table.Heading.Cell>Date</Table.Heading.Cell>
        <Table.Heading.Cell className="hidden md:table-cell">Title</Table.Heading.Cell>
        <Table.Heading.Cell>Description</Table.Heading.Cell>
      </Table.Heading.Row>
    </Table.Heading>
  );
}

function ItemDetail(item: ApiNotification) {
  const { markViewed } = useStore("NotificationStore");
  return (
    <Table.Data.Row
      key={item.id}
      id={item.id + "DR"}
      className={ClassNames("cursor-pointer", {
        [TableStyles.tableDataRowRemarked]: !item.isViewed,
      })}
      onClick={() => {
        if (!item.isViewed) markViewed(item.id);
        history.push(`/app/projects/${item.itemParentId ?? ""}/sub/${item.itemId}`);
      }}
    >
      <Table.Data.Cell>
        {FormatDate(item.createdAt, {
          locale: "en-uk",
          options: { day: "2-digit", month: "short", year: "numeric" },
        })}
      </Table.Data.Cell>
      <Table.Data.Cell className="hidden md:table-cell">{item.title}</Table.Data.Cell>
      <Table.Data.Cell>{item.details}</Table.Data.Cell>
    </Table.Data.Row>
  );
}
