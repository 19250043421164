// Hooks & Store
// import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// Components
import AccountPage from "Pages/Account/AccountPage";
// Assets
// import logo from "../Assets/Images/logo.svg";
import { Navigate, Route, Routes } from "react-router";
import { useStore } from "App/Stores/StoreContext";
import { useEffect } from "react";
import LoaderScreen from "./LoaderScreen";
import { Observer } from "mobx-react-lite";
import NotFound from "Pages/Errors/NotFound";
import ServerError from "Pages/Errors/ServerError";
import UserSpace from "./UserSpace";
import CompaniesPage from "Pages/Companies/CompaniesPage";
import CompaniesList from "Pages/Companies/CompaniesList";
import ProductsPage from "Pages/Products/ProductsPage";
import ProductsList from "Pages/Products/ProductsList";
import { observer } from "mobx-react-lite";
import DashboardPage from "Pages/Dashboard/Dashboard";
import Dev from "Pages/_UI/Dev";
import { ProductDetail } from "Pages/Products/ProductDetail";
import ProjectsPage from "Pages/Projects/ProjectsPage";
import ProjectsList from "Pages/Projects/ProjectsList";
import SubscriptionsPage from "Pages/Subscriptions/SubscriptionsPage";
import SubscriptionsList from "Pages/Subscriptions/SubscriptionsList";
import { SubscriptionDetail } from "Pages/Subscriptions/SubscriptionDetail";
import OfflineOverlay from "Components/Project/OfflineOverlay";
import CompanyDetails from "Pages/Companies/CompanyDetails";
import CompanyAddEdit from "Pages/Companies/CompanyAddEdit";
import NotificationsPage from "Pages/Notifications/NotificationsPage";
import CompanyAddress from "Pages/Companies/CompanyAddress";
import ProductAddEdit from "Pages/Products/ProductAddEdit";
import LoginForm from "Pages/Account/LoginForm";
import PassResetForm from "Pages/Account/PassResetForm";
import NewPasswordForm from "Pages/Account/NewPasswordForm";
import UsersPage from "Pages/Users/UsersPage";
import UsersList from "Pages/Users/UsersList";
import UserDetails from "Pages/Users/UserDetails";
import UserAddress from "Pages/Users/UserAddress";
import UserCreate from "Pages/Users/UserCreate";
import ProjectsAddEdit from "Pages/Projects/ProjectsAddEdit";
import ProjectDetails from "Pages/Projects/ProjectDetails";
import { Outlet, useLocation } from "react-router-dom";
import { ScrollToTop } from "Utils/Components";
import CompanyModeratorAdd from "Pages/Companies/CompanyModeratorAdd";
import ProjectModeratorAdd from "Pages/Projects/ProjectModeratorAdd";
import SubscriptionAddEdit from "Pages/Subscriptions/SubscriptionAddEdit";
import UserUpdate from "Pages/Users/UserUpdate";
import ProjectSubDetails from "Pages/Projects/Subs/ProjectSubDetails";
import ProjectSubAddEdit from "Pages/Projects/Subs/ProjectSubAddEdit";
import ProjectProdAddEdit from "Pages/Projects/Prods/ProjectProdAddEdit";
import ProjectProdDetails from "Pages/Projects/Prods/ProjectProdDetails";
import ProjectDetailsOutlet from "Pages/Projects/ProjectDetailsOutlet";

const useAppUserLogin = () => {
  const userStore = useStore("AccountStore");
  return useEffect(() => {
    userStore.loadAppUser();
    return userStore.cleanUp;
  });
};

const useCommonStorePreparation = () => {
  const commonStore = useStore("CommonStore");
  return useEffect(() => {
    window.addEventListener("online", commonStore.RefreshOnlineStatus);
    window.addEventListener("offline", commonStore.RefreshOnlineStatus);
  });
};

export default observer(function App() {
  const commonStore = useStore("CommonStore");
  const userStore = useStore("AccountStore");
  useCommonStorePreparation();
  useAppUserLogin();

  return (
    <>
      <Observer>
        {() => {
          if (!commonStore.appLoaded) return <LoaderScreen />;
          return (
            <Routes>
              <Route path="/" element={<ScrollToTopOnPath />}>
                <Route
                  element={!userStore.isLoggedIn ? <AccountPage /> : <Navigate to="app" />}
                >
                  <Route
                    index
                    element={
                      !userStore.isLoggedIn ? <Navigate to="login" /> : <Navigate to="app" />
                    }
                  />
                  <Route
                    path="login"
                    element={userStore.isLoggedIn ? <Navigate to="/app" /> : <LoginForm />}
                  />
                  <Route
                    path="resetPass"
                    element={
                      userStore.isLoggedIn ? <Navigate to="/app" /> : <PassResetForm />
                    }
                  />
                  <Route
                    path="changePassword"
                    element={
                      userStore.isLoggedIn ? <Navigate to="/app" /> : <NewPasswordForm />
                    }
                  />
                </Route>
                <Route path="app" element={<UserSpace />}>
                  <Route index element={<Navigate to="dashboard" />} />

                  <Route path="dev" element={<Dev />} />

                  <Route path="notifications" element={<NotificationsPage />} />

                  <Route path="dashboard" element={<DashboardPage />} />

                  <Route path="companies" element={<CompaniesPage />}>
                    <Route index element={<CompaniesList />} />
                    <Route path="new" element={<CompanyAddEdit />} />
                    <Route path=":compId">
                      <Route index element={<CompanyDetails />} />
                      <Route path="edit" element={<CompanyAddEdit />} />
                      <Route path="address" element={<CompanyAddress />} />
                      <Route path="new-project" element={<ProjectsAddEdit />} />
                      <Route path="new-moderator" element={<CompanyModeratorAdd />} />
                      <Route path="mod/:modId"></Route>
                    </Route>
                  </Route>

                  <Route path="projects" element={<ProjectsPage />}>
                    <Route index element={<ProjectsList />} />
                    <Route path="new" element={<ProjectsAddEdit />} />
                    <Route path=":projId" element={<ProjectDetailsOutlet />}>
                      <Route index element={<ProjectDetails />} />
                      <Route path="edit" element={<ProjectsAddEdit />} />
                      <Route path="new-moderator" element={<ProjectModeratorAdd />} />
                      <Route path="new-subscription" element={<ProjectSubAddEdit />} />
                      <Route path="new-product" element={<ProjectProdAddEdit />} />
                      <Route path="sub">
                        <Route path=":subId">
                          <Route index element={<ProjectSubDetails />} />
                          <Route path="edit" element={<ProjectSubAddEdit />} />
                        </Route>
                      </Route>
                      <Route path="prd">
                        <Route path=":prodId">
                          <Route index element={<ProjectProdDetails />} />
                          <Route path="edit" element={<ProjectProdAddEdit />} />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path="products" element={<ProductsPage />}>
                    <Route index element={<ProductsList />} />
                    <Route path="new" element={<ProductAddEdit />} />
                    <Route path=":prodId">
                      <Route index element={<ProductDetail />} />
                      <Route path="edit" element={<ProductAddEdit />} />
                    </Route>
                  </Route>

                  <Route path="subscriptions" element={<SubscriptionsPage />}>
                    <Route index element={<SubscriptionsList />} />
                    <Route path="new" element={<SubscriptionAddEdit />} />
                    <Route path=":subId">
                      <Route index element={<SubscriptionDetail />} />
                      <Route path="edit" element={<SubscriptionAddEdit />} />
                    </Route>
                  </Route>

                  <Route path="users" element={<UsersPage />}>
                    <Route index element={<UsersList />} />
                    <Route path="new" element={<UserCreate />} />
                    <Route path=":userEmail">
                      <Route index element={<UserDetails />} />
                      <Route path="edit" element={<UserUpdate />} />
                      <Route path="address" element={<UserAddress />} />
                      {/* <Route path="edit" element={<SubscriptionForm />} /> */}
                    </Route>
                  </Route>

                  <Route path="server-error" element={<ServerError />} />
                  <Route path="not-found" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="not-found" />} />
                </Route>
                <Route
                  path="server-error"
                  element={
                    userStore.isLoggedIn ? (
                      <Navigate to="/app/server-error" />
                    ) : (
                      <ServerError />
                    )
                  }
                />
                <Route
                  path="not-found"
                  element={
                    userStore.isLoggedIn ? <Navigate to="/app/not-found" /> : <NotFound />
                  }
                />
                <Route
                  path="*"
                  element={
                    userStore.isLoggedIn ? (
                      <Navigate to="/app/not-found" />
                    ) : (
                      <Navigate to="/not-found" />
                    )
                  }
                />
              </Route>
            </Routes>
          );
        }}
      </Observer>
      <OfflineOverlay />
      <ToastContainer position="bottom-right" hideProgressBar />
    </>
  );
});

function ScrollToTopOnPath() {
  const { pathname } = useLocation();
  return (
    <ScrollToTop on={pathname}>
      <Outlet />
    </ScrollToTop>
  );
}
