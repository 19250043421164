import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
import { useParams } from "react-router-dom";
import { history } from "index";
import { useEffect } from "react";
import { FormatDate } from "Utils/DateUtils";
import { Nullable } from "Utils/HelperTypes";
import { AddressToString, ModeratorRole, RemovalStatus } from "App/Models";
import { ProjectDetails } from "App/Api/ProjectAgent";
import { CompanyModeratorDetails } from "App/Api/CompanyAgent";
import { NavLink } from "react-router-dom";
import FontAwesome from "Components/UI/FontAwesome";
import ClassNames from "Utils/ClassNames";

export default observer(function CompanyDetails() {
  const id = useParams()["compId"] ?? (history.push("/app/not-found") as unknown as string);
  const { companiesMap, loadItemDetails, deleteItem } = useStore("CompanyStore");
  const item = companiesMap.get(id);
  const data = item?.data;
  useEffect(() => {
    if (id && (!item || !item.detailsLoaded)) loadItemDetails(id);
  }, [id, item, loadItemDetails]);

  if (!item || !item.detailsLoaded === true || !data) return <LoaderSection />;

  return (
    <Details>
      <Details.Item>
        <Details.Item.Main>
          <Details.Item.Main.Title>{data.name}</Details.Item.Main.Title>
          <Details.Item.Main.DetailsList
            values={[
              { icon: solid("at"), title: "Support Email", info: data.supportEmail ?? "-" },
              {
                icon: solid("phone"),
                title: "Support Phone",
                info: data.supportPhone ?? "-",
              },
              {
                icon: solid("info-circle"),
                title: "Details",
                info: data.details ?? "-",
              },
            ]}
          />
          <Details.Item.Main.Actions>
            <Details.Item.Main.Actions.Edit />
            <PopUp
              actions={{
                ok: () =>
                  deleteItem(data.id).then((r) =>
                    r === RemovalStatus.RequireFinalization ? 0 : 1
                  ),
              }}
            >
              <PopUp.Open.AsDeleteButton />
              <PopUp.DeleteDialog
                title="Deleting Company!"
                message={`Are you sure you want to remove '${data.name}' from companies?`}
              />
            </PopUp>
          </Details.Item.Main.Actions>
        </Details.Item.Main>
        <Details.Item.Extra
          values={[
            {
              icon: solid("info"),
              title: "Created On",
              info: FormatDate(data.createdAt),
            },
            ...(data.isRemoved
              ? [
                  {
                    icon: solid("x"),
                    title: "Pending Remove",
                    info: "Must Remove Projects First",
                  },
                  {
                    icon: solid("x"),
                    title: "Removed At",
                    info: FormatDate(data.removedAt),
                  },
                ]
              : []),
          ]}
        >
          <Details.Item.Extra.Detail>
            <NavLink to="address" className="w-full block" role="button">
              <Details.Item.Extra.Detail.Icon icon={solid("map-location")} />
              <Details.Item.Extra.Detail.Info>
                {AddressToString(data.headAddress) ?? "-"}
              </Details.Item.Extra.Detail.Info>
            </NavLink>
          </Details.Item.Extra.Detail>
        </Details.Item.Extra>
      </Details.Item>
      <Details.SubDetails>
        <CompanyProjectDetails id={data.id} details={data.projects} />
        <CompanyModeratorDetailsSection id={data.id} details={data.moderators} />
      </Details.SubDetails>
    </Details>
  );
});

function CompanyProjectDetails(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-project`} />
        <Details.ChildList.Legend.Heading>Projects</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{CompanyProjectSummary}</For>
    </Details.ChildList>
  );
}

function CompanyProjectSummary(item: ProjectDetails) {
  return (
    <Summary.Card key={item.id} to={`/app/projects/${item.id}`}>
      <Summary.Card.Title>{item.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}

function CompanyModeratorDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<CompanyModeratorDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-moderator`} />
        <Details.ChildList.Legend.Heading>Moderators</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{CompanyModeratorSummary}</For>
    </Details.ChildList>
  );
}

function CompanyModeratorSummary(item: CompanyModeratorDetails) {
  return (
    <Summary.Card key={item.id}>
      <Summary.Card.Title>{item.moderator?.displayName}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Role"
        info={ModeratorRole[item.role]}
      />
      <Summary.Card.Detail>
        <Summary.Card.Detail.Icon icon={solid("at")} />
        <Summary.Card.Detail.Title>Email</Summary.Card.Detail.Title>
        <Summary.Card.Detail.Info>
          <a href={`mailto:${item.moderator?.email ?? ""}`}>{item.moderator?.email}</a>
        </Summary.Card.Detail.Info>
      </Summary.Card.Detail>
      <Summary.Card.Detail>
        <Summary.Card.Detail.Icon icon={solid("phone")} />
        <Summary.Card.Detail.Title>Phone</Summary.Card.Detail.Title>
        <Summary.Card.Detail.Info>
          {item.moderator?.phoneNumber ? (
            <a href={`tel:${item.moderator?.phoneNumber ?? ""}`}>
              {item.moderator?.phoneNumber}
            </a>
          ) : (
            "-"
          )}
        </Summary.Card.Detail.Info>
      </Summary.Card.Detail>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />

      <SummaryActions
        email={item.moderator?.email ?? ""}
        modId={item.id}
        compId={item.companyId}
      />
    </Summary.Card>
  );
}

function SummaryActions(props: { email: string; modId: string; compId: string }) {
  const { removeModerator } = useStore("CompanyStore");
  return (
    <div>
      <NavLink to={`/app/users/${props.email}`}>
        <button
          className={ClassNames(
            "group transition-all",
            "px-4 py-2 min-w-[94px]",
            "rounded-full font-bold",
            "bg-gray-5 text-white",
            "hover:bg-gray-6 focus-visible:bg-gray-7 active:bg-gray-7"
          )}
        >
          Details
        </button>
      </NavLink>
      <PopUp
        actions={{
          ok: () => removeModerator(props.modId, props.compId).then(() => true),
        }}
      >
        <PopUp.Open.AsDeleteButton className="float-right" />
        <PopUp.DeleteDialog
          title="Deleting Moderator!"
          message={`Are you sure you want to remove moderator '${props.email}' from this company?`}
        />
      </PopUp>
    </div>
  );
}
