import AxiosRequests from "Utils/Axios";
import { ApiDate, Nullable } from "Utils/HelperTypes";
import { ApiVoidPromise, ApiPromise, PaginationQuery, PaginatedResult } from "App/Models";

// *******************************************************
// * Dashboard                                           *
// *******************************************************

export interface DashboardStatistics {
  companies: number;
  projects: number;
  products: number;
  productCategories: number;
  subscriptions: number;
  subscriptionCategories: number;
  subscriptionsEndingInTwoWeeks: number;
  users: number;
  adminUsers: number;
  totalProjectSubscriptionsCost: number;
  totalProjectSubscriptionsPrice: number;
  totalProjectProductsPrice: number;
  totalProjectProductsCost: number;
}

export interface Api {
  LoadDashboardStatistics: ApiPromise<DashboardStatistics>;
}

// *******************************************************
// * Notification                                        *
// *******************************************************

export interface ApiNotification {
  id: string;
  itemId: string;
  itemParentId?: string;
  title: string;
  details: string;
  actionStatus: number;
  viewedAt: ApiDate;
  isViewed: boolean;
  createdAt: ApiDate;
}

// *****************************************
// * Load Notifications

export interface NotificationsQuery extends PaginationQuery {
  UnreadOnly?: boolean;
}

export interface Api {
  GetNotification: ApiPromise<PaginatedResult<ApiNotification>, NotificationsQuery>;
  MarkViewed: ApiVoidPromise<string>;
}

// *******************************************************
// * Currency                                            *
// *******************************************************

export interface Currency {
  iso4217?: string;
  symbol?: string;

  /** @format int32 */
  decimals?: number;
  isSupported?: boolean;
}

export interface CurrencyExchangeRate {
  id?: Nullable<string>;
  from?: Nullable<string>;
  fromCurrency?: Nullable<Currency>;
  to?: Nullable<string>;
  toCurrency?: Nullable<Currency>;

  /** @format double */
  exchangeRate?: number;

  /** @format date-time */
  dateTimeOfExchange?: ApiDate;
  fromApi?: boolean;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  // ** Dashboard **
  LoadDashboardStatistics: async () =>
    AxiosRequests.get<DashboardStatistics>("/dashboard/statistics"),
  GetNotification: (query?: NotificationsQuery) =>
    AxiosRequests.get<PaginatedResult<ApiNotification>>("/notification", query),
  MarkViewed: (notificationId: string) =>
    AxiosRequests.put("/notification", { notificationId }),
};
