import * as Yup from "yup";
import {
  Address,
  ApiPromise,
  ApiQuery,
  ApiVoidPromise,
  ModeratorRole,
  RemovalStatus,
  RestoreStatus,
  UserDetailsToAdmin,
} from "App/Models";
import { ApiDate, Nullable } from "Utils/HelperTypes";
import YupValidations from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";
import { Project, ProjectDetails } from "./ProjectAgent";

// *******************************************************
// * Company                                             *
// *******************************************************

const basePath = "/company";

export interface Company {
  id?: Nullable<string>;
  name: Nullable<string>;
  details?: Nullable<string>;
  supportEmail?: Nullable<string>;
  supportPhone?: Nullable<string>;
  photoUrl?: Nullable<string>;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

export interface CompanyDetails extends Company {
  id: string;
  name: string;
  headAddress?: Nullable<Address>;
  projects?: Nullable<ProjectDetails[]>;
  moderators?: Nullable<CompanyModeratorDetails[]>;
}

// *****************************************
// * Add Company

export interface AddCompanyData {
  name: string;
  details?: Nullable<string>;
  supportEmail?: Nullable<string>;
  supportPhone?: Nullable<string>;
}

export const AddCompanyDataValidator: Yup.SchemaOf<AddCompanyData> = Yup.object({
  name: YupValidations.RequiredName("Name"),
  details: YupValidations.OptionalDetails("Details"),
  supportEmail: YupValidations.OptionalEmail("Support Email"),
  supportPhone: YupValidations.OptionalPhone("Support Phone"),
});

export interface Api {
  AddCompany: ApiPromise<CompanyDetails, AddCompanyData>;
}

// *****************************************
// * Edit Company

export interface EditCompanyData {
  data: Company;
}

export interface Api {
  EditCompany: ApiPromise<CompanyDetails, EditCompanyData>;
}

// *****************************************
// * List Companies & Load Details

export type ListCompaniesQuery = ApiQuery;

export interface Api {
  ListCompanies: ApiPromise<CompanyDetails[], ListCompaniesQuery>;
}

export interface Api {
  LoadCompanyDetails: ApiPromise<CompanyDetails, string>;
}

// *****************************************
// * Remove & Restore Company

export interface RemoveCompanyData {
  id: string;
}

export interface RestoreCompanyData {
  id: string;
}

export interface Api {
  RemoveCompany: ApiPromise<RemovalStatus, RemoveCompanyData>;
  RestoreCompany: ApiPromise<RestoreStatus, RestoreCompanyData>;
}

// *****************************************
// * Manage Address

export interface AddEditCompanyAddressData {
  id: string;
  address: Address;
}

export interface RemoveCompanyAddressData {
  id: string;
}

export interface Api {
  AddEditCompanyAddress: ApiVoidPromise<AddEditCompanyAddressData>;
  RemoveCompanyAddress: ApiVoidPromise<RemoveCompanyAddressData>;
}

// *******************************************************
// * Company Moderator                                   *
// *******************************************************

export interface CompanyModerator {
  id: string;
  companyId: string;
  moderatorId: string;
  role: ModeratorRole;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

export interface CompanyModeratorDetails extends CompanyModerator {
  company?: Nullable<Company>;
  moderator?: Nullable<UserDetailsToAdmin>;
}

// *****************************************
// * Add Company Moderator

export interface AddCompanyModeratorData {
  /** Company Id */
  id: string;
  userEmail: string;
  role: ModeratorRole;
}

export const AddCompanyModeratorDataValidator: Yup.SchemaOf<AddCompanyModeratorData> =
  Yup.object({
    id: YupValidations.RequiredString("Company Id"),
    userEmail: YupValidations.RequiredEmail("User Email"),
    role: YupValidations.RequiredEnum("Role", ModeratorRole),
  });

export interface Api {
  AddCompanyModerator: ApiPromise<CompanyModeratorDetails, AddCompanyModeratorData>;
}

// *****************************************
// * Remove & Restore Company Categories

export interface RemoveCompanyModeratorData {
  id: string;
}

export interface Api {
  RemoveCompanyModerator: ApiPromise<RemovalStatus, RemoveCompanyModeratorData>;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  AddCompany: async (data: AddCompanyData) =>
    await AxiosRequests.post<CompanyDetails>(basePath, data),
  ListCompanies: async (query?: ListCompaniesQuery) =>
    await AxiosRequests.get<CompanyDetails[]>(basePath, query),
  EditCompany: async (data: EditCompanyData) =>
    await AxiosRequests.put<CompanyDetails>(basePath, data),
  RemoveCompany: async (data: RemoveCompanyData) =>
    await AxiosRequests.delete<RemovalStatus>(basePath, data),
  LoadCompanyDetails: async (id: string) =>
    await AxiosRequests.get<CompanyDetails>(`${basePath}/${id}`),
  RestoreCompany: async (data: RestoreCompanyData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/restore`, data),
  AddEditCompanyAddress: async (data: AddEditCompanyAddressData) =>
    await AxiosRequests.put(`${basePath}/address`, data),
  RemoveCompanyAddress: async () => await AxiosRequests.put(`${basePath}/address`),
  // ** Moderators **
  AddCompanyModerator: async (data: AddCompanyModeratorData) =>
    await AxiosRequests.post<CompanyModeratorDetails>(`${basePath}/moderator`, data),
  RemoveCompanyModerator: async (data: RemoveCompanyModeratorData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/moderator`, data),
};
