import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { ButtonHTMLAttributes, HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import ClassNames from "../../Utils/ClassNames";
import { XChildren } from "../../Utils/ReactElementsTypes";
import Flex from "../Grid/Flex";
import FontAwesome from "../UI/FontAwesome";

export interface LeafProps extends HTMLAttributes<HTMLDivElement> {
  direction: "left" | "right";
  border?: boolean;
  shadow?: boolean;
  children: React.ReactNode | React.ReactNode[] | JSX.Element;
}

function Leaf(props: LeafProps) {
  const { direction, border, shadow, className, children, ...rest } = props;
  return (
    <Flex.Box
      className={ClassNames(
        Flex.Col,
        "mb-10 min-w-[280px]",
        {
          "rounded-bl-[64px] rounded-tr-[64px]": direction === "left",
          "rounded-tl-[64px] rounded-br-[64px]": direction === "right",
          "shadow-ui": !!shadow,
          "border-t border-l border-gray-4": !!border,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Flex.Box>
  );
}

Leaf.className = {
  List: "min-h-[180px] h-fit p-6 bg-white bg-gradient-to-r from-gray-3 w-full sm:w-2/3 md:w-[47.6%]",
  CornerImage: "bg-contain rounded-full mt-auto self-end w-16 h-16 p-0",
  CornerIcon: "rounded-full mt-auto self-end w-10 h-10 p-3",
  Legend: {
    Primary: "bg-secondary-4 text-white",
    PrimaryButton:
      "bg-white text-secondary-4 hover:text-secondary-6 focus:text-secondary-6 active:text-secondary-7",
  },
};

Leaf.Heading = function LeafHeading(props: { children: XChildren }) {
  return (
    <>
      <p>{props.children}</p>
    </>
  );
};

Leaf.CornerImage = function LeafCornerImage(props: {
  src?: string;
  fallbackIcon: IconProp;
  fallbackStyle?: "primary" | "secondary";
}) {
  if (props.src)
    return <img className={ClassNames(Leaf.className.CornerImage)} src={props.src} />;
  return (
    <FontAwesome
      className={ClassNames("text-white", Leaf.className.CornerIcon, {
        "bg-primary-6 ": !props.fallbackStyle || props.fallbackStyle === "primary",
        "bg-secondary-6": props.fallbackStyle === "secondary",
      })}
      icon={props.fallbackIcon}
    />
  );
};

function LeafActionButtons(props: { children: XChildren }) {
  return (
    <div className="w-300 h-fit mt-10 -mb-10 p-0 pr-4 self-end text-right -mx-4">
      {props.children}
    </div>
  );
}

LeafActionButtons.Button = function LeafActionButton(
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children"> & {
    action: "Edit" | "Delete" | "Submit" | "Cancel";
  }
) {
  const { className, action, ...rest } = props;
  const [hover, setHover] = React.useState<boolean>();
  return (
    <button
      {...rest}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={ClassNames(
        "w-24 mx-2 py-2 px-2 text-center font-bold rounded-full",
        {
          "bg-secondary-4 hover:bg-secondary-5 focus:bg-secondary-6 active:bg-secondary-7 disabled:bg-secondary-3 text-white":
            action === "Edit" || action === "Submit",
          "bg-accent-4 hover:bg-accent-5 focus:bg-accent-6 active:bg-accent-7 disabled:bg-secondary-3 text-white":
            action === "Delete",
          "bg-gray-4 hover:bg-gray-5 focus:bg-gray-6 active:bg-gray-7 text-white":
            action === "Cancel",
        },
        className
      )}
    >
      {hover ? (
        <FontAwesome
          icon={
            action === "Cancel"
              ? solid("cancel")
              : action === "Submit"
              ? solid("check")
              : action === "Delete"
              ? solid("trash")
              : solid("pen")
          }
        />
      ) : (
        action
      )}
    </button>
  );
};
Leaf.ActionButtons = LeafActionButtons;

function LeafLegend(props: { className?: string; children: React.ReactNode | JSX.Element }) {
  return (
    <legend
      className={ClassNames(
        "absolute flex flex-row items-center justify-center w-fit px-2 py-3 font-bold -mt-16 self-center rounded-full",
        props.className
      )}
    >
      {props.children}
    </legend>
  );
}

interface LegendButtonPropsBase {
  className: string;
}

interface LegendButtonNavProps extends LegendButtonPropsBase {
  href: string;
}

interface LegendButtonProps extends LegendButtonPropsBase {
  onClick: () => void;
}

type LegendActionProps = LegendButtonNavProps | LegendButtonProps;

LeafLegend.AddButton = function LeafLegendAddButton(props: LegendActionProps) {
  if ((props as LegendButtonNavProps).href)
    return (
      <Link
        className={ClassNames(
          "my-0 mx-1 h-8 w-8 p-2 rounded-full text-center flex justify-center items-center",
          props.className
        )}
        to={(props as LegendButtonNavProps).href}
      >
        <FontAwesome className="absolute" icon={solid("plus")} size="lg" />
      </Link>
    );
  return (
    <button
      className={ClassNames(
        "my-0 mx-1 h-8 w-8 p-2 rounded-full text-center flex justify-center items-center",
        props.className
      )}
      onClick={(props as LegendButtonProps).onClick}
    >
      <FontAwesome className="absolute" icon={solid("plus")} size="lg" />
    </button>
  );
};

LeafLegend.Title = function LeafLegendAddButton(props: { children: string }) {
  return <span className="m-0 mx-1 p-0 text-center">{props.children}</span>;
};

Leaf.Legend = LeafLegend;

export default Leaf;
