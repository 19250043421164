import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FlexCol } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { Nullable } from "Utils/HelperTypes";
import {
  DivProps,
  HeadingProps,
  NavLinkProps,
  ParagraphProps,
} from "Utils/ReactElementsTypes";

function Summary(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexCol
      sm
      wrap
      {...rest}
      className={ClassNames(
        className,
        "gap-8",
        "items-center md:items-start md:justify-start"
      )}
    />
  );
}
function SummaryCard(props: NavLinkProps | DivProps) {
  const classNames = useMemo(
    () =>
      ClassNames(
        "min-w-[284px] max-w-[380px] w-11/12 sm:w-[75%] md:w-[48%] text-start",
        "bg-gray-1 bg-gradient-to-r from-gray-4/40 border shadow-ui",
        "pt-4 pb-10 lg:py-5 px-4 lg:px-8 pr-4 rounded-tr-leaf rounded-bl-leaf",
        "animate-all hover:scale-105 hover:from-gray-3",
        "flex flex-col"
      ),
    []
  );
  return (props as NavLinkProps).to ? (
    <NavLink {...(props as NavLinkProps)} className={classNames} />
  ) : (
    <div {...(props as DivProps)} className={classNames} />
  );
}

Summary.Card = SummaryCard;

function SummaryCardTitle(props: HeadingProps) {
  const { className, ...rest } = props;
  return (
    <h2
      {...rest}
      className={ClassNames(className, "font-bold text-primary-6 text-2xl m-0  lg:mb-4 p-0")}
    />
  );
}

SummaryCard.Title = SummaryCardTitle;

export type SummaryCardDetailProps = {
  icon?: Nullable<IconProp>;
  title?: Nullable<string>;
  info?: Nullable<string>;
};

function SummaryCardDetail(props: DivProps & SummaryCardDetailProps) {
  const { className, children, icon, title, info, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        className,
        "group text-base text-primary-6 hover:bg-white/50",
        "w-4/5 lg:pl-2 lg:pr-4 py-2 lg:py-0 lg:mb-2 border-b lg:border-none"
      )}
    >
      {!!icon && <SummaryCardDetail.Icon icon={icon} />}
      {!!title && <SummaryCardDetail.Title>{title}</SummaryCardDetail.Title>}
      {!!info && <SummaryCardDetail.Info>{info}</SummaryCardDetail.Info>}
      {children}
    </div>
  );
}

SummaryCard.Detail = SummaryCardDetail;

function SummaryCardDetailIcon(props: FontAwesomeIconProps) {
  const { className, ...rest } = props;
  return (
    <FontAwesome
      {...rest}
      className={ClassNames(
        className,
        "inline-block group-hover:text-primary-6 text-white mr-1 md:mr-2 w-6"
      )}
    />
  );
}

SummaryCardDetail.Icon = SummaryCardDetailIcon;

function SummaryCardDetailTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p
      {...rest}
      className={ClassNames(
        className,
        "text-primary-7 inline-block md:min-w-[64px] text-xl mr-1 md:mr-2"
      )}
    />
  );
}

SummaryCardDetail.Title = SummaryCardDetailTitle;

function SummaryCardDetailInfo(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p {...rest} className={ClassNames(className, "text-primary-6 text-xl ml-7 lg:ml-8")} />
  );
}

SummaryCardDetail.Info = SummaryCardDetailInfo;

function SummaryCardFooterImage(props: {
  fallbackIcon: IconProp;
  src?: Nullable<string>;
  asDetail?: boolean;
}) {
  const { fallbackIcon, src, asDetail } = props;
  return !!src && !!src.length ? (
    <img
      src={src}
      className={ClassNames(
        "self-end justify-self-end",
        "text-center align-center object-cover rounded-full",
        { "-mt-16 w-16 h-16": !asDetail, "-mt-12 w-12 h-12": !!asDetail }
      )}
    />
  ) : (
    <FontAwesome
      icon={fallbackIcon}
      className={ClassNames(
        "self-end justify-self-end text-center align-center",
        "p-3 bg-white text-primary-6 rounded-full object-contain",
        {
          "-mt-16 w-11 h-11": !asDetail,
          "-mt-12 w-8 h-8": !!asDetail,
        }
      )}
    />
  );
}

SummaryCard.FooterImage = SummaryCardFooterImage;

export default Summary;
