import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { Variant } from "Components/UI/UI";
import _ from "lodash";

export const HasErrors = () => !!document.querySelector(`[data-formik-error=true]`);
export const NavigateToFirstErrorField = () => {
  const input = document.querySelector(`[data-formik-error=true]`);

  if (!input) return;

  input.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "start",
  });

  (input as HTMLElement).focus();
};

export interface FormikErrorObject {
  message?: string;
  context?: Record<string, string>;
  validation?: Record<string, string>;
  Variant?: Variant;
}

export interface FaxErrors extends Record<string, unknown> {
  errors?: FormikErrorObject;
}

export function EmptyFaxErrors<T>(helpers: FormikHelpers<T>) {
  helpers.setFieldValue("errors", null);
}

export function SetValidationErrors<T>(
  helpers: FormikHelpers<T>,
  errors: Record<string, string | string[]>,
  message?: string,
  variant?: "danger" | "warning" | "info"
) {
  helpers.setFieldValue("errors", {
    message: message,
    variant: variant,
    validation: errors,
  });
}
export function SetContextErrors<T>(
  helpers: FormikHelpers<T>,
  errors: Record<string, string | string[]>,
  message?: string,
  variant?: "danger" | "warning" | "info"
) {
  helpers.setFieldValue("errors", {
    message: message,
    variant: variant,
    context: errors,
  });
}
export async function SetFormikErrors<T>(
  helpers: FormikHelpers<T>,
  message?: string | null,
  errors?: {
    validation?: Record<string, string | string[]>;
    context?: Record<string, string | string[]> | string;
  } | null,
  variant?: "danger" | "warning" | "info" | null
) {
  await helpers.setFieldValue("errors", {
    message: message,
    variant: variant,
    context: errors?.context,
    validation: errors?.validation,
  });
  if (errors?.validation) {
    _.forEach(errors.validation, (v, k) => {
      helpers.setFieldError(_.lowerFirst(k), typeof v === "string" ? v : v[0]);
    });
  }
}
export function SetPropsErrors<T>(
  helpers: FormikHelpers<T>,
  errors: Record<string, string | string[]>
) {
  _.forEach(errors, (v, k) => {
    helpers.setFieldError(_.lowerFirst(k), typeof v === "string" ? v : v[0]);
  });
}

type formikErrorInitType = <T>(initValues: T) => FaxErrors & T;

export const WithFormikErrors: formikErrorInitType = (initValues) => ({
  ...initValues,
  errors: {},
});

export type FaxErrs = { errors: Record<string, string | string[]> } & Record<
  string,
  string | string[]
>;

export const FaxErrorHandler = async <T>(aErr: AxiosError, helpers: FormikHelpers<T>) => {
  // Network Error
  if (aErr.response?.status === 0) {
    helpers?.setFieldValue("errors", {
      message: "Network Error!",
      variant: "warning",
    });
    return false;
  }

  if (!aErr.response) throw aErr;

  const respErr = aErr.response.data as FaxErrs;
  const { errors, ...contextErrors } = respErr; // errors are validation errors.

  if (aErr.response?.status === 400) {
    await SetFormikErrors(helpers, null, { validation: errors, context: {} }, "danger");
    await NavigateToFirstErrorField();
    return "NAV_ERR_FIELD";
  }
  if (aErr.response?.status === 422) {
    await SetContextErrors(helpers, contextErrors, "", "danger");
    await NavigateToFirstErrorField();
    return false;
  }
  console.error(aErr.response);
  throw aErr;
};
