import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { Outlet, useParams } from "react-router-dom";
import { history } from "index";
import { useEffect } from "react";

export default observer(function ProjectDetails() {
  const id = useParams()["projId"] ?? (history.push("/app/not-found") as unknown as string);
  const { getItem, loadItemDetails } = useStore("ProjectStore");
  const item = getItem(id);
  useEffect(() => {
    if (!item?.detailsLoaded) loadItemDetails(id);
  }, [id, item, loadItemDetails]);

  if (!item || !item.detailsLoaded === true) return <LoaderSection />;

  return <Outlet />;
});
