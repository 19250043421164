import { Outlet } from "react-router-dom";
import AppHeading from "Components/Project/AppHeading";

function CompaniesPage() {
  return (
    <>
      <AppHeading title="Companies" newPath="/app/companies/new" />
      <Outlet />
    </>
  );
}

export default CompaniesPage;
