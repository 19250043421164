import React from "react";
import "Configs";
import "Extensions";
import ReactDOM from "react-dom/client";
import "index.css";
import App from "Layout/App";
import CustomRouter from "Utils/CustomRouter";
import { createBrowserHistory } from "history";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import StoreContext from "App/Stores/StoreContext";

export const history = createBrowserHistory();

export const pushTo = (to: string) => {
  history.push(to);
  return "";
};

export const pushToNotFound = () => {
  history.push("/app/not-found");
  return "";
};

const content = (
  <React.StrictMode>
    <StoreContext>
      <CustomRouter history={history}>
        <App />
      </CustomRouter>
    </StoreContext>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(content);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
