import { useLocation } from "react-router-dom";
import { ApiDate, FormatDate } from "./DateUtils";

export function FormattedDate(
  props: {
    locale?: Intl.LocalesArgument;
  } & Intl.DateTimeFormatOptions &
    ({ children: ApiDate; value?: undefined } | { children?: undefined; value: ApiDate })
): JSX.Element {
  return <>{FormatDate(props.children, { locale: props.locale, options: props })}</>;
}

export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}
