// import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Outlet } from "react-router-dom";
import Assets from "../../Assets";
import Flex from "../../Components/Grid/Flex";
// import FontAwesome from "../../Components/UI/FontAwesome";
import ClassNames from "../../Utils/ClassNames";
import LoginForm from "./LoginForm";

export default function Login() {
  return (
    <>
      <img
        className="fixed hidden md:block top-0 left-0 h-screen w-5/12 z-0"
        src={Assets.Images.HeroLeftBG}
      />
      <img
        className="absolute md:hidden top-0 left-0 h-48 w-full z-20"
        src={Assets.Images.Mobile.HeroTop}
      />
      <Flex.Box
        className={ClassNames(
          "min-h-screen md:max-h-screen",
          [Flex.Col, Flex.Center],
          [Flex.md.Row, Flex.md.iStretch, Flex.md.cAround]
        )}
      >
        <Flex.Box
          className={ClassNames(
            [Flex.Col, Flex.Center],
            "h-28 md:min-h-screen m-0 md:pr-7",
            "w-full",
            "md:w-5/12 lg:w-5/12 bg-cover",
            "transition-all"
          )}
          style={{ backgroundImage: "/Assets/Images/HeroLeftBg.png" }}
        >
          <img
            className={ClassNames("w-20", "transition-all z-10")}
            src={Assets.Images.Logo}
          />
          <h2 className="hidden md:block mt-7 text-white drop-shadow-md text-4xl">
            Welcome Back!
          </h2>
        </Flex.Box>
        <Flex.Box className={ClassNames(Flex.Col, Flex.Center, "w-full md:w-7/12 lg:w-7/12")}>
          <Outlet />
        </Flex.Box>
      </Flex.Box>
      <img
        className="inline relative text-right float-end md:hidden w-full h-24 pl-14"
        src={Assets.Images.Mobile.HeroBottom}
      />
    </>
  );
}
