import { DivProps } from "Utils/ReactElementsTypes";
import ClassNames from "../../Utils/ClassNames";

export default function Overlay(
  props: DivProps & {
    width: "full" | "screen";
    zIndex?: number | string;
  }
) {
  const { className, width, zIndex, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        {
          "absolute w-full h-full": width === "full",
          "fixed w-screen h-screen top-0 left-0 right-0 bottom-0": width === "screen",
        },
        className
      )}
      style={{ zIndex: zIndex }}
    />
  );
}
