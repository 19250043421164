import { AddProjectDataValidator } from "App/Api/ProjectAgent";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import LoaderSection from "Components/UI/LoaderSection";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { For } from "Utils/For";

function ProjectAddEdit() {
  const { loadItemDetails, getItem, editItem, addNewItem, getNewOrExistingItem } =
    useStore("ProjectStore");
  const { getItem: getCompany, companies } = useStore("CompanyStore");
  const editItemId = useParams()["projId"];
  const editMode = useMemo(() => !!editItemId, [editItemId]);
  const companySelected = useParams()["compId"];
  const company = useMemo(
    () => getCompany(companySelected!)?.data,
    [companySelected, getCompany]
  );
  const item = useMemo(
    () =>
      editMode
        ? getNewOrExistingItem(editItemId)
        : companySelected
        ? { companyId: companySelected, name: "" }
        : getNewOrExistingItem(),
    [editItemId, companySelected, editMode, getNewOrExistingItem]
  );
  // const saving = editMode ? editingItem === true : addingNewItem === true;

  useEffect(() => {
    if (editItemId && !getItem(editItemId)) loadItemDetails(editItemId);
  }, [editItemId, loadItemDetails, getItem]);

  return (
    <>
      <AddEdit>
        {!editMode && (
          <>
            <h2 className="text-2xl mb-4 font-bold text-primary-8 drop-shadow-sm">
              New Project
            </h2>
          </>
        )}
        {editMode && (
          <>
            <h2 className="text-2xl mb-4 font-bold text-primary-8 drop-shadow-sm">
              Edit Project
            </h2>
            {!item && <LoaderSection title="Loading item..." />}
          </>
        )}

        {((editMode && !!item) || !editMode) && (
          <Formik
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={editMode ? (editItem as any) : addNewItem}
            initialValues={item}
            validationSchema={AddProjectDataValidator}
            enableReinitialize
          >
            {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
              <Form
                onSubmit={handleSubmit}
                onBlur={handleBlur}
                onReset={handleReset}
                onChange={handleChange}
              >
                <AddEdit.SelectField
                  name="companyId"
                  placeholder="Company"
                  label="Company"
                  disabled={!!companySelected}
                >
                  <>
                    {!!companySelected && (
                      <AddEdit.SelectInput.Option
                        value={company?.id}
                        title={company?.name}
                        label={company?.name}
                      />
                    )}
                    {!companySelected && (
                      <>
                        <AddEdit.SelectInput.Option
                          title={"Select Company..."}
                          label={"Select Company..."}
                        />
                        <For each={companies}>
                          {(comp) => (
                            <AddEdit.SelectInput.Option
                              value={comp.id}
                              title={comp.name}
                              label={comp.name}
                            />
                          )}
                        </For>
                      </>
                    )}
                  </>
                </AddEdit.SelectField>
                <AddEdit.TextField name="name" placeholder="Name" label="Name" />

                <FormikAlert />

                <AddEdit.Actions>
                  <FormikSubmitField>
                    {({ field, isSubmitting }) => (
                      <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                    )}
                  </FormikSubmitField>
                  <AddEdit.Actions.Cancel />
                </AddEdit.Actions>
              </Form>
            )}
          </Formik>
        )}
      </AddEdit>
    </>
  );
}

export default observer(ProjectAddEdit);
