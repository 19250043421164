import { Nullable } from "Utils/HelperTypes";
import { isFalsy } from "./GuardUtils";

export type ApiDate = Date | number | string | null;

export function GetDate(val?: ApiDate): Nullable<Date> {
  if (isFalsy(val)) return null;
  switch (typeof val) {
    case "number":
    case "string":
      return new Date(val);

    default:
      return val;
  }
}

export function FormatDate(
  val?: ApiDate,
  intl: {
    locale?: Intl.LocalesArgument;
    options?: Intl.DateTimeFormatOptions;
  } = { locale: "en-MY", options: { day: "2-digit", month: "short", year: "numeric" } }
): Nullable<string> {
  return GetDate(val)?.toLocaleDateString(intl?.locale, intl?.options) ?? "";
}
