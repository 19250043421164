import { useState, useId, Fragment, useEffect, Dispatch, SetStateAction } from "react";
import Overlay from "Components/UI/Overlay";
import { Nullable } from "Utils/HelperTypes";
import { DialogProps, DivProps, ParagraphProps } from "Utils/ReactElementsTypes";
import { ButtonProps } from "Utils/ReactElementsTypes";
import { useMemo } from "react";
import ClassNames from "Utils/ClassNames";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FontAwesome from "./FontAwesome";
import { FlexRow } from "Components/Grid/FlexBox";
import Spinner from "./Spinner";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type PopProps = {
  show?: boolean;
  setShow: (val: boolean) => void;
  open: () => void;
  close: () => void;
};

// export interface IActions {
//   add?: (helpers: PopProps) => void | Promise<void>;
//   save?: (helpers: PopProps) => void | Promise<void>;
//   delete?: (helpers: PopProps) => void | Promise<void>;
//   main?: (helpers: PopProps) => void | Promise<void>;
//   secondary?: (helpers: PopProps) => void | Promise<void>;
// }

export type ActionFunc = null | (() => boolean | void);

export interface ModalResult {
  id: string;
  show?: boolean;
  setShow: Dispatch<SetStateAction<boolean | undefined>>;
  open: () => void;
  close: () => void;
}

function PopHelper(props: {
  children: (props: ModalResult) => JSX.Element;
  refSetShow?: (setShow: (x: boolean) => void) => void;
  onClose?: ActionFunc;
  onAdd?: ActionFunc;
  onDelete?: ActionFunc;
  onCancel?: ActionFunc;
  onSave?: ActionFunc;
}) {
  const id = useId();
  const [show, setShow] = useState<boolean>();
  const open = useMemo(() => () => setShow(true), []);
  const close = useMemo(() => () => setShow(false), []);

  const { refSetShow, children } = props;

  useEffect(() => {
    if (refSetShow) refSetShow(setShow);
  }, [refSetShow]);

  return (
    <Fragment key={id}>
      {children({
        id,
        show,
        setShow,
        open,
        close,
      })}
    </Fragment>
  );
}

function PopOverlay(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <Overlay
      className={ClassNames(className, "bg-black/70 cursor-default z-[999997]")}
      width="screen"
      zIndex={999997}
      {...rest}
    />
  );
}

PopHelper.Overlay = PopOverlay;

function PopModal(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        "visible fixed block top-2 right-2 bottom-2 left-2 z-[999999] p-4",
        "rounded-tl-leaf-sm rounded-br-leaf-sm cursor-default w-fit h-fit min-w-[280px] max-w-[400px] max-h-[98vh]",
        "text-start",
        className
      )}
      style={{ zIndex: 999998 }}
    />
  );
}

function PopDialog(props: DialogProps) {
  const { className, ...rest } = props;
  return (
    <dialog
      {...rest}
      className={ClassNames(
        "visible fixed block top-2 right-2 bottom-2 left-2 z-[999999] p-4",
        "rounded-tl-leaf-sm rounded-br-leaf-sm cursor-default w-fit h-fit min-w-[280px] max-w-[400px] max-h-[98vh]",
        "text-start",
        className
      )}
      style={{ zIndex: 999999 }}
    />
  );
}

PopHelper.Dialog = PopDialog;
PopHelper.Modal = PopModal;

function ModalDialogHeading(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexRow className={ClassNames("border-b border-gray-3 pb-3", className)} {...rest} />
  );
}

PopModal.Heading = ModalDialogHeading;
PopDialog.Heading = ModalDialogHeading;

function ModalDialogTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p {...rest} className={ClassNames("inline mr-3 text-2xl text-secondary-5", className)} />
  );
}

PopModal.Title = ModalDialogTitle;
PopDialog.Title = ModalDialogTitle;

function ModalDialogCloseButton(props: Omit<ButtonProps, "id">) {
  const { className, ...rest } = props;
  const id = useId();
  return (
    <button
      id={"close-" + id}
      {...rest}
      className={ClassNames(
        className,
        "transition-all ml-auto px-2 w-8 h-8",
        "rounded-tl-xl rounded-br-xl focus:rounded-tr-xl focus:rounded-bl-xl focus:rounded-tl-none focus:rounded-br-none",
        "border border-gray-4 text-gray-4",
        "hover:text-gray-5 hover:border-gray-5",
        "focus:text-gray-6 focus:border-gray-6 focus-visible:outline-dashed focus-visible:outline-2",
        "active:text-gray-7 active:border-gray-7"
      )}
      type="button"
      autoFocus
    >
      <FontAwesome icon={solid("close")} size="lg" />
    </button>
  );
}

PopModal.CloseButton = ModalDialogCloseButton;
PopDialog.CloseButton = ModalDialogCloseButton;

function ModalDialogDetails(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <div {...rest} className={ClassNames("py-3 pr-4 overflow-y-auto h-fit", className)} />
  );
}

PopModal.Details = ModalDialogDetails;
PopDialog.Details = ModalDialogDetails;

function ModalDialogMessage(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={ClassNames(className, "text-lg text-secondary-7/80")} />;
}

PopModal.Message = ModalDialogMessage;
PopDialog.Message = ModalDialogMessage;

function ModalDialogFooter(props: DivProps) {
  const { className, ...rest } = props;
  return <div {...rest} className={ClassNames("border-t border-gray-3 py-2", className)} />;
}

PopModal.Footer = ModalDialogFooter;
PopDialog.Footer = ModalDialogFooter;

export type ActionProp = () => Promise<void> | void;

export type ModalDialogActionButtonProps = Omit<ButtonProps, "id" | "children"> & {
  children?: (props: {
    running?: boolean | null;
    setRunning: Dispatch<SetStateAction<boolean | undefined>>;
  }) => JSX.Element;
};

function ModalDialogActionHelper(props: {
  children: (props: {
    isProcessing: boolean;
    setIsProcessing: Dispatch<SetStateAction<boolean>>;
  }) => JSX.Element;
}) {
  const id = useId();
  const [isProcessing, setIsProcessing] = useState(false);
  return <Fragment key={id}>{props.children({ isProcessing, setIsProcessing })}</Fragment>;
}

PopModal.Action = ModalDialogActionHelper;
PopDialog.Action = ModalDialogActionHelper;

function ModalDialogActionButton(props: Omit<ButtonProps, "id">) {
  const id = useId();
  const { className, ...rest } = props;
  return (
    <button
      id={id}
      className={ClassNames(
        "min-w-[86px] py-1 px-3 mt-1 mx-1.5 float-right group",
        className
      )}
      {...rest}
    />
  );
}

ModalDialogActionHelper.Button = ModalDialogActionButton;

function ModalDialogActionButtonContent(props: {
  text: string;
  icon: IconProp;
  showSpinner?: boolean | null;
}) {
  return props.showSpinner ? (
    <Spinner size="lg" />
  ) : (
    <>
      <span className="animate-all group-enabled:group-hover:hidden">{props.text}</span>
      <FontAwesome
        className="animate-all hidden group-enabled:group-hover:inline"
        icon={props.icon}
      />
    </>
  );
}

ModalDialogActionButton.Content = ModalDialogActionButtonContent;

function ModalDialogActionCancel(
  props: Omit<ButtonProps, "id" | "children"> & { working?: Nullable<boolean> }
) {
  const { className, working, disabled, ...rest } = props;
  return (
    <ModalDialogActionButton
      disabled={working || disabled}
      className={ClassNames(
        "animate-all bg-gray-5 border border-gray-5 text-white rounded-tl-2xl rounded-br-2xl",
        "enabled:hover:bg-gray-6 enabled:hover:border-gray-6",
        "enabled:focus:bg-gray-7  enabled:focus:border-gray-7 focus:rounded-tr-2xl focus:rounded-bl-2xl focus:rounded-tl-none focus:rounded-br-none",
        "enabled:active:bg-gray-8 enabled:active:border-gray-8",
        "disabled:bg-white disabled:border-gray-3 disabled:text-gray-4",
        className
      )}
      {...rest}
    >
      <ModalDialogActionButton.Content
        text="Cancel"
        icon={solid("x")}
        showSpinner={!!working}
      />
    </ModalDialogActionButton>
  );
}

ModalDialogActionButton.Cancel = ModalDialogActionCancel;

function ModalDialogActionDelete(
  props: Omit<ButtonProps, "id" | "children"> & {
    working?: Nullable<boolean>;
    action?: () => Promise<void>;
  }
) {
  const { className, working, disabled, action, onClick, ...rest } = props;
  const [runningAction, setRunningAction] = useState(false);
  return (
    <ModalDialogActionButton
      disabled={runningAction || working || disabled}
      className={ClassNames(
        "animate-all group bg-danger-6 border border-danger-6 text-white rounded-tl-2xl rounded-br-2xl",
        "enabled:hover:bg-danger-7 enabled:hover:border-danger-7",
        "enabled:focus:bg-danger-8  enabled:focus:border-danger-8 focus:rounded-tr-2xl focus:rounded-bl-2xl focus:rounded-tl-none focus:rounded-br-none",
        "enabled:active:bg-danger-9 enabled:active:border-danger-9",
        "disabled:bg-white disabled:border-danger-3 disabled:text-danger-5",
        className
      )}
      onClick={
        action
          ? () => {
              setRunningAction(true);
              action().finally(() => setRunningAction(false));
            }
          : onClick
      }
      {...rest}
    >
      <ModalDialogActionButton.Content
        text="Delete"
        icon={solid("trash")}
        showSpinner={runningAction || !!working}
      />
    </ModalDialogActionButton>
  );
}

ModalDialogActionButton.Delete = ModalDialogActionDelete;

function ModalDialogActionSave(
  props: Omit<ButtonProps, "id" | "children"> & {
    working?: Nullable<boolean>;
    action?: () => Promise<void>;
  }
) {
  const { className, working, disabled, action, onClick, ...rest } = props;
  const [runningAction, setRunningAction] = useState(false);
  return (
    <ModalDialogActionButton
      disabled={runningAction || working || disabled}
      className={ClassNames(
        "animate-all group bg-success-6 border border-success-6 text-white rounded-tl-2xl rounded-br-2xl",
        "enabled:hover:bg-success-7 enabled:hover:border-success-7",
        "enabled:focus:bg-success-8  enabled:focus:border-success-8 focus:rounded-tr-2xl focus:rounded-bl-2xl focus:rounded-tl-none focus:rounded-br-none",
        "enabled:active:bg-success-9 enabled:active:border-success-9",
        "disabled:bg-white disabled:border-success-3 disabled:text-success-5",
        className
      )}
      onClick={
        action
          ? () => {
              setRunningAction(true);
              action().finally(() => setRunningAction(false));
            }
          : onClick
      }
      {...rest}
    >
      <ModalDialogActionButton.Content
        text="Cancel"
        icon={solid("x")}
        showSpinner={runningAction || !!working}
      />
      {runningAction || working ? (
        <Spinner size="lg" />
      ) : (
        <ModalDialogActionButton.Content text="Save" icon={solid("check")} />
      )}
    </ModalDialogActionButton>
  );
}

ModalDialogActionButton.Save = ModalDialogActionSave;

export default PopHelper;
