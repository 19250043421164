import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
import { useParams } from "react-router-dom";
import { history } from "index";
import { useEffect } from "react";
import { FormatDate } from "Utils/DateUtils";
import { Nullable } from "Utils/HelperTypes";
import { AddressToString, RemovalStatus } from "App/Models";
import { ProjectDetails } from "App/Api/ProjectAgent";
import { NavLink } from "react-router-dom";

export default observer(function UserDetails() {
  const id =
    useParams()["userEmail"] ?? (history.push("/app/not-found") as unknown as string);
  const { getItem } = useStore("UsersStore");
  const item = getItem(id);

  return (
    <Details>
      <Details.Item>
        <Details.Item.Main>
          <Details.Item.Main.Title>{item?.displayName}</Details.Item.Main.Title>
          <Details.Item.Main.DetailsList
            values={[
              { icon: solid("at"), title: "Email", info: item?.email },
              {
                icon: solid("phone"),
                title: "Phone",
                info: item?.phoneNumber ?? "-",
              },
              {
                icon: solid("info-circle"),
                title: "Is Admin",
                info: item?.isAdmin ? "Yes" : "No",
              },
            ]}
          />
          <Details.Item.Main.Actions>
            <Details.Item.Main.Actions.Edit />
          </Details.Item.Main.Actions>
        </Details.Item.Main>
        <Details.Item.Extra
          values={[
            {
              icon: solid("info"),
              title: "First Name",
              info: item?.firstName,
            },
            {
              icon: solid("info"),
              title: "Last Name",
              info: item?.lastName,
            },
            {
              icon: solid("info"),
              title: "Gender",
              info: item?.gender === 1 ? "Male" : item?.gender === 2 ? "Female" : "N/A",
            },
            {
              icon: solid("info"),
              title: "Created On",
              info: FormatDate(item?.createdAt),
            },
          ]}
        >
          <Details.Item.Extra.Detail>
            <NavLink to="address" className="w-full block" role="button">
              <Details.Item.Extra.Detail.Icon icon={solid("map-location")} />
              <Details.Item.Extra.Detail.Info>
                {AddressToString(item?.address) ?? "-"}
              </Details.Item.Extra.Detail.Info>
            </NavLink>
          </Details.Item.Extra.Detail>
        </Details.Item.Extra>
      </Details.Item>
    </Details>
  );
});
