import { createContext, useContext, useId, useMemo, useState } from "react";
import { Nullable } from "Utils/HelperTypes";
import { JSXElement, NodeOrJsxChild, RenderNodeOrJsxChild } from "Utils/NodeOrJsx";
import { ReactSetState } from "Utils/ReactHelperTypes";
import { Action, ActionSteps } from "./ActionContext";

export type PopStatus = boolean | null;

export type PopUpActions = {
  ok?: Action;
  cancel?: Action;
};

export type PopUpActionStatus = `${ActionSteps}-${keyof PopUpActions}` | "Undetermined";

export type PopUpStore = {
  id: string;
  isOpen: Nullable<PopStatus>;
  setOpenStatus: ReactSetState<PopStatus>;
  open: () => void;
  close: () => void;
  actionStatus: Nullable<PopUpActionStatus>;
  setActionStatus: ReactSetState<Nullable<PopUpActionStatus>>;
  actions: PopUpActions;
};

const _PopUpContext_ = createContext<Nullable<PopUpStore>>(null);

export function usePopUp() {
  return useContext(_PopUpContext_) as PopUpStore;
}

export type PopUpChild = NodeOrJsxChild<PopUpStore>;

export type PopUpContextProps = {
  children?: PopUpChild;
  value?: PopStatus;
  actions?: PopUpActions;
};

function PopUpContext(props: PopUpContextProps) {
  const id = useId();
  const { actions, value } = props;
  const [isOpen, setOpenStatus] = useState<Nullable<PopStatus>>(value ?? null);
  const [actionStatus, setActionStatus] = useState<Nullable<PopUpActionStatus>>(null);
  const open = useMemo(() => () => setOpenStatus(true), []);
  const close = useMemo(() => () => setOpenStatus(false), []);
  const values = useMemo(
    () => ({
      id,
      isOpen,
      setOpenStatus,
      open,
      close,
      actionStatus,
      setActionStatus,
      actions: actions ?? {},
    }),
    [id, isOpen, actionStatus, actions, open, close]
  );

  return (
    <_PopUpContext_.Provider value={values} key={id}>
      {RenderNodeOrJsxChild(values, props.children)}
    </_PopUpContext_.Provider>
  );
}

function AccessPopUpStore(props: { children?: JSXElement<PopUpStore> }) {
  const popUpStore = usePopUp();
  return props.children ? props.children(popUpStore) : null;
}

PopUpContext.Consumer = AccessPopUpStore;

export default PopUpContext;
