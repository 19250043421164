import { makeAutoObservable, runInAction } from "mobx";
import { DashboardStatistics } from "App/Models/dashboardStatistics";
import { RootStore } from "./Stores";
import { CommonAgent } from "App/Api";

export class DashboardStore {
  // =========================
  // statistics
  statistics: DashboardStatistics | null = null;
  loadingStatistics: boolean | "loaded" | "failed" | null = null;

  loadStatistics = () => {
    if (this.loadingStatistics !== "failed" && this.loadingStatistics) return;
    this.loadingStatistics = true;
    return CommonAgent.Api.LoadDashboardStatistics({})
      .then((stats) =>
        runInAction(() => {
          this.statistics = stats;
          this.loadingStatistics = "loaded";
        })
      )
      .catch((err) => {
        console.log(err);
        runInAction(() => (this.loadingStatistics = "failed"));
      });
  };

  root: RootStore;
  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }
}
