import { HTMLAttributes } from "react";
import ClassNames from "../../Utils/ClassNames";

export const xs = {
  Col: "flex-col",
  Row: "flex-row",
  iStart: "items-start",
  iCenter: "items-center",
  iEnd: "items-end",
  iStretch: "items-stretch",
  jStart: "justify-start",
  jCenter: "justify-center",
  jEnd: "justify-end",
  jBetween: "justify-between",
  jAround: "justify-around",
  jEvenly: "justify-evenly",
  cStart: "content-start",
  cCenter: "content-center",
  cEnd: "content-end",
  cBetween: "content-between",
  cAround: "content-around",
  cEvenly: "content-evenly",
  Center: "content-center justify-center items-center",
};

export const sm = {
  Col: "sm:flex-col",
  Row: "sm:flex-row",
  iStart: "sm:items-start",
  iCenter: "sm:items-center",
  iEnd: "sm:items-end",
  iStretch: "sm:items-stretch",
  jStart: "sm:justify-start",
  jCenter: "sm:justify-center",
  jEnd: "sm:justify-end",
  jBetween: "sm:justify-between",
  jAround: "sm:justify-around",
  jEvenly: "sm:justify-evenly",
  cStart: "sm:content-start",
  cCenter: "sm:content-center",
  cEnd: "sm:content-end",
  cBetween: "sm:content-between",
  cAround: "sm:content-around",
  cEvenly: "sm:content-evenly",
  Center: "sm:content-center sm:justify-center sm:items-center",
};

export const md = {
  Col: "md:flex-col",
  Row: "md:flex-row",
  iStart: "md:items-start",
  iCenter: "md:items-center",
  iEnd: "md:items-end",
  iStretch: "md:items-stretch",
  jStart: "md:justify-start",
  jCenter: "md:justify-center",
  jEnd: "md:justify-end",
  jBetween: "md:justify-between",
  jAround: "md:justify-around",
  jEvenly: "md:justify-evenly",
  cStart: "md:content-start",
  cCenter: "md:content-center",
  cEnd: "md:content-end",
  cBetween: "md:content-between",
  cAround: "md:content-around",
  cEvenly: "md:content-evenly",
  Center: "md:content-center md:justify-center md:items-center",
};

export const lg = {
  Col: "lg:flex-col",
  Row: "lg:flex-row",
  iStart: "lg:items-start",
  iCenter: "lg:items-center",
  iEnd: "lg:items-end",
  iStretch: "lg:items-stretch",
  jStart: "lg:justify-start",
  jCenter: "lg:justify-center",
  jEnd: "lg:justify-end",
  jBetween: "lg:justify-between",
  jAround: "lg:justify-around",
  jEvenly: "lg:justify-evenly",
  cStart: "lg:content-start",
  cCenter: "lg:content-center",
  cEnd: "lg:content-end",
  cBetween: "lg:content-between",
  cAround: "lg:content-around",
  cEvenly: "lg:content-evenly",
  Center: "lg:content-center lg:justify-center lg:items-center",
};

export const xl = {
  Col: "xl:flex-col",
  Row: "xl:flex-row",
  iStart: "xl:items-start",
  iCenter: "xl:items-center",
  iEnd: "xl:items-end",
  iStretch: "xl:items-stretch",
  jStart: "xl:justify-start",
  jCenter: "xl:justify-center",
  jEnd: "xl:justify-end",
  jBetween: "xl:justify-between",
  jAround: "xl:justify-around",
  jEvenly: "xl:justify-evenly",
  cStart: "xl:content-start",
  cCenter: "xl:content-center",
  cEnd: "xl:content-end",
  cBetween: "xl:content-between",
  cAround: "xl:content-around",
  cEvenly: "xl:content-evenly",
  Center: "xl:content-center xl:justify-center xl:items-center",
};

export const xxl = {
  Col: "2xl:flex-col",
  Row: "2xl:flex-row",
  iStart: "2xl:items-start",
  iCenter: "2xl:items-center",
  iEnd: "2xl:items-end",
  iStretch: "2xl:items-stretch",
  jStart: "2xl:justify-start",
  jCenter: "2xl:justify-center",
  jEnd: "2xl:justify-end",
  jBetween: "2xl:justify-between",
  jAround: "2xl:justify-around",
  jEvenly: "2xl:justify-evenly",
  cStart: "2xl:content-start",
  cCenter: "2xl:content-center",
  cEnd: "2xl:content-end",
  cBetween: "2xl:content-between",
  cAround: "2xl:content-around",
  cEvenly: "2xl:content-evenly",
};

export function Box(props: HTMLAttributes<HTMLDivElement>) {
  const { className, ...rest } = props;
  return <div {...rest} className={ClassNames("flex", className)} />;
}

export default {
  Box,
  ...xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  fullWidth: "w-full",
  fullHeight: "h-full",
};
