import { number } from "yup";
import { Enum, MaybeNull } from "./HelperTypes";

export function TypeOf(v: unknown) {
  return typeof v;
}

export function New<T>(v: T): MaybeNull<T> {
  if (v === undefined) undefined;
  if (v === null) return null;
  switch (typeof v) {
    case "undefined":
      return undefined;
    case "bigint":
    case "boolean":
    case "function":
    case "number":
    case "string":
    case "symbol":
      return v;
    case "object":
      return { ...v };
    default:
      new Object(v);
  }
}

export function EnumValues(v: Enum, filter?: "number"): number[];
export function EnumValues(v: Enum, filter?: "string"): string[];
export function EnumValues(v: Enum, filter?: undefined): (number | string)[];
export function EnumValues(v: Enum, filter?: "string" | "number") {
  return Object.values(v).filter((x) => !filter || typeof x === filter);
}

export function EnumKeys(v: Enum) {
  return Object.keys(v);
}

export function EnumObject(v: Enum) {
  return [...EnumKeys(v), ...EnumValues(v)] as [];
}
