import {
  AddProjectSubscriptionDataValidator,
  ProjectSubscriptionDetails,
} from "App/Api/ProjectAgent";
import { useStore } from "App/Stores/StoreContext";
import { LeafFormStyles } from "Assets/Styles/Styles";
import FormikAlert from "Components/Forms/FormikAlert";
import FormikField from "Components/Forms/FormikField";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import { Form, Formik } from "formik";
import { pushToNotFound } from "index";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";

function ProjectAddEdit() {
  const { addSubscription, editSubscription, newItemSubscription, getItem } =
    useStore("ProjectStore");
  const { subscriptions } = useStore("SubscriptionStore");
  const projId = useParams()["projId"] ?? pushToNotFound();
  const editItemId = useParams()["subId"];
  const editMode = useMemo(() => !!editItemId, [editItemId]);

  const item = useMemo(
    () =>
      editMode
        ? getItem(projId)?.data.subscriptions?.find((v) => v.id === editItemId) ??
          newItemSubscription(projId)
        : newItemSubscription(projId),
    [editItemId, editMode, newItemSubscription, projId, getItem]
  );

  return (
    <>
      <AddEdit>
        <Formik
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={(v, h) =>
            editMode ? editSubscription(v as any, projId, h) : addSubscription(v, h)
          }
          initialValues={item}
          enableReinitialize
          validationSchema={AddProjectSubscriptionDataValidator}
        >
          {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
            <Form
              onSubmit={handleSubmit}
              onBlur={handleBlur}
              onReset={handleReset}
              onChange={handleChange}
            >
              <fieldset className={ClassNames(LeafFormStyles.FieldSet, "group")}>
                <label
                  className={ClassNames(
                    LeafFormStyles.Label,
                    "group-hover:text-primary-7 group-focus-within:text-primary-8"
                  )}
                >
                  Project
                </label>
                <input
                  disabled
                  value={getItem(projId)?.data.name ?? "-"}
                  className={ClassNames(LeafFormStyles.Input)}
                />
              </fieldset>
              <AddEdit.SelectField
                name="subscriptionId"
                label="Subscription"
                placeholder="Subscription"
              >
                <option label="Select Subscription..." title="Select Subscription...">
                  Select Subscription...
                </option>
                <For each={subscriptions}>
                  {(item) => (
                    <option
                      title={item.categoryName + " - " + item.name}
                      label={item.categoryName + " - " + item.name}
                      value={item.id}
                    >
                      {item.categoryName + " - " + item.name}
                    </option>
                  )}
                </For>
              </AddEdit.SelectField>

              <AddEdit.TextField
                name="startAt"
                placeholder="date"
                label="Start On"
                type="date"
              />
              <AddEdit.TextField name="endAt" placeholder="date" label="End On" type="date" />
              <AddEdit.TextField name="price" placeholder="Price" label="Price" />
              <AddEdit.TextField
                name="priceCurrencyName"
                placeholder="Currency"
                label="Currency"
                disabled
              />
              <FormikField name="autoRenew">
                {({ field, label }) => (
                  <fieldset className={LeafFormStyles.FieldSet}>
                    <input type="checkbox" {...field} className={LeafFormStyles.CheckInput} />{" "}
                    <label {...label} className={LeafFormStyles.CheckLabel}>
                      Auto Renew?
                    </label>
                  </fieldset>
                )}
              </FormikField>
              <hr className="mb-4" />
              <AddEdit.TextField name="details" placeholder="Details" label="Details" />

              <FormikAlert />

              <AddEdit.Actions>
                <FormikSubmitField>
                  {({ field, isSubmitting }) => (
                    <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                  )}
                </FormikSubmitField>
                <AddEdit.Actions.Cancel />
              </AddEdit.Actions>
            </Form>
          )}
        </Formik>
      </AddEdit>
    </>
  );
}

export default observer(ProjectAddEdit);
function loadItemDetails(editItemId: string) {
  throw new Error("Function not implemented.");
}
