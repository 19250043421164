import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PopUpContext, { PopUpContextProps, usePopUp } from "Components/Contexts/PopUpContext";
import FontAwesome from "Components/UI/FontAwesome";
import Overlay from "Components/UI/Overlay";
import ClassNames from "Utils/ClassNames";
import { Nullable } from "Utils/HelperTypes";
import { ButtonProps } from "Utils/ReactElementsTypes";
import { NodeOrJsxChild, RenderNodeOrJsxChild } from "Utils/NodeOrJsx";
import { PopUpModal, PopUpDialog, PopUpActionButtonOnClick } from "./PopUpModalDialog";

function PopUp(props: PopUpContextProps) {
  return <PopUpContext {...props} />;
}

PopUp.Store = PopUpContext.Consumer;

function PopUpToggle(
  props: Omit<ButtonProps, "onClick" | "children"> & {
    children: NodeOrJsxChild<Nullable<boolean>>;
  }
) {
  const { setOpenStatus, isOpen } = usePopUp();
  const { children, ...rest } = props;
  return (
    <button {...rest} onClick={() => setOpenStatus((v) => !v)}>
      {RenderNodeOrJsxChild(isOpen, children)}
    </button>
  );
}

PopUp.Toggle = PopUpToggle;

function PopUpOpen(
  props: Omit<ButtonProps, "onClick" | "children"> & {
    children: NodeOrJsxChild<Nullable<boolean>>;
  }
) {
  const { open, isOpen } = usePopUp();
  const { children, ...rest } = props;
  return (
    <button {...rest} onClick={open}>
      {RenderNodeOrJsxChild(isOpen, children)}
    </button>
  );
}

PopUp.Open = PopUpOpen;

function PopUpOpenAsDeleteButton(props: { className?: string }) {
  return (
    <PopUp.Open
      className={ClassNames(
        "group transition-all",
        "px-4 py-2 min-w-[94px]",
        "rounded-full font-bold",
        "bg-accent-5 text-white",
        "hover:bg-accent-6 focus-visible:bg-accent-7 active:bg-accent-7",
        props.className
      )}
    >
      <FontAwesome
        className={ClassNames("group-hover:inline hidden")}
        size="lg"
        icon={solid("trash")}
      />
      <span className={ClassNames("inline group-hover:hidden")}>Delete</span>
    </PopUp.Open>
  );
}

PopUpOpen.AsDeleteButton = PopUpOpenAsDeleteButton;

function PopUpOverlay() {
  const { isOpen, close } = usePopUp();
  return isOpen ? (
    <Overlay
      className="bg-black/70 cursor-default z-[999997]"
      width="screen"
      zIndex={999997}
      onClick={close}
    />
  ) : null;
}

PopUp.Overlay = PopUpOverlay;

PopUp.Modal = PopUpModal;

PopUp.Dialog = PopUpDialog;

function DeleteDialog(props: {
  title?: Nullable<string>;
  message?: Nullable<string>;
  onDelete?: PopUpActionButtonOnClick;
  onCancel?: PopUpActionButtonOnClick;
}) {
  return (
    <>
      <PopUp.Overlay />
      <PopUp.Dialog>
        {props.title && (
          <PopUp.Dialog.Heading>
            <PopUp.Dialog.Title>{props.title}</PopUp.Dialog.Title>
            <PopUp.Dialog.CloseButton />
          </PopUp.Dialog.Heading>
        )}
        {props.message && (
          <PopUp.Dialog.Details>
            <PopUp.Dialog.Message>{props.message}</PopUp.Dialog.Message>
          </PopUp.Dialog.Details>
        )}
        <PopUp.Dialog.Footer>
          <PopUp.Dialog.ActionButton.Delete onClick={props.onDelete} />
          <PopUp.Dialog.ActionButton.Cancel onClick={props.onCancel} />
        </PopUp.Dialog.Footer>
      </PopUp.Dialog>
    </>
  );
}

PopUp.DeleteDialog = DeleteDialog;

export default PopUp;
