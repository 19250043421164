import { FlexCol, FlexProps } from "Components/Grid/FlexBox";
import ClassNames from "Utils/ClassNames";
import { DivProps, ParagraphProps } from "Utils/ReactElementsTypes";

function Statistics(props: FlexProps) {
  const { className, ...rest } = props;
  return (
    <FlexCol
      sm
      className={className ?? "gap-4 items-center sm:justify-center mb-4"}
      wrap
      {...rest}
    />
  );
}

function StatisticCard(
  props: DivProps & { title?: string; value?: string | number; currency?: string }
) {
  const { className, title, value, currency, children, ...rest } = props;
  return (
    <FlexCol
      {...rest}
      className={
        className ??
        ClassNames(
          "py-4 w-10/12 sm:w-5/12  md:w-[1/3] lg:w-1/6 min-w-[200px] max-w-[300px]",
          "bg-gray-2 bg-gradient-to-r from-gray-3 text-center rounded-tl-leaf-sm rounded-br-leaf-sm hover:scale-105"
        )
      }
    >
      {(!!value || value === 0) && (
        <StatisticCard.Value>
          <span>{value}</span>
          {currency && <span className="text-primary-6">{" " + currency}</span>}
        </StatisticCard.Value>
      )}
      {!!title?.length && <StatisticCard.Title>{title}</StatisticCard.Title>}
      {children}
    </FlexCol>
  );
}

Statistics.Card = StatisticCard;

function StatisticCardTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={className ?? "text-gray-8/75 font-bold"} />;
}

StatisticCard.Title = StatisticCardTitle;

function StatisticCardValue(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={className ?? "text-gray-8/75 font-bold"} />;
}

StatisticCard.Value = StatisticCardValue;

export default Statistics;
