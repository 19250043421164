import { useStore as useStore } from "App/Stores/StoreContext";
import Statistics from "Components/Project/Statistics";
import Alert from "Components/UI/Alert";
import Button from "Components/UI/Button";
import LoaderSection from "Components/UI/LoaderSection";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

export default observer(function StaticsArea() {
  const { statistics, loadStatistics, loadingStatistics } = useStore("DashboardStore");

  useEffect(() => {
    loadStatistics();
  });

  return (
    <div className="pt-4">
      {loadingStatistics === true && <LoaderSection title="Loading Statistics..." />}
      {loadingStatistics === "failed" && (
        <>
          <Alert title="An error occurred while loading statistics." />
          <Button variant="accent-gradient" onClick={loadStatistics}>
            Refresh
          </Button>
        </>
      )}
      {loadingStatistics === "loaded" && statistics && (
        <>
          <Statistics>
            <Statistics.Card title="Companies" value={statistics.companies} />
            <Statistics.Card title="Projects" value={statistics.projects} />
            <Statistics.Card
              title="Subscriptions Ending Soon"
              value={statistics.subscriptionsEndingInTwoWeeks}
            />
          </Statistics>

          <Statistics>
            <Statistics.Card
              title="Projects' Subscriptions Cost"
              value={statistics.totalProjectSubscriptionsCost}
              currency="USD"
            />
            <Statistics.Card
              title="Projects' Subscriptions Price"
              value={statistics.totalProjectSubscriptionsPrice}
              currency="USD"
            />
            <Statistics.Card
              title="Projects' Products Cost"
              value={statistics.totalProjectProductsCost}
              currency="USD"
            />
            <Statistics.Card
              title="Projects' Products Price"
              value={statistics.totalProjectProductsPrice}
              currency="USD"
            />
          </Statistics>
          <Statistics>
            <Statistics.Card
              title="Product Categories"
              value={statistics.productCategories}
            />
            <Statistics.Card title="Products" value={statistics.products} />
            <Statistics.Card
              title="Subscriptions Categories"
              value={statistics.subscriptionCategories}
            />
            <Statistics.Card title="Subscriptions" value={statistics.subscriptions} />
          </Statistics>
          <Statistics>
            <Statistics.Card title="Admin Users" value={statistics.adminUsers} />
            <Statistics.Card title="All Users" value={statistics.users} />
          </Statistics>
        </>
      )}
    </div>
  );
});
