import { CompanyModerator, CompanyModeratorDetails } from "./../Api/CompanyAgent";
import {
  ApiProcessingError,
  ApiProcessingStatus,
  ModeratorRole,
  RemovalStatus,
} from "App/Models";
import { EmptyFaxErrors, FaxErrorHandler } from "Utils/FormikErrorUtils";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { MaybeNull, Nullable } from "Utils/HelperTypes";
import { RootStore } from "./Stores";
import { history } from "index";
import {
  AddCompanyData,
  AddCompanyModeratorData,
  AddEditCompanyAddressData,
  Api,
  Company,
  CompanyDetails,
} from "App/Api/CompanyAgent";

export class CompanyStore {
  // =========================
  // Companies
  companiesMap = new Map<
    MaybeNull<string>,
    { data: CompanyDetails; detailsLoaded?: boolean }
  >();
  getItem = (id: string) => this.companiesMap.get(id);
  getNewOrExistingItem = (id?: Nullable<string>): Company =>
    id && id.length ? this.companiesMap.get(id)?.data ?? this.newItem() : this.newItem();

  private newItem = () => ({
    name: null,
    details: null,
    supportEmail: null,
    supportPhone: null,
  });

  get companies() {
    return _.sortBy(
      Array.from(this.companiesMap.values()).map((x) => x.data),
      (x) => x.name
    );
  }

  setItem = (value: CompanyDetails, detailsLoaded = false) => {
    if (value.id) {
      const current = this.companiesMap.get(value.id);
      if (!current) {
        this.companiesMap.set(value.id, { data: value, detailsLoaded });
        return;
      }
      current.data = {
        ...current.data,
        ..._.omitBy(value, _.isNull),
      };
      if (!current.detailsLoaded) current.detailsLoaded = detailsLoaded;
      // if (detailsLoaded) {
      //   value.projects?.forEach((x) => this.root.ProjectStore.setProject(x));
      // }
      return;
    }
  };

  loadingItems: ApiProcessingStatus = null;
  loadItems = async () => {
    if (this.loadingItems) return;
    this.loadingItems = true;
    try {
      const res = await Api.ListCompanies({});
      res.forEach((x) => this.setItem(x));
      runInAction(() => (this.loadingItems = "success"));
    } catch (er) {
      runInAction(() => (this.loadingItems = "faulted"));
      // const err = er as AxiosError;
      throw er;
    }
  };

  loadingItemDetails: ApiProcessingStatus = null;
  loadItemDetails = async (id: string) => {
    if (this.loadingItemDetails === true) return;
    this.loadingItemDetails = true;

    try {
      const details = await Api.LoadCompanyDetails(id);
      this.setItem(details, true);
      runInAction(() => (this.loadingItemDetails = "success"));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loadingItemDetails = "faulted"));
    }
  };

  deletingItem: ApiProcessingStatus = null;
  deleteItem = async (id: string) => {
    if (this.deletingItem === true) return;
    this.deletingItem = true;

    const exists = this.companiesMap.get(id);

    if (!exists) {
      this.deletingItem = "success";
      return RemovalStatus.NotFound;
    }

    try {
      const res = await Api.RemoveCompany({ id });
      runInAction(() => {
        if (res === RemovalStatus.RequireFinalization) {
          exists.data.removedAt = new Date();
          exists.data.isRemoved = true;
        } else {
          history.push("/app/companies");
          this.companiesMap.delete(id);
        }
        this.deletingItem = "success";
      });
      return res;
    } catch (error) {
      console.error(error);
      runInAction(() => (this.deletingItem = "faulted"));
      throw error;
    }
  };

  addingNewItem: ApiProcessingStatus = null;
  addNewItem = async <T>(data: AddCompanyData, helpers: FormikHelpers<T>) => {
    if (this.addingNewItem === true) return;
    this.addingNewItem = true;
    EmptyFaxErrors(helpers);
    try {
      const res = await Api.AddCompany(data);
      this.setItem(res, true);
      runInAction(() => {
        this.addingNewItem = "success";
      });
      history.push(window.location.pathname.replace("/new", `/${res.id}`));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => {
        this.addingNewItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.log(error);
      throw error;
    }
  };

  editingItem: ApiProcessingStatus = null;
  editItem = async <T>(data: Company, helpers: FormikHelpers<T>) => {
    if (this.editingItem === true) return;
    this.editingItem = true;
    EmptyFaxErrors(helpers);

    const item = this.companiesMap.get(data.id)?.data;

    if (!item) {
      this.editingItem = "faulted";
      return;
    }

    try {
      const res = await Api.EditCompany({ data });
      this.setItem(res, false);
      runInAction(() => {
        this.editingItem = "success";
      });
      history.push(window.location.pathname.replace("/edit", ""));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => {
        this.editingItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.error(error);
      throw error;
    }
  };

  updatingAddress: ApiProcessingStatus = null;
  addEditAddress = async <T>(data: AddEditCompanyAddressData, helpers: FormikHelpers<T>) => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.companiesMap.get(data.id)?.data;
    if (!item) {
      this.updatingAddress = "faulted";
      return;
    }

    try {
      await Api.AddEditCompanyAddress(data);
      runInAction(() => {
        this.companiesMap.get(data.id)!.data.headAddress = data.address;
        this.updatingAddress = "success";
        history.push("..");
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };
  removeAddress = async (id: string) => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;

    // Check Item Exists
    const item = this.companiesMap.get(id)?.data;
    if (!item) {
      this.updatingAddress = "faulted";
      return;
    }

    // Check Child Exists
    if (!item.headAddress) {
      this.updatingAddress = "success";
      return;
    }

    try {
      await Api.RemoveCompanyAddress({ id });
      runInAction(() => {
        item.headAddress = null;
        this.updatingAddress = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };

  getItemModerator = (
    companyId: string,
    modId: string
  ): CompanyModeratorDetails | undefined =>
    this.companiesMap.get(companyId)?.data.moderators?.filter((x) => x.id === modId)[0];

  newItemModerator = (companyId: string): AddCompanyModeratorData => ({
    id: companyId,
    userEmail: "",
    role: ModeratorRole.None,
  });

  addingModerator: ApiProcessingStatus = null;
  addModerator = async <T>(data: AddCompanyModeratorData, helpers: FormikHelpers<T>) => {
    if (this.addingModerator === true) return;
    this.addingModerator = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.companiesMap.get(data.id)?.data;
    if (!item) {
      this.addingModerator = "faulted";
      return;
    }

    // Check Child Exists
    const moderator =
      item.moderators?.filter((x) => x.moderator?.email === data.userEmail) ?? [];
    if (moderator.length) {
      this.addingModerator = "success";
      return;
    }

    try {
      const res = await Api.AddCompanyModerator(data);
      runInAction(() => {
        item.moderators ??= [];
        item.moderators.push(res);
        this.addingModerator = "success";
        history.push(window.location.pathname.replace("/new-moderator", ""));
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.addingModerator = new ApiProcessingError(err as AxiosError, "faulted");
      });
      throw err;
    }
  };

  removingModerator: ApiProcessingStatus = null;
  removeModerator = async (id: string, compId: string) => {
    if (this.removingModerator === true) return;
    this.removingModerator = true;

    // Check Item Exists
    const item = this.companiesMap.get(compId)?.data;
    if (!item) {
      this.removingModerator = "faulted";
      return;
    }

    // Check Child Exists
    const moderator = item.moderators?.filter((x) => x.id === id) ?? [];
    if (!moderator.length) {
      this.removingModerator = "success";
      return;
    }

    try {
      await Api.RemoveCompanyModerator({ id });
      runInAction(() => {
        item.moderators = item.moderators?.filter((x) => x != moderator[0]);
        this.removingModerator = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.removingModerator = "faulted";
      });
      throw err;
    }
  };

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }
}
