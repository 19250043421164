import Stores, { RootStore } from "App/Stores/Stores";
import React, { createContext, useContext } from "react";
import { NodeOrJsxChild, RenderNodeOrJsxChild } from "Utils/NodeOrJsx";

//Declare store type
const _storeContext = createContext<RootStore>(Stores);

function StoreContext(props: { children: React.ReactNode }) {
  return <_storeContext.Provider value={Stores}>{props.children}</_storeContext.Provider>;
}

// Define Types
export function useStore(): RootStore;
export function useStore<T extends keyof RootStore>(storeName: T): RootStore[T];

/**
 *Get the root store or specify the store name data
 *@ param storename specifies the name of the sub store
 * @returns typeof StoreType[storeName]
 */
export function useStore<T extends keyof RootStore>(storeName?: T) {
  const rootStore = useContext(_storeContext);
  return storeName ? rootStore[storeName] : rootStore;
}

function Consumer(
  props:
    | { children: NodeOrJsxChild<RootStore>; store?: undefined }
    | { store: keyof RootStore; children: NodeOrJsxChild<RootStore[keyof RootStore]> }
) {
  const stores = useStore();
  if (props.store) return RenderNodeOrJsxChild(stores[props.store], props.children);
  return RenderNodeOrJsxChild(stores, props.children);
}

StoreContext.Consumer = Consumer;

export default StoreContext;
