import { AddCompanyDataValidator } from "App/Api/CompanyAgent";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import LoaderSection from "Components/UI/LoaderSection";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

function CompanyAddEdit() {
  const { loadItemDetails, getItem, editItem, addNewItem, getNewOrExistingItem } =
    useStore("CompanyStore");
  const editItemId = useParams()["compId"];
  const editMode = useMemo(() => !!editItemId, [editItemId]);
  const item = useMemo(
    () => getNewOrExistingItem(editItemId),
    [editItemId, getNewOrExistingItem]
  );
  // const saving = editMode ? editingItem === true : addingNewItem === true;

  useEffect(() => {
    if (editItemId && !getItem(editItemId)) loadItemDetails(editItemId);
  }, [editItemId, loadItemDetails, getItem]);

  return (
    <>
      <AddEdit>
        {!editMode && (
          <>
            <h2 className="text-2xl mb-4 font-bold text-primary-8 drop-shadow-sm">
              New Company
            </h2>
          </>
        )}
        {editMode && (
          <>
            <h2 className="text-2xl mb-4 font-bold text-primary-8 drop-shadow-sm">
              Edit Company
            </h2>
            {!item && <LoaderSection title="Loading item..." />}
          </>
        )}
        {((editMode && !!item) || !editMode) && (
          <Formik
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={editMode ? (editItem as any) : addNewItem}
            initialValues={item}
            validationSchema={AddCompanyDataValidator}
            enableReinitialize
          >
            {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
              <Form
                onSubmit={handleSubmit}
                onBlur={handleBlur}
                onReset={handleReset}
                onChange={handleChange}
              >
                <AddEdit.TextField name="name" placeholder="Name" label="Name" />
                {/* Required Above */}
                <hr className="mb-4" />
                {/* Optional Below */}
                <AddEdit.TextField name="details" placeholder="Details" label="Details" />
                <AddEdit.TextField
                  name="supportEmail"
                  label="Support Email"
                  placeholder="email"
                />
                <AddEdit.TextField
                  name="supportPhone"
                  label="Support Phone"
                  placeholder="phone"
                />

                <FormikAlert />

                <AddEdit.Actions>
                  <FormikSubmitField>
                    {({ field, isSubmitting }) => (
                      <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                    )}
                  </FormikSubmitField>
                  <AddEdit.Actions.Cancel />
                </AddEdit.Actions>
              </Form>
            )}
          </Formik>
        )}
      </AddEdit>
    </>
  );
}

export default observer(CompanyAddEdit);
