import { useStore } from "App/Stores/StoreContext";
import AppHeading from "Components/Project/AppHeading";
import { observer } from "mobx-react-lite";
import NotificationArea from "./NotificationArea";
import StaticsArea from "./StatisticsArea";

export default observer(function DashboardPage() {
  const { userDisplayName } = useStore("AccountStore");

  return (
    <>
      <AppHeading title="Dashboard"></AppHeading>
      <div className="py-2">
        <div className="text-lg font-bold text-primary-8 drop-shadow-sm border-b pb-4">
          Welcome Back, {userDisplayName}!
        </div>
        <NotificationArea />
        <StaticsArea />
      </div>
    </>
  );
});
