import { useField } from "formik";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import _ from "lodash";
import Alert from "../UI/Alert";

export default function FormikAlert(prop: { className?: string }) {
  const [field, meta] = useField("errors");
  const HasAny =
    !!meta.error || !!field.value?.message || !!field.value?.context || !!field.value?.props;
  return (
    <ErrorBoundary>
      <fieldset
        className={`w-full ${prop.className ?? ""}`}
        hidden={!HasAny}
        name="errors"
        key="errors"
      >
        {HasAny && (
          <Alert title={field.value?.message} variant={field.value.variant}>
            {_.map(field.value.validation, (v, k) =>
              k === "message" ? null : <p className="my-1">{v}</p>
            )}
            {_.map(field.value.context, (v, k) =>
              k === "message" ? null : <p className="my-1 font-bold">{v}</p>
            )}
          </Alert>
        )}
      </fieldset>
    </ErrorBoundary>
  );
}
