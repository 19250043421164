import { Fragment, useId } from "react";
import { Nullable } from "./HelperTypes";

export function For<T>(props: {
  each?: Nullable<T[]>;
  children: (prop: T) => JSX.Element | JSX.Element[];
}) {
  const id = useId();
  return <Fragment key={id}>{props.each?.map((x) => props.children(x))}</Fragment>;
}
