import { ServerError } from "App/Models";
import axios, { AxiosError, AxiosResponse, AxiosResponseHeaders } from "axios";
import { toast } from "react-toastify";
import { PaginatedResult, Pagination } from "App/Models";
import Stores from "App/Stores/Stores";
import { history } from "index";
import AppMessages from "Utils/AppMessages";
import Sleep from "Utils/Sleep";

const handleSuccessResponse = <T>(response: AxiosResponse) => {
  // With pagination
  const pagination = response.headers["pagination"];
  if (pagination) {
    response.data = new PaginatedResult<T>(
      response.data as T[],
      JSON.parse(pagination) as Pagination
    );
    return response as AxiosResponse<PaginatedResult<T>>;
  }

  if (response.headers && response.headers) return response;
};

const handleUnauthorizedAccess = (errorHeader: AxiosResponseHeaders) => {
  if (errorHeader["www-authenticate"]?.startsWith('Bearer error="invalid_token"')) {
    Stores.CommonStore.StoreCurrentPath();
    toast.error(AppMessages.SessionExpiredToast);
    Stores.AccountStore.logout();
    return false;
  }
  return true;
};

const handleNotFound = () => {
  history.push("/not-found");
  toast.error(AppMessages.NotFoundToast);
  return false;
};

const handleServerError = (data: ServerError) => {
  Stores.CommonStore.setServerError(data);
  history.push("/server-error");
  toast.error(AppMessages.ServerErrorToast);
  return false;
};

export default () => {
  // API URL
  (axios.defaults.baseURL = `${
    process.env.NODE_ENV === "development"
      ? `https://${window.location.hostname.split(":")[0]}:7239`
      : (process.env.REACT_APP_API_URL as string)
  }/api/v1`),
    // Customize Request Before Sending It.
    axios.interceptors.request.use((config) => {
      if (!config) return;
      if (!config.headers) config.headers = {};

      //Add JWT Authorization When Available
      const token = Stores.CommonStore.token;
      if (token) config.headers.Authorization = `Bearer ${token}`;

      // Add Locale
      //   config.headers.Locale = store.localeStore.current;

      // Add ISO DateTime
      config.headers.DateTimeISO = new Date().toISOString();

      return config;
    });

  // Handle Response
  axios.interceptors.response.use(
    // When Completed Successfully with 2xx Response.
    (response: AxiosResponse) => {
      // Simulate API Delay
      if (process.env.NODE_ENV === "development")
        return Sleep(100).then(() => {
          return handleSuccessResponse(response);
        });
      return handleSuccessResponse(response);
    },

    // When It Fails, or Completed With 4xx and 5xx Responses.
    (error: AxiosError) => {
      console.error(error);
      if (error.response) {
        const { data, status, headers } = error.response;
        switch (status) {
          case 0:
            toast.warn("Network Error!");
            break;
          case 401:
            if (!handleUnauthorizedAccess(headers)) return false;
            break;
          case 404:
            return handleNotFound();
          case 408:
            toast.error(AppMessages.RequestTimeout);
            break;
          case 500:
            return handleServerError(data as ServerError);
          case 504:
            toast.error(AppMessages.ServerTimeout);
            break;
        }
      } else {
        toast.error(error.message);
      }
      return Promise.reject(error);
    }
  );
};
