import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import Leaf from "Components/Project/Leaf";
import LoaderSection from "Components/UI/LoaderSection";
import ProductsCategories from "./ProductsCategories";
import { NewProductCategoryModal } from "./NewProductCategoryModal";
import { FlexCol } from "Components/Grid/FlexBox";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Product } from "App/Api/ProductAgent";
import { ProductStatus } from "App/Models";

export default observer(function ProductsList() {
  const {
    itemsByCategory: productsByCategory,
    filterBy,
    loadingItems: loadingProducts,
  } = useStore("ProductStore");
  return (
    <>
      <FlexCol md="rowRev" wrap className="w-full items-center md:items-start">
        <FlexCol className="w-full md:w-[30%] md:h-full md:min-h-[70vh] md:max-h-[90vh] md:overflow-y-auto min-w-[280px] max-w-[360px] rounded-tl-leaf rounded-br-leaf bg-white mt-6 border shadow-ui pt-10 pb-8 px-6">
          <Leaf.Legend className={Leaf.className.Legend.Primary}>
            <NewProductCategoryModal />
          </Leaf.Legend>
          <ProductsCategories />
        </FlexCol>
        <Summary className="w-full md:w-[55%] lg:w-[68%] md:mr-4 py-4 md:py-0">
          {loadingProducts === true && <LoaderSection title="Loading Products..." />}
          {loadingProducts === "success" && (
            <For each={productsByCategory(filterBy)}>{ProductSummary}</For>
          )}
        </Summary>
      </FlexCol>
    </>
  );
});

function ProductSummary(item: Product) {
  return (
    <Summary.Card to={item.id} key={item.id}>
      <Summary.Card.Title>{item.name}</Summary.Card.Title>

      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category:"
        info={item.categoryName}
      />
      <Summary.Card.Detail
        icon={solid("info-circle")}
        title="Status:"
        info={ProductStatus[item.status]}
      />
      <Summary.Card.Detail />
      <Summary.Card.FooterImage fallbackIcon={solid("building")} src={item.photoUrl} />
    </Summary.Card>
  );
}
