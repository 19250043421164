import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ClassNames from "Utils/ClassNames";
import FontAwesome from "./FontAwesome";

export default function Spinner(props: { className?: string; size: SizeProp }) {
  return (
    <FontAwesome
      className={ClassNames("animate-spin", props.className)}
      icon={solid("spinner")}
      size={props.size}
    />
  );
}
