import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { LeafFormStyles } from "Assets/Styles/Styles";
import FormikFieldSet from "Components/Forms/FormikFieldSet";
import { FlexCol, FlexRow } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import Spinner from "Components/UI/Spinner";
import { NavLink } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { FormatDate } from "Utils/DateUtils";
import { Nullable, OmitProps } from "Utils/HelperTypes";
import { ButtonProps, DivProps, InputProps, SelectProps } from "Utils/ReactElementsTypes";

function AddEdit(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexCol
      wrap
      className={ClassNames(
        className,
        "w-full md:8/12 xl:w-7/12 py-8 px-6 shadow-ui rounded-tr-leaf rounded-bl-leaf bg-white"
      )}
      {...rest}
    />
  );
}

function AddEditTextFieldSet(props: {
  name: string;
  label: string;
  placeholder?: string;
  type?: "search" | "password" | "email" | "text" | "phone" | "date";
  disabled?: boolean;
}) {
  return (
    <FormikFieldSet name={props.name} className={LeafFormStyles.FieldSet}>
      {({ field, meta }) => (
        <>
          <AddEdit.TextInput
            {...field}
            label={props.label}
            placeholder={props.placeholder}
            type={props.type}
            disabled={props.disabled}
          />
          {meta.touched && !!meta.error && (
            <div className={LeafFormStyles.ErrorFeedback}>
              <span className="text-red-600 font-bold">{meta.error}</span>
            </div>
          )}
        </>
      )}
    </FormikFieldSet>
  );
}

AddEdit.TextField = AddEditTextFieldSet;

function AddEditSelectFieldSet(props: {
  children: JSX.Element | JSX.Element[];
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}) {
  return (
    <FormikFieldSet name={props.name} className={LeafFormStyles.FieldSet}>
      {({ field, meta }) => (
        <>
          <AddEdit.SelectInput
            {...field}
            label={props.label}
            placeholder={props.placeholder}
            disabled={props.disabled}
          >
            {props.children}
          </AddEdit.SelectInput>
          {meta.touched && !!meta.error && (
            <div className={LeafFormStyles.ErrorFeedback}>
              <span className="text-red-600 font-bold">{meta.error}</span>
            </div>
          )}
        </>
      )}
    </FormikFieldSet>
  );
}

AddEdit.SelectField = AddEditSelectFieldSet;

function AddEditTextInput(
  props: InputProps & { label: string; "data-formik-error": boolean }
) {
  const {
    className,
    label,
    id,
    children,
    "data-formik-error": invalid,
    value,
    type,
    ...rest
  } = props;
  return (
    <>
      <label htmlFor={id} className={LeafFormStyles.Label}>
        <span>{label}</span>
      </label>
      <input
        id={id}
        {...rest}
        data-formik-error={invalid}
        type={type}
        value={
          type === "date"
            ? FormatDate(value as any, {
                options: { day: "2-digit", month: "2-digit", year: "numeric" },
                locale: "en-ca",
              })
            : (value as any)
        }
        className={ClassNames(className, LeafFormStyles.Input, {
          "border-danger-5": invalid,
        })}
      />
      {children}
    </>
  );
}

AddEdit.TextInput = AddEditTextInput;

function AddEditSelectInput(
  props: SelectProps & { label: string; "data-formik-error": boolean }
) {
  const { className, label, id, children, "data-formik-error": invalid, ...rest } = props;
  return (
    <>
      <label htmlFor={id} className={LeafFormStyles.Label}>
        <span className="text-primary-6">{label}</span>
      </label>
      <select
        id={id}
        {...rest}
        data-formik-error={invalid}
        className={ClassNames(className, LeafFormStyles.Input, {
          "border-danger-5": invalid,
        })}
      >
        {children}
      </select>
    </>
  );
}

AddEdit.SelectInput = AddEditSelectInput;

function AddEditSelectInputOption(props: {
  label?: string;
  value?: string | number | readonly string[];
  title?: string;
}) {
  return <option {...props}>{props.title}</option>;
}

AddEditSelectInput.Option = AddEditSelectInputOption;
AddEdit.SelectOption = AddEditSelectInputOption;

function AddEditMainActions(props: Omit<DivProps, "className">) {
  return (
    <FlexRow
      {...props}
      className={ClassNames("gap-4 justify-end", "mt-4 md:mt-2 -mb-2 md:-mb-12")}
    />
  );
}

AddEdit.Actions = AddEditMainActions;

function AddEditMainActionsCancelButton() {
  return (
    <NavLink
      to=".."
      className={ClassNames(
        "group transition-all text-center inline",
        "px-4 py-2 min-w-[94px]",
        "rounded-full font-bold",
        "bg-gray-4 text-white",
        "hover:bg-gray-5 focus-visible:bg-gray-6 active:bg-gray-7"
      )}
    >
      <FontAwesome icon={solid("x")} size="lg" className="group-hover:inline hidden" />
      <span className="group-hover:hidden inline">Cancel</span>
    </NavLink>
  );
}

AddEditMainActions.Cancel = AddEditMainActionsCancelButton;

function AddEditMainActionsSubmitButton(
  props: OmitProps<ButtonProps, "children" | "className">
) {
  return (
    <button
      className={ClassNames(
        "group transition-all text-center inline",
        "px-4 py-2 min-w-[94px]",
        "rounded-full font-bold",
        "bg-success-4 text-white",
        "hover:bg-success-5 focus-visible:bg-success-6 active:bg-success-7"
      )}
      {...props}
    >
      {props.disabled && <Spinner size="sm" />}
      {!props.disabled && (
        <>
          <FontAwesome
            icon={solid("check")}
            size="lg"
            className="group-hover:inline hidden"
          />
          <span className="group-hover:hidden inline">Save</span>
        </>
      )}
    </button>
  );
}

AddEditMainActions.Save = AddEditMainActionsSubmitButton;

export default AddEdit;
