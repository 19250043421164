import {
  AddProjectProductDataValidator,
  AddProjectSubscriptionDataValidator,
  ProjectSubscriptionDetails,
} from "App/Api/ProjectAgent";
import { ProjectProductStatus } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import { LeafFormStyles } from "Assets/Styles/Styles";
import FormikAlert from "Components/Forms/FormikAlert";
import FormikField from "Components/Forms/FormikField";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import { Form, Formik } from "formik";
import { pushToNotFound } from "index";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";
import { EnumAsOptions } from "Utils/ReactElementUtils";

function ProjectAddEdit() {
  const { addProduct, editProduct, newItemProduct, getItem } = useStore("ProjectStore");
  const { products } = useStore("ProductStore");
  const projId = useParams()["projId"] ?? pushToNotFound();
  const editItemId = useParams()["prodId"];
  const editMode = useMemo(() => !!editItemId, [editItemId]);

  const item = useMemo(
    () =>
      editMode
        ? getItem(projId)?.data.products?.find((v) => v.id === editItemId) ??
          newItemProduct(projId)
        : newItemProduct(projId),
    [editItemId, editMode, newItemProduct, projId, getItem]
  );

  return (
    <>
      <AddEdit>
        <Formik
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={(v, h) =>
            editMode ? editProduct(v as any, projId, h) : addProduct(v as any, h)
          }
          initialValues={item}
          enableReinitialize
          validationSchema={AddProjectProductDataValidator}
        >
          {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
            <Form
              onSubmit={handleSubmit}
              onBlur={handleBlur}
              onReset={handleReset}
              onChange={handleChange}
            >
              <fieldset className={ClassNames(LeafFormStyles.FieldSet, "group")}>
                <label
                  className={ClassNames(
                    LeafFormStyles.Label,
                    "group-hover:text-primary-7 group-focus-within:text-primary-8"
                  )}
                >
                  Project
                </label>
                <input
                  disabled
                  value={getItem(projId)?.data.name ?? "-"}
                  className={ClassNames(LeafFormStyles.Input)}
                />
              </fieldset>
              <AddEdit.SelectField name="productId" label="Product" placeholder="Product">
                <option label="Select Product..." title="Select Product...">
                  Select Product...
                </option>
                <For each={products}>
                  {(item) => (
                    <option
                      title={item.categoryName + " - " + item.name}
                      label={item.categoryName + " - " + item.name}
                      value={item.id}
                    >
                      {item.categoryName + " - " + item.name}
                    </option>
                  )}
                </For>
              </AddEdit.SelectField>

              <AddEdit.TextField name="cost" placeholder="Cost" label="Cost" />
              <AddEdit.TextField name="price" placeholder="Price" label="Price" />
              <AddEdit.TextField
                name="priceCurrencyName"
                placeholder="Currency"
                label="Currency"
                disabled
              />
              <AddEdit.SelectField name="status" label="Status" placeholder="Status">
                <option label="Select Status..." title="Select Status...">
                  Select Product...
                </option>
                <EnumAsOptions enum={ProjectProductStatus} />
              </AddEdit.SelectField>
              <hr className="mb-4" />
              <AddEdit.TextField name="details" placeholder="Details" label="Details" />

              <FormikAlert />

              <AddEdit.Actions>
                <FormikSubmitField>
                  {({ field, isSubmitting }) => (
                    <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                  )}
                </FormikSubmitField>
                <AddEdit.Actions.Cancel />
              </AddEdit.Actions>
            </Form>
          )}
        </Formik>
      </AddEdit>
    </>
  );
}

export default observer(ProjectAddEdit);
function loadItemDetails(editItemId: string) {
  throw new Error("Function not implemented.");
}
