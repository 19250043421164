import { useStore } from "App/Stores/StoreContext";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Form, Formik } from "formik";
import FormikAlert from "Components/Forms/FormikAlert";
import FormikSubmitButton from "Components/Forms/FormikSubmitButton";
import FormikTextInput from "Components/Forms/FormikTextInput";
import FontAwesome from "Components/UI/FontAwesome";
import { WithFormikErrors } from "Utils/FormikErrorUtils";
import { observer } from "mobx-react-lite";
import * as AccountAgent from "App/Api/AccountAgent";
import { NavLink } from "react-router-dom";

export default observer(function LoginForm() {
  const { login } = useStore("AccountStore");

  return (
    <>
      <h2 className="font-jaldi text-primary-7 text-4xl mb-8">Sign In!</h2>
      <Formik
        validationSchema={AccountAgent.LoginDataValidator}
        initialValues={WithFormikErrors<AccountAgent.LoginData>({
          email: "",
          password: "",
        })}
        onSubmit={login}
        validateOnBlur
        validateOnChange
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit} className="w-full px-3 max-w-sm">
            <FormikTextInput
              name="email"
              placeholder="Email"
              type="email"
              icon={{ prop: solid("user"), position: "pre" }}
              border={{ width: 1, rounded: "full" }}
              showInvalid
              shadow
            />
            <FormikTextInput
              name="password"
              placeholder="Password"
              type="password"
              icon={{ prop: solid("key"), position: "pre" }}
              border={{ width: 1, rounded: "full" }}
              showInvalid
              shadow
            />
            <FormikAlert className="mb-6" />
            <FormikSubmitButton
              disabled={isSubmitting}
              variant="primary-gradient"
              isValid={isValid}
              width="full"
              shadow
              rounded="full"
            >
              {isSubmitting && (
                <FontAwesome className="mr-2 animate-spin" icon={solid("spinner")} />
              )}
              Login
            </FormikSubmitButton>
            <div className="mt-4">
              Forgot Your Password?
              <strong>
                <NavLink
                  to="/resetPass"
                  className="text-secondary-4 hover:text-primary-5 focus:text-primary-7"
                >
                  {" "}
                  Reset it!
                </NavLink>
              </strong>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
});
