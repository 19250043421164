import { DivProps } from "Utils/ReactElementsTypes";
import ClassNames from "../../Utils/ClassNames";

export type FlexTypes = true | "col" | "row" | "colRev" | "rowRev";

export type FlexProps = DivProps & {
  wrap?: boolean;
  sm?: FlexTypes;
  md?: FlexTypes;
  lg?: FlexTypes;
  xl?: FlexTypes;
  xxl?: FlexTypes;
};

export function FlexRow(props: FlexProps = { wrap: true }) {
  const { className, wrap, sm, md, lg, xl, xxl, ...rest } = props;
  return (
    <div
      className={ClassNames(
        "flex flex-row",
        {
          "flex-wrap": wrap,
          // sm
          "sm:flex-col": sm === true || sm === "col",
          "sm:flex-col-reverse": sm === "colRev",
          "sm:flex-row-reverse": sm === "rowRev",
          // md
          "md:flex-col": md === true || md === "col",
          "md:flex-row": md === "row",
          "md:flex-col-reverse": md === "colRev",
          "md:flex-row-reverse": md === "rowRev",
          // lg
          "lg:flex-col": lg === true || lg === "col",
          "lg:flex-row": lg === "row",
          "lg:flex-col-reverse": lg === "colRev",
          "lg:flex-row-reverse": lg === "rowRev",
          // xl
          "xl:flex-col": xl === true || xl === "col",
          "xl:flex-row": xl === "row",
          "xl:flex-col-reverse": xl === "colRev",
          "xl:flex-row-reverse": xl === "rowRev",
          // 2xl
          "2xl:flex-col": xxl === true || xxl === "col",
          "2xl:flex-row": xxl === "row",
          "2xl:flex-col-reverse": xxl === "colRev",
          "2xl:flex-row-reverse": xxl === "rowRev",
        },
        className
      )}
      {...rest}
    />
  );
}

export function FlexCol(props: FlexProps = { wrap: true }) {
  const { className, wrap, sm, md, lg, xl, xxl, ...rest } = props;
  return (
    <div
      className={ClassNames(
        "flex flex-col",
        {
          "flex-wrap": wrap || wrap == undefined,
          "flex-nowrap": wrap === false,
          // sm
          "sm:flex-row": sm === true || sm === "row",
          "sm:flex-col-reverse": sm === "colRev",
          "sm:flex-row-reverse": sm === "rowRev",
          // md
          "md:flex-row": md === true || md === "row",
          "md:flex-col": md === "col",
          "md:flex-col-reverse": md === "colRev",
          "md:flex-row-reverse": md === "rowRev",
          // lg
          "lg:flex-row": lg === true || lg === "row",
          "lg:flex-col": lg === "col",
          "lg:flex-col-reverse": lg === "colRev",
          "lg:flex-row-reverse": lg === "rowRev",
          // xl
          "xl:flex-row": xl === true || xl === "row",
          "xl:flex-col": xl === "col",
          "xl:flex-col-reverse": xl === "colRev",
          "xl:flex-row-reverse": xl === "rowRev",
          // 2xl
          "2xl:flex-row": xxl === true || xxl === "row",
          "2xl:flex-col": xxl === "col",
          "2xl:flex-col-reverse": xxl === "colRev",
          "2xl:flex-row-reverse": xxl === "rowRev",
        },
        className
      )}
      {...rest}
    />
  );
}

export default {
  FlexRow,
};
