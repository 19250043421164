import { ApiQuery } from "App/Models";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";

export const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const AxiosRequests = {
  get: <T = void>(url: string, query?: ApiQuery) => {
    if (query) {
      const params = new URLSearchParams();
      _.forEach(query, (v, k) => {
        if (v && v.toString) params.append(k, v.toString());
      });
      return axios.get<T>(url, { params }).then(responseBody);
    }
    return axios.get<T>(url).then(responseBody);
  },
  post: <T = void>(url: string, body?: unknown) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T = void>(url: string, body?: unknown) => axios.put<T>(url, body).then(responseBody),
  delete: <T = void>(url: string, body?: unknown) =>
    axios.delete<T>(url, { data: body }).then(responseBody),
};

export type AxiosRequestsType = typeof AxiosRequests;

export default AxiosRequests;
