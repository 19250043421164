import { ModeratorRole } from "App/Models";
import { Fragment, useId } from "react";
import { EnumValues } from "Utils";
import { Enum, Nullable } from "./HelperTypes";

export function For<T>(props: {
  each?: Nullable<T[]>;
  children: (prop: T) => JSX.Element | JSX.Element[];
}) {
  const id = useId();
  return <Fragment key={id}>{props.each?.map((x) => props.children(x))}</Fragment>;
}

export function EnumAsOptions<T extends Enum>(props: {
  enum: T;
  filter?: "string" | "number";
}) {
  return (
    <For each={EnumValues(props.enum, "number")}>
      {(value) => (
        <option
          value={value}
          title={props.enum[value] as string}
          label={props.enum[value] as string}
        >
          {props.enum[value]}
        </option>
      )}
    </For>
  );
}
