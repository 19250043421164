import { UpdateUserDataValidator } from "App/Api/UserManagementAgent";
import { Gender } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import { Form, Formik, FormikHelpers } from "formik";
import { pushToNotFound } from "index";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ClassNames from "Utils/ClassNames";

function UserAddEdit() {
  const { editItem, getNewOrExistingItem } = useStore("UsersStore");
  const userEmail = useParams()["userEmail"] ?? pushToNotFound();

  return (
    <>
      <AddEdit>
        <Formik
          onSubmit={editItem}
          initialValues={getNewOrExistingItem(userEmail)}
          validationSchema={UpdateUserDataValidator}
        >
          {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
            <Form
              onSubmit={handleSubmit}
              onBlur={handleBlur}
              onReset={handleReset}
              onChange={handleChange}
            >
              <AddEdit.TextField name="displayName" label="Display Name" placeholder="name" />
              <AddEdit.TextField
                name="email"
                placeholder="Email"
                label="Email"
                type="email"
              />

              <hr className="mt-2 mb-4" />
              <AddEdit.TextField name="firstName" label="First Name" placeholder="name" />
              <AddEdit.TextField name="lastName" label="Last Name" placeholder="name" />
              <AddEdit.TextField name="phoneNumber" label="Phone" placeholder="Phone" />
              <AddEdit.SelectField name="gender" label="Gender" placeholder="Gender">
                <>
                  <AddEdit.SelectInput.Option
                    value={Gender.None}
                    label="Select Gender..."
                    title="Select Gender..."
                  />
                  <AddEdit.SelectInput.Option value={Gender.Male} label="Male" title="Male" />
                  <AddEdit.SelectInput.Option
                    value={Gender.Female}
                    label="Female"
                    title="Female"
                  />
                </>
              </AddEdit.SelectField>

              <FormikAlert />

              <AddEdit.Actions>
                <FormikSubmitField>
                  {({ field, isSubmitting }) => (
                    <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                  )}
                </FormikSubmitField>
                <AddEdit.Actions.Cancel />
              </AddEdit.Actions>
            </Form>
          )}
        </Formik>
      </AddEdit>
    </>
  );
}

export default observer(UserAddEdit);
