import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ActionStore from "Components/Contexts/ActionContext";
import AppHeading from "Components/Project/AppHeading";
import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
import Summary from "Components/Project/Summary";
import Sleep from "Utils/Sleep";

export default function Dev() {
  return (
    <>
      <AppHeading title="Devlopment" />
      <Details>
        <Details.Item>
          <Details.Item.Main>
            <Details.Item.Main.Title>Title</Details.Item.Main.Title>
            <Details.Item.Main.DetailsList
              values={[
                { icon: solid("info-circle"), title: "title", info: "info" },
                { icon: solid("info-circle"), title: "title", info: "info" },
              ]}
            />
            <Details.Item.Main.Actions>
              <Details.Item.Main.Actions.Edit />
              <PopUp actions={{ ok: () => Sleep(1500).then(() => true) }}>
                <PopUp.Open.AsDeleteButton />
                <PopUp.DeleteDialog
                  title="Delete This"
                  message="Are you sure you want to remove that?"
                />
              </PopUp>
            </Details.Item.Main.Actions>
          </Details.Item.Main>
          <Details.Item.Extra
            values={[
              { icon: solid("info"), title: "array", info: "info" },
              { icon: solid("info"), title: "title", info: "info here" },
              { icon: solid("info"), title: "elt", info: "info there" },
            ]}
          />
        </Details.Item>
        <Details.ChildList>
          <Details.ChildList.Legend>
            <Details.ChildList.Legend.AddButton to="/" />
            <Details.ChildList.Legend.Heading>Longer</Details.ChildList.Legend.Heading>
          </Details.ChildList.Legend>
          <Summary.Card>
            <Summary.Card.Title>Title</Summary.Card.Title>
            <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
            <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
          </Summary.Card>
          <Summary.Card>
            <Summary.Card.Title>Title</Summary.Card.Title>
            <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
            <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
          </Summary.Card>
          <Summary.Card>
            <Summary.Card.Title>Title</Summary.Card.Title>
            <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
            <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
          </Summary.Card>
          <Summary.Card>
            <Summary.Card.Title>Title</Summary.Card.Title>
            <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
            <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
          </Summary.Card>
          <Summary.Card>
            <Summary.Card.Title>Title</Summary.Card.Title>
            <Summary.Card.Detail icon={solid("circle")} title="title" info="guess what" />
            <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
          </Summary.Card>
        </Details.ChildList>
      </Details>
    </>
  );
}

function DummyDetail() {
  return (
    <>
      {/* ******************************************************************************* */}
    </>
  );
}
