import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useStore } from "App/Stores/StoreContext";
import AppHeading from "Components/Project/AppHeading";

function ProductsPage() {
  const productStore = useStore("ProductStore");
  useEffect(() => {
    if (!productStore.loadingCategories) productStore.loadCategories();
    if (!productStore.loadingItems) productStore.loadItems();
  });
  return (
    <>
      <AppHeading title="Products" newPath="/app/products/new" />
      <Outlet />
    </>
  );
}

export default ProductsPage;
