export function RequiredMessage(prop: string) {
  return prop + " field is required.";
}

export function EmailMessage(prop: string) {
  return prop + " value is not an email format.";
}

export function NameMessage(prop: string) {
  return prop + " contains unknown characters.";
}

export function MinStringMessage(prop: string, count: number) {
  return prop + " length must be more than " + count.toString() + ".";
}

export function MaxStringMessage(prop: string, count: number) {
  return prop + " length must be less than " + count.toString() + ".";
}

export function MinValueMessage(prop: string, value: number) {
  return prop + " value must be greater than " + value.toString() + ".";
}

export function MaxValueMessage(prop: string, value: number) {
  return prop + " value must be greater than " + value.toString() + ".";
}

export function PositiveValueMessage(prop: string) {
  return prop + " value must be positive.";
}

export function NegativeValueMessage(prop: string) {
  return prop + " value must be negative.";
}

export function MinItemsCountMessage(prop: string, value: number) {
  return prop + " must have at least " + value.toString() + " items.";
}

export function MaxItemsCountMessage(prop: string, value: number) {
  return prop + " can't have more than " + value.toString() + " items.";
}

export function NotAPhoneNumberMessage(prop: string) {
  return prop + " is not a phone number.";
}

export function OneOfEnumMessage(prop: string) {
  return prop + " must be one of provided options.";
}

export default {
  Required: RequiredMessage,
  Email: EmailMessage,
  Name: NameMessage,
  MinString: MinStringMessage,
  MaxString: MaxStringMessage,
  PositiveValue: PositiveValueMessage,
  NegativeValue: NegativeValueMessage,
  MinValue: MinValueMessage,
  MaxValue: MaxValueMessage,
  MinItemsCount: MinItemsCountMessage,
  MaxItemsCount: MaxItemsCountMessage,
  NotAPhoneNumber: NotAPhoneNumberMessage,
  OneOfEnum: OneOfEnumMessage,
};
