import { PhoneRegex } from "./../../Utils/ValidationRegex";
import YupUtils from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";
import { Nullable } from "Utils/HelperTypes";
import * as Yup from "yup";
import { Gender, ApiVoidPromise, LoggedUser, ApiPromise, User, Address } from "../Models";
import ValidationMessages from "Utils/ValidationMessages";
import { EnumValues } from "Utils";

// *******************************************************
// * Account Api                              *
// *******************************************************

const basePath = "/account";

// *****************************************
// * Login

export interface LoginData {
  email: Nullable<string>;
  password: Nullable<string>;
}

export const LoginDataValidator: Yup.SchemaOf<LoginData> = Yup.object({
  email: YupUtils.RequiredEmail("Email"),
  password: YupUtils.RequiredString("Password")
    .min(6, ValidationMessages.MinString("Password", 6))
    .max(64, ValidationMessages.MaxString("Password", 64)),
});

export interface Api {
  Login: ApiPromise<LoggedUser, LoginData>;
}

// *****************************************
// * Change Password

export interface ChangePasswordData {
  currentPassword: Nullable<string>;
  newPassword: Nullable<string>;
  confirmNewPassword: Nullable<string>;
}

export const ChangePasswordDataValidator: Yup.SchemaOf<ChangePasswordData> = Yup.object({
  currentPassword: YupUtils.RequiredString("Password")
    .min(6, ValidationMessages.MinString("Password", 6))
    .max(64, ValidationMessages.MaxString("Password", 64)),
  newPassword: YupUtils.ComplexPassword("Password"),
  confirmNewPassword: YupUtils.ComplexPassword("Password").oneOf(
    [Yup.ref("newPassword")],
    "Passwords don't match."
  ),
});

export interface Api {
  ChangePassword: ApiVoidPromise<ChangePasswordData>;
}

// *****************************************
// * Request Password Reset Link

export interface RequestPasswordResetLinkData {
  email: Nullable<string>;
}

export const RequestPasswordResetLinkDataValidator: Yup.SchemaOf<RequestPasswordResetLinkData> =
  Yup.object({
    email: YupUtils.RequiredEmail("Email"),
  });

export interface Api {
  RequestPasswordResetLink: ApiVoidPromise<RequestPasswordResetLinkData>;
}

// *****************************************
// * Reset Password

export interface ResetPasswordData {
  email: Nullable<string>;
  token: Nullable<string>;
  newPassword: Nullable<string>;
  confirmNewPassword: Nullable<string>;
}

export const ResetPasswordDataValidator: Yup.SchemaOf<ResetPasswordData> = Yup.object({
  email: YupUtils.RequiredEmail("Email"),
  token: YupUtils.RequiredString("Token"),
  newPassword: YupUtils.ComplexPassword("Password"),
  confirmNewPassword: YupUtils.ComplexPassword("Password").oneOf(
    [Yup.ref("newPassword")],
    "Passwords don't match."
  ),
});

export interface Api {
  ResetPassword: ApiVoidPromise<ResetPasswordData>;
}

// *****************************************
// * Update Profile

export interface UpdateProfileData {
  displayName: string;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  gender: Gender;
}

export const UpdateProfileDataValidator: Yup.SchemaOf<UpdateProfileData> = Yup.object({
  displayName: YupUtils.RequiredString("Display Name").default(null),
  firstName: Yup.string()
    .nullable()
    .optional()
    .max(64, ValidationMessages.MaxString("First Name", 64))
    .default(null),
  lastName: Yup.string()
    .nullable()
    .optional()
    .max(64, ValidationMessages.MaxString("Last Name", 64))
    .default(null),
  gender: YupUtils.RequiredEnum("Gender", Gender),
}).default({});

export interface Api {
  UpdateProfile: ApiVoidPromise<UpdateProfileData>;
  AddEditProfileAddress: ApiVoidPromise<Address>;
  RemoveProfileAddress: ApiVoidPromise;
}

// *****************************************
// * Other Methods

export interface Api {
  RefreshToken: ApiPromise<LoggedUser>;
  CurrentAccount: ApiPromise<User>;
  Logout: ApiVoidPromise;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  Login: async (data: LoginData) =>
    await AxiosRequests.post<LoggedUser>(AccountApiPaths.Login, data),
  RefreshToken: async () =>
    await AxiosRequests.post<LoggedUser>(AccountApiPaths.RefreshToken),
  CurrentAccount: async () => await AxiosRequests.get<User>(AccountApiPaths.CurrentAccount),
  Logout: async () => await AxiosRequests.get(AccountApiPaths.Logout),
  ChangePassword: async (data: ChangePasswordData) =>
    await AxiosRequests.put(AccountApiPaths.ChangePassword, data),
  RequestPasswordResetLink: async (data: RequestPasswordResetLinkData) =>
    await AxiosRequests.post(AccountApiPaths.RequestPasswordResetLink, data),
  ResetPassword: async (data: ResetPasswordData) =>
    await AxiosRequests.put(AccountApiPaths.ResetPassword, data),
  UpdateProfile: async (data: UpdateProfileData) =>
    await AxiosRequests.put(AccountApiPaths.UpdateProfile, data),
  AddEditProfileAddress: async (address: Address) =>
    await AxiosRequests.put(AccountApiPaths.UpdateProfileAddress, { address }),
  RemoveProfileAddress: async () =>
    await AxiosRequests.put(AccountApiPaths.UpdateProfileAddress),
};

const AccountApiPaths = {
  Login: `${basePath}/login`,
  RefreshToken: `${basePath}/refreshToken`,
  CurrentAccount: `${basePath}`,
  Logout: `${basePath}/logout`,
  ChangePassword: `${basePath}/changePassword`,
  RequestPasswordResetLink: `${basePath}/requestPassReset`,
  ResetPassword: `${basePath}/resetPass`,
  UpdateProfile: `${basePath}/profile`,
  UpdateProfileAddress: `${basePath}/profile/address`,
};
