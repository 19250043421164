import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import Assets from "Assets";
import FontAwesome from "../UI/FontAwesome";
import { FlexRow } from "Components/Grid/FlexBox";
import ClassNames from "Utils/ClassNames";

export interface AppHeadingProps {
  title: string;
  newPath?: string;
  showBrand?: boolean;
}

export default function AppHeading(props: AppHeadingProps) {
  const titlePath = useMemo(() => {
    const ps = window.location.pathname.split("/");
    return "/" + ps[1] + "/" + ps[2];
  }, []);
  return (
    <FlexRow className="m-0 p-0 mb-4 pt-1 md:pt-0 pb-2 border-b border-secondary-5 items-center">
      <a href="/">
        <img
          src={Assets.Images.Logo}
          className="w-12 h-10 md:w-16 md:h-12 object-contain inline border-r pr-2 md:pr-4 border-secondary-4"
        />
      </a>
      {/* {props.showBrand && (
        )} */}
      <NavLink
        to={titlePath}
        className="mx-2 md:mx-4 font-bold text-4xl inline text-secondary-6 hover:text-secondary-7 focus:hover:text-secondary-8 dark:text-secondary-4"
      >
        {props.title}
      </NavLink>
      {props.newPath && (
        <NavLink
          to={props.newPath ?? titlePath + "/new"}
          className={ClassNames(
            "py-2 px-2 text-center md:px-4 ml-auto mr-12 md:mr-0 text-base",
            "rounded-full w-10 min-w-fit align-middle",
            "bg-secondary-3 text-white/90 hover:bg-secondary-4 focus:bg-secondary-6 "
          )}
        >
          <FontAwesome icon={solid("plus")} size="lg" />
          <span className="hidden md:inline md:ml-2">Add New</span>
        </NavLink>
      )}
    </FlexRow>
  );
}
