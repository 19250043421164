import { Pagination } from "../Models";
import { Getter, Setter } from "Utils/GetterSetter";
import * as signalR from "@microsoft/signalr";
import { toast } from "react-toastify";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import _ from "lodash";
import { RootStore } from "./Stores";
import { Api, ApiNotification, NotificationsQuery } from "App/Api/CommonAgent";

export class NotificationStore {
  // =========================
  // statistics
  notifications: ApiNotification[] = [];
  loadingNotifications: boolean | "loaded" | "failed" | null = null;
  predicates: NotificationsQuery = { PageNumber: 1, PageSize: 25, UnreadOnly: false };
  pagination: Pagination | null = null;

  loadNotifications = async () => {
    if (this.loadingNotifications === true) return;
    this.loadingNotifications = true;

    try {
      const res = await Api.GetNotification(this.predicates);
      res.data.forEach(this.addNotification);
      runInAction(() => {
        this.pagination = res.pagination;
        this.loadingNotifications = "loaded";
      });
    } catch (err) {
      runInAction(() => {
        this.loadingNotifications = "failed";
      });
      console.error(err);
    }
  };

  addNotification = (item: ApiNotification) => {
    this.notifications = [item, ...this.notifications];
  };

  loadNextPage = () => {
    if (this.loadingNotifications === true) return;
    if (this.pagination?.totalPages ?? 0 > this.predicates.PageNumber)
      this.predicates.PageNumber++;
  };

  toggleReadOnly = () => {
    this.predicates.UnreadOnly = !this.predicates.UnreadOnly;
  };

  getPredicate: Getter<NotificationsQuery> = (prop) => {
    return this.predicates[prop];
  };

  setPredicate: Setter<NotificationsQuery> = (predicate, value) => {
    runInAction(() => {
      this.predicates[predicate] = value;
      if (predicate === "UnreadOnly") this.setPredicate("PageNumber", 1);
    });
  };

  setPagination(value: Pagination) {
    this.pagination = value;
  }

  hubConnection: signalR.HubConnection | null = null;

  get newNotifications() {
    return _.filter(Array.from(this.notifications.values()), (x) => x.viewedAt === null);
  }

  createHubConnection = () => {
    this.hubConnection ??= new signalR.HubConnectionBuilder()
      .withUrl(
        `${
          process.env.NODE_ENV === "development"
            ? `https://${window.location.hostname.split(":")[0]}:7239`
            : (process.env.REACT_APP_API_URL as string)
        }/hub/notification`,
        {
          accessTokenFactory: () => this.rootStore.CommonStore.token ?? "",
        }
      )
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.hubConnection.on("NewNotification", (notification: ApiNotification) => {
      runInAction(() => {
        if (!this.pagination)
          this.pagination = {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            itemsPerPage: this.predicates.PageSize,
          };
        this.pagination.totalItems++;
        this.addNotification(notification);
      });
      toast.info("You have received a new notification.");
    });

    this.hubConnection.on("NotificationViewed", (notificationId: string) => {
      _.forEach(this.notifications, (x) => {
        runInAction(() => {
          if (x.id === notificationId) {
            x.isViewed = true;
            x.viewedAt = new Date();
          }
        });
      });
    });
  };

  runHubConnection = () => {
    if (
      this.hubConnection &&
      this.hubConnection.state !== signalR.HubConnectionState.Disconnected
    )
      return;

    if (!this.hubConnection) this.createHubConnection();

    this.hubConnection
      ?.start()
      .then(this.loadNotifications)
      .catch((err) => {
        toast.warn("Couldn't setup live notifications. Please, refresh the page!");
        console.error(err);
      });
  };

  stopHubConnection = () => {
    this.hubConnection?.stop().catch((err) => console.error(err));
    this.hubConnection = null;
  };

  markViewed = (id: string) => {
    Api.MarkViewed(id).then(() => {
      this.notifications.forEach((i) => {
        if (i.id === id)
          runInAction(() => {
            i.isViewed = true;
          });
      });
    });
  };

  readonly rootStore: RootStore;
  constructor(root: RootStore) {
    makeAutoObservable(this);
    reaction(
      () => this.predicates.PageNumber,
      () => this.loadNotifications()
    );
    reaction(
      () => this.predicates.UnreadOnly,
      () => this.loadNotifications()
    );
    this.rootStore = root;
  }
}
