import { Address } from "./../Models/index";
import { FaxErrorHandler } from "Utils/FormikErrorUtils";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { RootStore } from "./Stores";
import { history } from "index";
import defaultUserImage from "Assets/Images/userPlaceHolder.png";
import { EmptyFaxErrors } from "../../Utils/FormikErrorUtils";
import { ApiProcessingStatus, LoggedUser } from "App/Models";
import * as AccountAgent from "App/Api/AccountAgent";

export class AccountStore {
  loggedUser: LoggedUser | null = null;
  refreshTokenTimeout?: NodeJS.Timeout;

  userNameOptions: [] | unknown;
  loading = false;

  get isLoggedIn() {
    return !!this.loggedUser;
  }

  get isAdmin() {
    return this.loggedUser?.isAdmin ?? false;
  }
  get isClient() {
    return this.loggedUser?.isClient ?? false;
  }

  get userPhoto() {
    return this.loggedUser?.photoUrl ?? defaultUserImage;
  }

  get userDisplayName() {
    return this.loggedUser?.displayName;
  }

  get userEmail() {
    return this.loggedUser?.email;
  }

  get userToken() {
    return this.loggedUser?.token;
  }

  accountProcessingResult: ApiProcessingStatus = null;
  login = async <T>(creds: AccountAgent.LoginData, helpers: FormikHelpers<T>) => {
    if (this.accountProcessingResult === true) return;
    this.accountProcessingResult = true;

    EmptyFaxErrors(helpers);

    try {
      // Try to log user in and returns a user when success.
      const user = await AccountAgent.Api.Login(creds);
      // Login success, loading app.
      this.rootStore.CommonStore.setAppLoadingStatus(true);
      // Say Hi1
      toast.success("Welcome, " + user.displayName + "!");
      // store token
      this.rootStore.CommonStore.setToken(user.token);
      // store logged user info
      runInAction(() => {
        this.loggedUser = user;
      });
      // If user requested a url which required login
      if (this.rootStore.CommonStore.forwardedRef) {
        // Redirect to it
        history.push(this.rootStore.CommonStore.forwardedRef);
        runInAction(() => (this.rootStore.CommonStore.forwardedRef = null));
      } else {
        // Redirect User to App
        history.push("/");
      }
      this.rootStore.CommonStore.setAppLoadingStatus(false);
      this.startRefreshTokenTimer();
      return true;
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      throw error;
    } finally {
      this.endLogin();
    }
  };
  requestPasswordResetLink = async <T>(
    creds: AccountAgent.RequestPasswordResetLinkData,
    helpers: FormikHelpers<T>
  ) => {
    if (this.accountProcessingResult === true || this.accountProcessingResult === "success")
      return;
    this.accountProcessingResult = true;

    EmptyFaxErrors(helpers);

    try {
      await AccountAgent.Api.RequestPasswordResetLink(creds);
      // Say Hi
      toast.success("Password reset link is sent successfully!");
      // If user requested a url which required login
      history.push("/");

      return true;
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
    } finally {
      runInAction(() => {
        this.accountProcessingResult = "success";
      });
    }
  };
  resetPassword = async <T>(
    creds: AccountAgent.ResetPasswordData,
    helpers: FormikHelpers<T>
  ) => {
    if (this.accountProcessingResult === true) return;
    this.accountProcessingResult = true;

    EmptyFaxErrors(helpers);

    try {
      await AccountAgent.Api.ResetPassword(creds);
      // Say Hi1
      toast.success("Password reset successfully!");

      history.push("/login");
      runInAction(() => {
        this.accountProcessingResult = "success";
      });
      return true;
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      throw error;
    } finally {
      runInAction(() => {
        this.accountProcessingResult = "faulted";
      });
    }
  };

  private startRefreshTokenTimer() {
    if (!this.loggedUser) return;
    const jwtToken = JSON.parse(window.atob(this.loggedUser.token.split(".")[1])) as {
      exp: number;
    };
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  refreshToken = async () => {
    console.log("refresh user token");
    this.stopRefreshTokenTimer();
    try {
      const user = await AccountAgent.Api.RefreshToken({});
      runInAction(() => (this.loggedUser = user));
      this.rootStore.CommonStore.setToken(user.token);
      this.startRefreshTokenTimer();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  loadAppUser = async () => {
    if (this.accountProcessingResult) return;
    this.accountProcessingResult = true;

    this.rootStore.CommonStore.setAppLoadingStatus(true);

    if (this.refreshTokenTimeout) {
      return this.endLogin();
    }

    if (this.isLoggedIn) {
      this.startRefreshTokenTimer();
      return this.endLogin();
    }

    if (this.rootStore.CommonStore.token) {
      await this.refreshToken();
      return this.endLogin();
    }
    if (!this.rootStore.CommonStore.forwardedRef)
      this.rootStore.CommonStore.StoreCurrentPath();
    this.endLogin();
  };

  endLogin = () => {
    if (this.isLoggedIn) {
      this.rootStore.prepareApp();
    }
    this.rootStore.CommonStore.setAppLoadingStatus(false);
    this.accountProcessingResult = false;
  };

  logout = async () => {
    this.stopRefreshTokenTimer();
    this.rootStore.NotificationStore.stopHubConnection();
    try {
      await AccountAgent.Api.Logout({});
    } finally {
      runInAction(() => {
        this.rootStore.CommonStore.setToken(null);
        this.loggedUser = null;
      });
      window.location.href = "/login"; // Data must be removed and everything must refresh after logout
    }
  };

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  cleanUp = () => {
    this.loggedUser = null;
    this.stopRefreshTokenTimer();
  };

  updatingProfile: ApiProcessingStatus = null;
  updateProfile = async <T>(
    data: AccountAgent.UpdateProfileData,
    helpers: FormikHelpers<T>
  ) => {
    if (this.updatingProfile === true) return;
    this.updatingProfile = true;
    EmptyFaxErrors(helpers);
    try {
      await AccountAgent.Api.UpdateProfile(data);
      runInAction(() => {
        if (this.loggedUser) this.loggedUser = { ...this.loggedUser, ...data };
        this.updatingProfile = "success";
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.updatingProfile = "faulted";
      });
      throw err;
    }
  };

  updatingAddress: ApiProcessingStatus = null;
  addEditAddress = async <T>(data: Address, helpers: FormikHelpers<T>) => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;
    EmptyFaxErrors(helpers);
    try {
      await AccountAgent.Api.AddEditProfileAddress(data);
      runInAction(() => {
        if (this.loggedUser) this.loggedUser.address = data;
        this.updatingAddress = "success";
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };
  removeAddress = async () => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;

    if (!this.loggedUser?.address) {
      this.updatingAddress = "success";
      return;
    }

    try {
      await AccountAgent.Api.RemoveProfileAddress({});
      runInAction(() => {
        if (this.loggedUser) this.loggedUser.address = null;
        this.updatingAddress = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };

  readonly rootStore: RootStore;
  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.rootStore = root;
  }
}
