import { useEffect } from "react";

export function ScrollToTop(props: { on: unknown; children?: JSX.Element }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [props.on]);
  return props.children ?? null;
}

export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return null;
}
