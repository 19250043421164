import ErrorBoundary from "Utils/ErrorBoundary";
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from "formik";
import { useId, useMemo, useRef } from "react";
import {
  DivProps,
  FieldSetProps,
  InputProps,
  LabelProps,
  TextInputProps,
} from "Utils/ReactElementsTypes";
import ClassNames from "Utils/ClassNames";

export interface FormikFieldSetProps extends Omit<FieldSetProps, "children"> {
  name: string;
  children: <T>(props: {
    field: FieldInputProps<string | number | string[] | undefined> & {
      id: string;
      "data-formik-error": boolean;
    };
    meta: FieldMetaProps<T>;
    helpers: FieldHelperProps<T>;
  }) => JSX.Element | React.ReactNode;
}

function FormikFieldSet(props: FormikFieldSetProps) {
  const { children, ...rest } = props;
  const [fField, meta, helpers] = useField(props.name);
  const id = useId();
  const field = useMemo(() => {
    return {
      ...fField,
      id,
      "data-formik-error": !!meta.error && meta.touched,
    };
  }, [fField, id, meta]);
  return (
    <ErrorBoundary key={id + "ErrBound"}>
      <fieldset id={id + "fs"} key={id + "FsKey"} {...rest}>
        {children({ field, meta, helpers })}
      </fieldset>
    </ErrorBoundary>
  );
}

export function Label(props: LabelProps) {
  return <label {...props} />;
}

function InputWithIcon(props: DivProps) {
  const { className, ...rest } = props;
  return <div {...rest} className={ClassNames("flex flex-row p-0 m-0", className)} />;
}

FormikFieldSet.InputWithIcon = InputWithIcon;

export function Input(props: InputProps) {
  const inp = useRef<HTMLInputElement>(null);
  const { useNativeValidation, invalidMessage } = props;
  return (
    <input
      ref={inp}
      {...props}
      onInvalid={(e) => {
        if (useNativeValidation) inp.current?.setCustomValidity(invalidMessage ?? "");
        if (props.onInvalid) props.onInvalid(e);
      }}
      onBlur={(e) => {
        if (useNativeValidation) inp.current?.setCustomValidity(invalidMessage ?? "");
        if (props.onBlur) props.onBlur(e);
      }}
      onInput={(e) => {
        if (useNativeValidation) inp.current?.setCustomValidity("");
        if (props.onInput) props.onInput(e);
      }}
    />
  );
}

FormikFieldSet.Input = Input;

export function TextInput(props: TextInputProps) {
  return <Input {...props} />;
}

FormikFieldSet.TextInput = TextInput;

export default FormikFieldSet;
