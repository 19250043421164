import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
import { useParams } from "react-router-dom";
import { history } from "index";
import { useEffect, useMemo } from "react";
import { FormatDate } from "Utils/DateUtils";
import { Nullable } from "Utils/HelperTypes";
import {
  ProjectDetails,
  ProjectProductDetails,
  ProjectSubscriptionDetails,
} from "App/Api/ProjectAgent";
import { ProjectModeratorDetails } from "App/Api/ProjectAgent";
import { NavLink } from "react-router-dom";

export default observer(function ProjectDetails() {
  const id = useParams()["projId"] ?? (history.push("/app/not-found") as unknown as string);
  const subId = useParams()["prodId"];
  const { getItem, removeProduct, root } = useStore("ProjectStore");
  const item = getItem(id);
  const data = item?.data;
  const sub = useMemo(
    () => data?.products?.find((x) => x.id === subId),
    [data, subId]
  ) as ProjectProductDetails;

  const parentSub = root.ProductStore.products.find((x) => x.id === sub.productId);

  return (
    <Details>
      <Details.Item>
        <Details.Item.Main>
          <NavLink to="../../..">
            <Details.Item.Main.Title>{item?.data?.name}</Details.Item.Main.Title>
          </NavLink>
          <Details.Item.Main.Title>{parentSub?.name}</Details.Item.Main.Title>
          <Details.Item.Main.DetailsList
            values={[
              {
                icon: solid("money-bill"),
                title: "Cost",
                info: ((sub?.cost ?? "-") as string) + "$",
              },
              {
                icon: solid("money-bill"),
                title: "Price",
                info: ((sub.price ?? "-") as string) + "$",
              },
            ]}
          />
          <Details.Item.Main.Actions>
            <Details.Item.Main.Actions.Edit />
            <PopUp
              actions={{
                ok: () => removeProduct(sub.id, item?.data.id ?? "").then(() => true),
              }}
            >
              <PopUp.Open.AsDeleteButton />
              <PopUp.DeleteDialog
                title="Deleting Project!"
                message={`Are you sure you want to remove '${
                  parentSub?.name ?? ""
                }' from project '${item?.data.name ?? ""}'?`}
              />
            </PopUp>
          </Details.Item.Main.Actions>
        </Details.Item.Main>
        <Details.Item.Extra>
          <Details.Item.Extra.Detail>
            <Details.Item.Extra.Detail.Icon icon={solid("info")} />
            <Details.Item.Extra.Detail.Title>Details:</Details.Item.Extra.Detail.Title>
            <Details.Item.Extra.Detail.Info>
              {sub.details ?? "-"}
            </Details.Item.Extra.Detail.Info>
          </Details.Item.Extra.Detail>
        </Details.Item.Extra>
      </Details.Item>
    </Details>
  );
});

function ProjectProjectDetails(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-project`} />
        <Details.ChildList.Legend.Heading>Projects</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectProjectSummary}</For>
    </Details.ChildList>
  );
}

function ProjectProjectSummary(item: ProjectDetails) {
  return (
    <Summary.Card key={item.id} to={`/app/project/${item.id}`}>
      <Summary.Card.Title>{item.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}

function ProjectModeratorDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectModeratorDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-moderator`} />
        <Details.ChildList.Legend.Heading>Moderators</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectModeratorSummary}</For>
    </Details.ChildList>
  );
}

function ProjectModeratorSummary(item: ProjectModeratorDetails) {
  return (
    <Summary.Card key={item.id} to={`/app/company/${item.id}`}>
      <Summary.Card.Title>{item.moderator?.displayName}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}

function ProjectSubscriptionDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectSubscriptionDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-subscription`} />
        <Details.ChildList.Legend.Heading>Subscriptions</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectSubscriptionSummary}</For>
    </Details.ChildList>
  );
}

function ProjectSubscriptionSummary(item: ProjectSubscriptionDetails) {
  return (
    <Summary.Card key={item.id} to={`/app/company/${item.id}`}>
      <Summary.Card.Title>{item.subscription?.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category"
        info={item.subscription?.categoryName}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Start On"
        info={FormatDate(item.startAt)}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="End On"
        info={FormatDate(item.endAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}

function ProjectProductDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectProductDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-product`} />
        <Details.ChildList.Legend.Heading>Products</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectProductSummary}</For>
    </Details.ChildList>
  );
}

function ProjectProductSummary(item: ProjectProductDetails) {
  return (
    <Summary.Card key={item.id} to={`/app/company/${item.id}`}>
      <Summary.Card.Title>{item.product?.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category"
        info={item.product?.categoryName}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}
