import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";

export default observer(function ServerError() {
  const commonStore = useStore("CommonStore");
  return (
    <div>
      <div id="content">
        <div className="container text-center">
          <h1>Server Error!</h1>
          <h3 className="text-6 mb-3">{commonStore.error?.message}</h3>
          {commonStore.error?.details && (
            <p className="text-3 text-muted">{commonStore.error.details}</p>
          )}
        </div>
      </div>
    </div>
  );
});
