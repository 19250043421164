import { Variant } from "./UI";
import FontAwesome from "./FontAwesome";
import Flex from "../Grid/Flex";
import ClassNames from "../../Utils/ClassNames";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";

export interface AlertProps {
  variant?: Variant;
  title?: string;
  content?: string;
  link?: { see?: string; url: string; as: "link" | "a" };
  children?: React.ReactNode | JSX.Element;
}

export default function Alert(props: AlertProps) {
  const { variant, title, content, link, children } = props;

  return (
    <div className="m-0 p-0 w-full">
      <Flex.Box
        className={ClassNames(
          "flex-row items-stretch justify-center w-full h-full m-0 pl-1 pr-2 py-4",
          "rounded-tr-2xl rounded-bl-2xl shadow-ui border border-danger-3",
          {
            [ClassNames("bg-gray-2 text-gray-6", "dark:bg-gray-7 dark:text-gray-0")]:
              !variant, // Default
            [ClassNames(
              "bg-primary-0 text-primary-6",
              "dark:bg-primary-7 dark:text-primary-0"
            )]: variant === "primary",
            [ClassNames(
              "bg-secondary-0 text-secondary-6",
              "dark:bg-secondary-7 dark:text-secondary-0"
            )]: variant === "secondary",
            [ClassNames(
              "bg-warning-2 text-warning-8",
              "dark:bg-warning-7 dark:text-warning-0"
            )]: variant === "warning",
            [ClassNames(
              "bg-success-2 text-success-8",
              "dark:bg-success-7 dark:text-success-0"
            )]: variant === "success",
            [ClassNames("bg-danger-2 text-danger-8", "dark:bg-danger-7 dark:text-danger-0")]:
              variant === "danger",
            [ClassNames("bg-info-1 text-info-8", "dark:bg-info-7 dark:text-info-0")]:
              variant === "info",
            [ClassNames("bg-accent-0 text-accent-6", "dark:bg-accent-7 dark:text-accent-0")]:
              variant === "accent",
          }
        )}
      >
        <Flex.Box className={ClassNames(Flex.Center, "w-2/12")}>
          <FontAwesome
            icon={
              variant
                ? variant === "danger"
                  ? solid("exclamation-circle")
                  : variant === "warning"
                  ? solid("exclamation-triangle")
                  : variant === "info"
                  ? solid("info-circle")
                  : variant === "success"
                  ? solid("check-circle")
                  : solid("info")
                : solid("info")
            }
            size="lg"
          />
        </Flex.Box>
        <div className={ClassNames("h-full ml-2 w-10/12")}>
          {title && <p className="font-bold text-justify my-1">{title}</p>}
          {content && <p className="my-1">{content}</p>}
          {link &&
            (link.as === "a" ? (
              <a href={link.url}>{link.see}</a>
            ) : (
              <Link to={link.url}>{link.see}</Link>
            ))}
          {children}
        </div>
      </Flex.Box>
    </div>
  );
}
