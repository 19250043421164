import { BrowserHistory } from "history";
import { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";

interface CustomRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: BrowserHistory;
}

export default function CustomRouter(props: CustomRouterProps) {
  const [state, setState] = useState({
    action: props.history.action,
    location: props.history.location,
  });

  useLayoutEffect(() => props.history.listen(setState), [props.history]);

  return (
    <Router
      basename={props.basename}
      location={state.location}
      navigationType={state.action}
      navigator={props.history}
    >
      {props.children}
    </Router>
  );
}
