import ErrorBoundary from "Utils/ErrorBoundary";
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from "formik";
import { useId, useMemo, useRef } from "react";
import { NodeOrJsxChild, RenderNodeOrJsxChild } from "Utils/NodeOrJsx";

export interface FormikFieldProps<T> {
  name: string;
  children: NodeOrJsxChild<FormikFieldJSXChildProps<T>>;
}

export interface FormikFieldJSXChildProps<T> {
  label: { htmlFor: string; id: string };
  field: FieldInputProps<string | number | string[] | undefined> & {
    id: string;
    "data-formik-error": boolean;
  };
  meta: FieldMetaProps<T>;
  helpers: FieldHelperProps<T>;
  isValid: boolean;
  isInvalid: boolean;
}

function FormikField<T>(props: FormikFieldProps<T>) {
  const { children, name } = props;
  const id = useId();

  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;

  const isValid = useMemo(() => touched && !error, [touched, error]);
  const isInvalid = useMemo(() => touched && !!error, [touched, error]);

  return (
    <ErrorBoundary key={id + "ErrBound"}>
      {RenderNodeOrJsxChild(
        {
          label: { htmlFor: id, id: id + "Lbl" },
          field: { ...field, id, "data-formik-error": isInvalid },
          meta,
          helpers,
          isValid,
          isInvalid,
        },
        children
      )}
    </ErrorBoundary>
  );
}

export default FormikField;
