import { AxiosError } from "axios";
import { ApiDate, Nullable, OmitProps, RecordKey } from "Utils/HelperTypes";
import { EnumValues } from "Utils";
import * as Yup from "yup";
import YupValidations from "Utils/YupValidations";

// ********************************************
// * Enums                                    *
// ********************************************

/**
 * @format int32
 */
export enum RemovalStatus {
  NotFound = 0,
  RequireFinalization = 1,
  Removed = 2,
  Error = 3,
}

/**
 * @format int32
 */
export enum RestoreStatus {
  NotFound = 0,
  Restored = 1,
  NotRestored = 2,
}

/**
 * @format int32
 */
export enum Gender {
  None = 0,
  Male = 1,
  Female = 2,
}

/**
 * @format int32
 */
export enum NotificationActionStatus {
  new = 0,
  read = 1,
}

/**
 * @format int32
 */
export enum ProductStatus {
  available = 0,
  temporarilyUnavailable = 1,
  deprecated = 2,
}

/**
 * @format int32
 */
export enum ProjectProductStatus {
  WaitingConfirm = 0,
  InProgress = 1,
  Delivered = 2,
}

/**
 * @format int32
 */
export enum SubscriptionStatus {
  Available = 0,
  TemporarilyUnavailable = 1,
  Deprecated = 2,
}

/**
 * @format int32
 */
export enum UserRemovalReason {
  None = 0,
  AdminRemoval = 1,
  UserRequestedDisable = 2,
}

/**
 * @format int32
 */
export enum UserStatus {
  Enabled = 0,
  Disabled = 1,
}

/**
 * @format int32
 */
export enum ModeratorRole {
  None = 0,
  Owner = 1,
  Manager = 2,
  Support = 3,
  Developer = 4,
  Designer = 5,
}

export type MediatRUnit = object;
export const MediatRUnit = {};

// ********************************************
// * Pagination                               *
// ********************************************
export interface Pagination {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export class PaginatedResult<T> {
  data: T[];
  pagination: Pagination;

  constructor(data: T[], pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }
}

export type PaginatedResultType<T> = typeof PaginatedResult<T>;

export type PaginationQuery = ApiQueryRecord & {
  PageNumber: number;
  PageSize: number;
};

export class PagingParams implements PaginationQuery {
  PageNumber;
  PageSize;

  constructor(pageNumber = 1, pageSize = 20) {
    this.PageNumber = pageNumber;
    this.PageSize = pageSize;
  }
  [x: string]: string | number | boolean | null | undefined;
}

// ********************************************
// * Address                                  *
// ********************************************
export interface Address {
  id?: Nullable<string>;
  country: string;
  provinceState: string;
  city?: Nullable<string>;
  street?: Nullable<string>;
  building?: Nullable<string>;
  postalZipCode?: Nullable<string>;
}

export const AddressValidator: Yup.SchemaOf<Address> = Yup.object({
  id: Yup.string().nullable().optional(),
  country: YupValidations.RequiredString("Country"),
  provinceState: YupValidations.RequiredString("Province/State"),
  city: YupValidations.OptionalName("City"),
  street: YupValidations.OptionalName("Street"),
  building: YupValidations.OptionalName("Building"),
  postalZipCode: YupValidations.OptionalName("Postal No.", 14),
});

export const AddressToString = (address?: Nullable<Address>) => {
  if (!address) return null;
  let val = "";

  val += address.postalZipCode ? address.postalZipCode + ", " : "";
  val += address.building ? address.building + ", " : "";
  val += address.street ? address.street + ", " : "";
  val += address.city ? address.city + ", " : "";
  val += address.provinceState + ", ";
  val += address.country;

  return val;
};

// ********************************************
// * Users                                    *
// ********************************************
export interface User {
  username?: Nullable<string>;
  email: Nullable<string>;
  displayName: Nullable<string>;
  photoUrl?: Nullable<string>;
  gender: Gender;
}

export interface LoggedUser extends User {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  phoneNumber?: Nullable<string>;
  token: string;
  address?: Nullable<Address>;

  /** @format date-time */
  createdAt?: ApiDate;
  isAdmin?: boolean;
  isClient?: boolean;

  // ***********
  // * Overrides
  username?: string;
  email: string;
  displayName: string;
}

export interface UserDetailsToAdmin extends OmitProps<LoggedUser, "token"> {
  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
  removalReason?: UserRemovalReason;
}

// ********************************************
// * Agent                                    *
// ********************************************

export type ApiQueryRecord = Record<string, string | number | boolean | null | undefined>;
export type ApiQuery = ApiQueryRecord | null | undefined;
export type ApiDataRecord = Record<
  string,
  object | string | number | boolean | null | undefined
>;
export type ApiBody = ApiDataRecord & Record<string, ApiDataRecord>;

export type ApiPromiseFunc = <T, R>(dataOrQuery?: T) => Promise<R>;
export type ApiPromise<R, T = unknown> =
  | ((dataOrQuery?: T) => Promise<R>)
  | ((dataOrQuery: T) => Promise<R>);
export type ApiVoidPromise<T = unknown> = ApiPromise<void, T>;

type CanHandleErrors = "faulted" | "invalid" | "context-invalid" | "timeout";
interface Error {
  error: AxiosError;
  reason: CanHandleErrors;
}
export type ApiProcessingStatus =
  | "success"
  | boolean
  | CanHandleErrors
  | "unauthorized"
  | "not-found"
  | ApiProcessingError
  | "5xx"
  | null;

export class ApiProcessingError implements Error {
  error: AxiosError;
  reason: CanHandleErrors;
  toString = () => this.reason;

  constructor(error: AxiosError, reason: CanHandleErrors) {
    this.error = error;
    this.reason = reason;
  }
}

export interface ServerError {
  statusCode: number;
  message: string;
  details: string;
}
