import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SubscriptionCategory } from "App/Api/SubscriptionAgent";
import { useStore } from "App/Stores/StoreContext";
import { FlexRow } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import LoaderSection from "Components/UI/LoaderSection";
import PopHelper from "Components/UI/Pop";
import Spinner from "Components/UI/Spinner";
import { observer } from "mobx-react-lite";
import ClassNames from "Utils/ClassNames";
import { For } from "Utils/For";
import { Nullable } from "Utils/HelperTypes";

const CategoryItem = observer((props: { item: Nullable<SubscriptionCategory> }) => {
  const subscriptionStore = useStore("SubscriptionStore");
  return (
    <button
      className={ClassNames(
        "flex flex-row flex-wrap",
        subscriptionStore.filterBy == props.item?.name ?? null
          ? "bg-primary-6 text-white hover:bg-primary-7 focus:bg-primary-7"
          : [
              {
                "text-gray-6/50": !props.item?.isRemoved,
                "text-danger-7/50": props.item?.isRemoved,
              },
              "bg-gray-2",
              "hover:bg-gray-3 hover:text-gray-7/50",
              "focus:bg-gray-4 focus:text-white focus-within:bg-gray-4 focus-within:text-white",
            ],
        "my-1 py-1 px-2 focus-visible:outline-dashed items-center justify-between rounded-tl-2xl rounded-br-2xl"
      )}
      onClick={() => {
        subscriptionStore.filterBy = props.item?.name ?? null;
      }}
    >
      <span className="w-fit">{props.item?.name ?? "All"}</span>
      <span>
        {props.item && (
          <PopHelper>
            {({ show, open, close }) => (
              <>
                <button
                  className="p-0.5 justify-self-end hover:text-danger-5 focus:text-danger-6 w-fit h-fit"
                  onClick={open}
                  title="delete"
                >
                  <FontAwesome icon={solid("trash")} size="sm" />
                </button>
                {show && (
                  <>
                    <PopHelper.Overlay onClick={close} />
                    <PopHelper.Dialog>
                      <PopHelper.Dialog.Heading>
                        <PopHelper.Dialog.Title>
                          Removing Category: {props.item?.name}
                        </PopHelper.Dialog.Title>
                        <PopHelper.Dialog.CloseButton onClick={close} />
                      </PopHelper.Dialog.Heading>
                      <PopHelper.Dialog.Details>
                        <PopHelper.Dialog.Message>
                          Are you sure you want to remove this category?
                        </PopHelper.Dialog.Message>
                      </PopHelper.Dialog.Details>
                      <PopHelper.Dialog.Footer>
                        <PopHelper.Dialog.Action>
                          {({ isProcessing, setIsProcessing }) => (
                            <>
                              <PopHelper.Dialog.Action.Button.Delete
                                onClick={() => {
                                  setIsProcessing(true);
                                  subscriptionStore
                                    .deleteCategory(props.item?.name as string)
                                    .then(close)
                                    .finally(() => setIsProcessing(false));
                                }}
                                working={isProcessing}
                              />
                              <PopHelper.Dialog.Action.Button.Cancel
                                onClick={close}
                                disabled={isProcessing}
                                hidden={isProcessing}
                              />
                            </>
                          )}
                        </PopHelper.Dialog.Action>
                      </PopHelper.Dialog.Footer>
                    </PopHelper.Dialog>
                  </>
                )}
              </>
            )}
          </PopHelper>
        )}
        {props.item?.isRemoved && (
          <button
            className="p-0.5 justify-self-end hover:text-success-3 ml-2 focus:text-success-4 w-fit h-fit"
            title="restore"
            // onClick={subscriptionStore.restoreCategory(props.item.name)}
          >
            <FontAwesome icon={solid("undo")} size="sm" />
          </button>
        )}
      </span>
      {props.item?.isRemoved && subscriptionStore.filterBy === props.item.name && (
        <span className="border-t border-white mt-1 pt-1 block w-100 justify-self-start text-start">
          This category is not removed. Remove its products then finalize it, or restore it.
        </span>
      )}
    </button>
  );
});

export default observer(function SubscriptionsCategories() {
  const subscriptionStore = useStore("SubscriptionStore");
  if (subscriptionStore.loadingCategories !== "success")
    return <LoaderSection title="Loading Categories..." />;

  return (
    <>
      <CategoryItem item={null} />
      <For each={subscriptionStore.categories}>
        {(category) => <CategoryItem key={category.data.name} item={category.data} />}
      </For>
    </>
  );
});
