import { ApiVoidPromise } from "../Models";
import * as Yup from "yup";
import {
  ApiPromise,
  ApiQuery,
  ModeratorRole,
  ProjectProductStatus,
  RemovalStatus,
  RestoreStatus,
  UserDetailsToAdmin,
} from "App/Models";
import { ApiDate, Nullable } from "Utils/HelperTypes";
import YupValidations from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";
import { Company } from "./CompanyAgent";
import { Product } from "./ProductAgent";
import { Subscription } from "./SubscriptionAgent";

// *******************************************************
// * Project                                             *
// *******************************************************

const basePath = "/project";

export interface Project {
  id?: Nullable<string>;
  name: Nullable<string>;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: Nullable<boolean>;
  companyId: Nullable<string>;
}

export interface ProjectDetails extends Project {
  id: string;
  companyId: string;
  company?: Nullable<Company>;
  products?: Nullable<ProjectProductDetails[]>;
  subscriptions?: Nullable<ProjectSubscriptionDetails[]>;
  moderators?: Nullable<ProjectModeratorDetails[]>;
}

// *****************************************
// * Add Project

export interface AddProjectData {
  companyId: string;
  name?: string;
}

export const AddProjectDataValidator: Yup.SchemaOf<AddProjectData> = Yup.object({
  companyId: YupValidations.RequiredString("Company Id"),
  name: YupValidations.RequiredName(),
});

export interface Api {
  AddProject: ApiPromise<ProjectDetails, AddProjectData>;
}

// *****************************************
// * Edit Project

export interface EditProjectData {
  data: Project;
}

export interface Api {
  EditProject: ApiPromise<ProjectDetails, EditProjectData>;
}

// *****************************************
// * List Projects & Load Details

export type ListProjectsQuery = ApiQuery;

export interface Api {
  ListProjects: ApiPromise<ProjectDetails[], ListProjectsQuery>;
  LoadProjectDetails: ApiPromise<ProjectDetails, string>;
}

// *****************************************
// * Remove & Restore Project

export interface RemoveProjectData {
  id: string;
}

export interface RestoreProjectData {
  id: string;
}

export interface Api {
  RemoveProject: ApiPromise<RemovalStatus, RemoveProjectData>;
  RestoreProject: ApiPromise<RestoreStatus, RestoreProjectData>;
}

// *******************************************************
// * Project Moderator                                   *
// *******************************************************

export interface ProjectModerator {
  id: string;
  projectId: string;
  moderatorId: string;
  role: ModeratorRole;
  details?: Nullable<string>;

  /** @format date-time */
  createdAt?: ApiDate;

  /** @format date-time */
  removedAt?: ApiDate;
  isRemoved?: boolean;
}

export interface ProjectModeratorDetails extends ProjectModerator {
  project?: Nullable<Project>;
  moderator?: Nullable<UserDetailsToAdmin>;
}

// *****************************************
// * Add Project Moderator

export interface AddProjectModeratorData {
  /** Project Id */
  id: string;
  userEmail: string;
  role: ModeratorRole;
  details?: Nullable<string>;
}

export const AddProjectModeratorDataValidator: Yup.SchemaOf<AddProjectModeratorData> =
  Yup.object({
    id: YupValidations.RequiredString("Project Id"),
    userEmail: YupValidations.RequiredEmail("User Email"),
    role: YupValidations.RequiredEnum("Role", ModeratorRole),
    details: YupValidations.OptionalDetails(),
  });

export interface Api {
  AddProjectModerator: ApiPromise<ProjectModerator, AddProjectModeratorData>;
}

// *****************************************
// * Remove & Restore Project Moderator

export interface RemoveProjectModeratorData {
  id: string;
}

export interface Api {
  RemoveProjectModerator: ApiPromise<RemovalStatus, RemoveProjectModeratorData>;
}

// *******************************************************
// * Project Product                                     *
// *******************************************************

export interface ProjectProduct {
  id: string;
  projectId: string;
  productId: string;
  details?: Nullable<string>;

  /** @format double */
  price?: number;
  priceCurrencyName?: Nullable<string>;

  /** @format double */
  cost?: number;
  costCurrencyName?: Nullable<string>;

  /** @format date-time */
  createdAt?: ApiDate;
  status?: ProjectProductStatus;
}

export interface ProjectProductDetails extends ProjectProduct {
  project?: Nullable<Project>;
  product?: Nullable<Product>;
}

// *****************************************
// * Add Project Product

export interface AddProjectProductData {
  projectId: string;
  productId: Nullable<string>;
  details?: Nullable<string>;

  /** @format double */
  price: number;
  priceCurrencyName: string;

  /** @format double */
  cost: number;
  costCurrencyName: string;
  status: ProjectProductStatus;
}

export const AddProjectProductDataValidator: Yup.SchemaOf<AddProjectProductData> = Yup.object(
  {
    projectId: YupValidations.RequiredString("Project Id"),
    productId: YupValidations.RequiredString("Product Id"),
    details: YupValidations.OptionalDetails(),
    price: YupValidations.Money("Price"),
    priceCurrencyName: YupValidations.Currency("Price Currency"),
    cost: YupValidations.Money("Cost"),
    costCurrencyName: YupValidations.Currency("Cost Currency"),
    status: YupValidations.RequiredEnum("Status", ProjectProductStatus),
  }
);

export interface Api {
  AddProjectProduct: ApiPromise<ProjectProductDetails, AddProjectProductData>;
}

// *****************************************
// * Edit Project Product

export interface EditProjectProductData {
  id: string;
  details?: Nullable<string>;

  /** @format double */
  price: number;
  priceCurrencyName: string;

  /** @format double */
  cost: number;
  costCurrencyName: string;
  status: ProjectProductStatus;
}

export const EditProjectProductDataValidator: Yup.SchemaOf<EditProjectProductData> =
  Yup.object({
    id: YupValidations.RequiredString("Project Product Id"),
    details: YupValidations.OptionalDetails(),
    price: YupValidations.Money("Price"),
    priceCurrencyName: YupValidations.Currency("Price Currency"),
    cost: YupValidations.Money("Cost"),
    costCurrencyName: YupValidations.Currency("Cost Currency"),
    status: YupValidations.RequiredEnum("Status", ProjectProductStatus),
  });

export interface Api {
  EditProjectProduct: ApiVoidPromise<EditProjectProductData>;
}

// *****************************************
// * Remove Project Product

export interface RemoveProjectProductData {
  id: string;
}

export interface Api {
  RemoveProjectProduct: ApiPromise<RemovalStatus, RemoveProjectProductData>;
}

// *******************************************************
// * Project Subscription                                *
// *******************************************************

export interface ProjectSubscription {
  id: string;
  projectId: string;
  subscriptionId: string;
  details?: Nullable<string>;

  /** @format date-time */
  startAt: ApiDate;

  /** @format date-time */
  endAt: ApiDate;

  autoRenew: boolean;

  /** @format double */
  price: number;
  priceCurrencyName: string;

  /** @format date-time */
  canceledAt?: ApiDate;
  isCanceled?: boolean;
  cancelationReason?: Nullable<string>;
  isActive: boolean;
  isDisabled: boolean;

  /** @format date-time */
  createdAt?: ApiDate;
}

export interface ProjectSubscriptionDetails extends ProjectSubscription {
  project?: Nullable<Project>;
  subscription?: Nullable<Subscription>;
}

// *****************************************
// * Add Project Subscription

export interface AddProjectSubscriptionData {
  projectId?: Nullable<string>;
  subscriptionId?: Nullable<string>;
  details?: Nullable<string>;

  /** @format date-time */
  startAt: ApiDate;

  /** @format date-time */
  endAt: ApiDate;
  autoRenew: boolean;

  /** @format double */
  price?: number;
  priceCurrencyName?: Nullable<string>;
}

export const AddProjectSubscriptionDataValidator: Yup.SchemaOf<AddProjectSubscriptionData> =
  Yup.object({
    projectId: YupValidations.RequiredString("Project Id"),
    subscriptionId: YupValidations.RequiredString("Subscription Id"),
    details: YupValidations.OptionalDetails(),
    startAt: YupValidations.RequiredDate("Start Date"),
    endAt: YupValidations.RequiredDate("End Date"),
    autoRenew: YupValidations.RequiredBoolean("Auto Renew"),
    price: YupValidations.Money("Price"),
    priceCurrencyName: YupValidations.Currency("Price Currency"),
  });

export interface Api {
  AddProjectSubscription: ApiPromise<ProjectSubscriptionDetails, AddProjectSubscriptionData>;
}

// *****************************************
// * Edit Project Subscription

export interface EditProjectSubscriptionData {
  id: string;
  details?: Nullable<string>;

  /** @format date-time */
  startAt: ApiDate;

  /** @format date-time */
  endAt: ApiDate;
  autoRenew: boolean;

  /** @format double */
  price: number;
  priceCurrencyName: string;
}

export const EditProjectSubscriptionDataValidator: Yup.SchemaOf<EditProjectSubscriptionData> =
  Yup.object({
    id: YupValidations.RequiredString("Id"),
    details: YupValidations.OptionalDetails(),
    startAt: YupValidations.RequiredDate("Start Date"),
    endAt: YupValidations.RequiredDate("End Date"),
    autoRenew: YupValidations.RequiredBoolean("Auto Renew"),
    price: YupValidations.Money("Price"),
    priceCurrencyName: YupValidations.Currency("Price Currency"),
  });

export interface Api {
  EditProjectSubscription: ApiVoidPromise<EditProjectSubscriptionData>;
}

// *****************************************
// * Remove & Cancel Project Subscription

export interface RemoveProjectSubscriptionData {
  id: string;
}

export interface CancelProjectSubscriptionData {
  id: string;
  reason: string;
  canceledAt: ApiDate;
}

export interface Api {
  RemoveProjectSubscription: ApiPromise<RemovalStatus, RemoveProjectSubscriptionData>;
  CancelProjectSubscription: ApiVoidPromise<CancelProjectSubscriptionData>;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  AddProject: async (data: AddProjectData) =>
    await AxiosRequests.post<ProjectDetails>(basePath, data),
  ListProjects: async (query?: ListProjectsQuery) =>
    await AxiosRequests.get<ProjectDetails[]>(basePath, query),
  EditProject: async (data: EditProjectData) =>
    await AxiosRequests.put<ProjectDetails>(basePath, data),
  RemoveProject: async (data: RemoveProjectData) =>
    await AxiosRequests.delete<RemovalStatus>(basePath, data),
  LoadProjectDetails: async (id: string) =>
    await AxiosRequests.get<ProjectDetails>(`${basePath}/${id}`),
  RestoreProject: async (data: RestoreProjectData) =>
    await AxiosRequests.put<RestoreStatus>(`${basePath}/restore`, data),

  // ** Products **
  AddProjectProduct: async (data: AddProjectProductData) =>
    await AxiosRequests.post<ProjectProductDetails>(`${basePath}/product`, data),
  EditProjectProduct: async (data: EditProjectProductData) =>
    await AxiosRequests.put(`${basePath}/product`, data),
  RemoveProjectProduct: async (data: RemoveProjectModeratorData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/product`, data),

  // ** Subscription **
  AddProjectSubscription: async (data: AddProjectSubscriptionData) =>
    await AxiosRequests.post<ProjectSubscriptionDetails>(`${basePath}/subscription`, data),
  EditProjectSubscription: async (data: EditProjectSubscriptionData) =>
    await AxiosRequests.put(`${basePath}/subscription`, data),
  RemoveProjectSubscription: async (data: RemoveProjectSubscriptionData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/subscription`, data),
  CancelProjectSubscription: async (data: CancelProjectSubscriptionData) =>
    await AxiosRequests.put(`${basePath}/subscription/cancel`, data),

  // ** Moderators **
  AddProjectModerator: async (data: AddProjectModeratorData) =>
    await AxiosRequests.post<ProjectModeratorDetails>(`${basePath}/moderator`, data),
  RemoveProjectModerator: async (data: RemoveProjectModeratorData) =>
    await AxiosRequests.delete<RemovalStatus>(`${basePath}/moderator`, data),
};
