import { IChildren } from "Utils/ReactElementsTypes";
import { Form, useFormikContext } from "formik";
import { useId } from "react";
import FormikFieldSet from "./FormikFieldSet";

function FormikForm(props: IChildren) {
  const id = useId();
  const formik = useFormikContext();
  return (
    <Form
      {...props}
      id={id}
      key={id}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      onBlur={formik.handleBlur}
      data-form-valid={formik.isValid}
      data-form-submitting={formik.isSubmitting}
      data-form-dirty={formik.dirty}
    />
  );
}

FormikForm.FieldSet = FormikFieldSet;

export default FormikForm;
