import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import ClassNames from "../../../Utils/ClassNames";
import Flex from "../../Grid/Flex";
import FontAwesome from "../../UI/FontAwesome";

export interface MenuNavLinkProps {
  open?: boolean;
  icon: IconProp;
  to: string;
  isAnchor?: boolean;
  children?: React.ReactNode | JSX.Element;
  className?: string;
  onClick?: () => void;
}

export default function MenuNavLink(props: MenuNavLinkProps) {
  if (props.isAnchor)
    return (
      <a
        className={ClassNames("flex", Flex.Row, Flex.Center, props.className)}
        href={props.to}
      >
        <div
          className={ClassNames("p-3 flex", Flex.Center, {
            "w-full": !props.open,
            "w-3/12": props.open,
          })}
        >
          <FontAwesome icon={props.icon} />
        </div>
        <div
          className={ClassNames({
            "hidden w-0": !props.open,
            "block w-9/12": props.open,
          })}
        >
          {props.children}
        </div>
      </a>
    );

  return (
    <NavLink
      className={(navData) =>
        ClassNames(
          "flex",
          Flex.Row,
          Flex.Center,
          "my-0 h-18 w-full md:w-[90%] md:self-end py-3 hover:bg-gradient-to-br hover:from-secondary-1 md:rounded-l-3xl",
          {
            "bg-body-light dark:bg-body-dark text-body-dark dark:text-body-light ":
              navData.isActive,
          },
          props.className
        )
      }
      to={props.to}
      onClick={(e) => {
        if (props.onClick) {
          e.preventDefault();
          props.onClick();
        }
      }}
      onKeyDown={(e) => {
        if ((e.key === "Enter" || e.key === "Space") && props.onClick) {
          e.preventDefault();
          props.onClick();
        }
      }}
    >
      <div
        className={ClassNames("py-2 flex", Flex.Center, {
          "w-full pr-3 pl-2": !props.open,
          "w-3/12 px-1": props.open,
        })}
      >
        <FontAwesome icon={props.icon} size="lg" />
      </div>
      <div
        className={ClassNames("m-0 py-2", {
          "hidden w-0": !props.open,
          "block w-9/12": props.open,
        })}
      >
        {props.children}
      </div>
    </NavLink>
  );
}
