import Leaf from "Components/Project/Leaf";
import PopHelper from "Components/UI/Pop";
import FontAwesome from "Components/UI/FontAwesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Formik } from "formik";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import * as Yup from "yup";
import YupUtils from "Utils/YupValidations";
import FormikForm from "Components/Forms/FormikForm";
import { useStore } from "App/Stores/StoreContext";
import FormikTextInput from "Components/Forms/FormikTextInput";

const newProductCategoryValidationSchema = Yup.object({
  name: YupUtils.RequiredString("Name"),
});

export function NewProductCategoryModal() {
  const productStore = useStore("ProductStore");
  return (
    <PopHelper>
      {({ id, show, open, close }) => (
        <>
          <Leaf.Legend.AddButton
            onClick={() => {
              productStore.addingCategory = false;
              open();
            }}
            className={Leaf.className.Legend.PrimaryButton}
          />
          <Leaf.Legend.Title>Categories</Leaf.Legend.Title>
          {show && (
            <Formik
              initialValues={{ name: "" }}
              validationSchema={newProductCategoryValidationSchema}
              onSubmit={(vals, heleprs) => {
                productStore.addCategory(vals, heleprs).then(close);
              }}
            >
              {({ isSubmitting, resetForm }) => (
                <>
                  <PopHelper.Overlay
                    onClick={() => {
                      resetForm();
                      close();
                    }}
                  />
                  <FormikForm>
                    <PopHelper.Dialog
                      id={id}
                      className="bg-white text-secondary-6 flex flex-col justify-center items-center"
                      open={show}
                    >
                      <PopHelper.Dialog.Heading className="px-2 py-3">
                        <PopHelper.Dialog.Title>New Product Category</PopHelper.Dialog.Title>
                        <PopHelper.Dialog.CloseButton
                          autoFocus
                          onClick={() => {
                            resetForm();
                            close();
                          }}
                        >
                          <FontAwesome icon={solid("close")} size="lg" />
                        </PopHelper.Dialog.CloseButton>
                      </PopHelper.Dialog.Heading>

                      <PopHelper.Dialog.Details>
                        {/* <FormikForm.FieldSet name="name">
                        {({ field }) => (
                          <>
                            <FormikForm.FieldSet.Label
                              className="text-base"
                              htmlFor={field.id}
                            >
                              Name
                            </FormikForm.FieldSet.Label>
                            <FormikForm.FieldSet.TextInput
                              {...field}
                              type="text"
                              className="w-full rounded-full shadow-ui border-gray-4"
                            />
                          </>
                        )}
                      </FormikForm.FieldSet> */}
                        <FormikTextInput
                          name="name"
                          border={{ width: "1", rounded: "full" }}
                          type="text"
                          placeholder="Name"
                        />
                      </PopHelper.Dialog.Details>
                      <PopHelper.Dialog.Footer>
                        <FormikSubmitField>
                          {({ field }) => (
                            <PopHelper.Dialog.Action.Button
                              {...field}
                              disabled={isSubmitting}
                            >
                              Add
                            </PopHelper.Dialog.Action.Button>
                          )}
                        </FormikSubmitField>
                        <PopHelper.Dialog.Action.Button
                          onClick={() => {
                            resetForm();
                            close();
                          }}
                        >
                          Cancel
                        </PopHelper.Dialog.Action.Button>
                      </PopHelper.Dialog.Footer>
                    </PopHelper.Dialog>
                  </FormikForm>
                </>
              )}
            </Formik>
          )}
        </>
      )}
    </PopHelper>
  );
}
