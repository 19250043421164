import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FontAwesome from "../Components/UI/FontAwesome";

export default function LoaderScreen() {
  return (
    <div className="w-screen h-screen bg-secondary-5 dark:bg-accent-9 absolute top-0 left-0 flex align-middle content-center justify-center items-center">
      <FontAwesome
        className="text-primary-0 animate-spin"
        icon={solid("spinner")}
        size="7x"
      />
    </div>
  );
}
