import {
  ApiProcessingError,
  ApiProcessingStatus,
  ModeratorRole,
  ProjectProductStatus,
  RemovalStatus,
} from "App/Models";
import { EmptyFaxErrors, FaxErrorHandler } from "Utils/FormikErrorUtils";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { MaybeNull, Nullable } from "Utils/HelperTypes";
import { RootStore } from "./Stores";
import { history } from "index";
import {
  AddProjectData,
  AddProjectModeratorData,
  AddProjectProductData,
  Api,
  Project,
  ProjectDetails,
  AddProjectSubscriptionData,
  EditProjectProductData,
  EditProjectSubscriptionData,
  CancelProjectSubscriptionData,
} from "App/Api/ProjectAgent";
import { removeFirstWhere } from "Utils/ArrayM";

export class ProjectStore {
  // =========================
  // Projects
  projectsMap = new Map<
    MaybeNull<string>,
    { data: ProjectDetails; detailsLoaded?: boolean }
  >();
  getItem = (id: string) => this.projectsMap.get(id);
  getNewOrExistingItem = (id?: Nullable<string>): Project =>
    id && id.length ? this.projectsMap.get(id)?.data ?? this.newItem() : this.newItem();

  private newItem = () => ({
    name: null,
    companyId: null,
  });

  get projects() {
    return _.sortBy(
      Array.from(this.projectsMap.values()).map((x) => x.data),
      (x) => x.name
    );
  }

  setItem = (value: ProjectDetails, detailsLoaded = false) => {
    if (value.id) {
      const current = this.projectsMap.get(value.id);
      if (!current) {
        this.projectsMap.set(value.id, { data: value, detailsLoaded });
        this.root.CompanyStore.companiesMap.get(value.companyId)?.data.projects?.push(value);
        return;
      }
      if (current.detailsLoaded) return;
      current.data = {
        ...current.data,
        ..._.omitBy(value, _.isNull),
      };
      current.detailsLoaded = detailsLoaded;
      this.root.CompanyStore.companiesMap
        .get(value.companyId)
        ?.data.projects?.push(current.data);
      // if (detailsLoaded) {
      //   value.projects?.forEach((x) => this.root.ProjectStore.setProject(x));
      // }
      return;
    }
  };

  loadingItems: ApiProcessingStatus = null;
  loadItems = async () => {
    if (this.loadingItems) return;
    this.loadingItems = true;
    try {
      const res = await Api.ListProjects({});
      res.forEach((x) => this.setItem(x));
      runInAction(() => (this.loadingItems = "success"));
    } catch (er) {
      runInAction(() => (this.loadingItems = "faulted"));
      // const err = er as AxiosError;
      throw er;
    }
  };

  loadingItemDetails: ApiProcessingStatus = null;
  loadItemDetails = async (id: string) => {
    if (this.loadingItemDetails === true) return;
    this.loadingItemDetails = true;

    try {
      const details = await Api.LoadProjectDetails(id);
      this.setItem(details, true);
      runInAction(() => (this.loadingItemDetails = "success"));
    } catch (error) {
      console.log(error);
      runInAction(() => (this.loadingItemDetails = "faulted"));
    }
  };

  deletingItem: ApiProcessingStatus = null;
  deleteItem = async (id: string) => {
    if (this.deletingItem === true) return;
    this.deletingItem = true;

    const exists = this.projectsMap.get(id);

    if (!exists) {
      this.deletingItem = "success";
      return RemovalStatus.NotFound;
    }

    try {
      const res = await Api.RemoveProject({ id });
      runInAction(() => {
        if (res === RemovalStatus.RequireFinalization) {
          exists.data.removedAt = new Date();
          exists.data.isRemoved = true;
        } else {
          this.projectsMap.delete(id);
          const company = this.root.CompanyStore.companiesMap.get(exists.data.companyId);
          if (company) {
            removeFirstWhere(company.data.projects, (v) => v.id === exists.data.id);
          }
          history.push(
            company?.data.id ? `/app/companies/${company?.data.id}` : "/app/projects"
          );
        }
        this.deletingItem = "success";
      });
      return res;
    } catch (error) {
      console.error(error);
      runInAction(() => (this.deletingItem = "faulted"));
      throw error;
    }
  };

  addingNewItem: ApiProcessingStatus = null;
  addNewItem = async <T>(data: AddProjectData, helpers: FormikHelpers<T>) => {
    if (this.addingNewItem === true) return;
    this.addingNewItem = true;
    EmptyFaxErrors(helpers);
    try {
      const res = await Api.AddProject(data);
      this.setItem(res, true);
      runInAction(() => {
        this.addingNewItem = "success";
      });
      if (window.location.pathname.includes("new-project")) {
        history.push(window.location.pathname.replace("/new-project", ""));
      } else {
        history.push(window.location.pathname.replace("/new", `/${res.id}`));
      }
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => {
        this.addingNewItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.log(error);
      throw error;
    }
  };

  editingItem: ApiProcessingStatus = null;
  editItem = async <T>(data: Project, faxHelper: FormikHelpers<T>) => {
    if (this.editingItem === true) return;
    this.editingItem = true;

    const item = this.projectsMap.get(data.id)?.data;

    if (!item) {
      this.editingItem = "faulted";
      return;
    }

    try {
      const res = await Api.EditProject({ data });
      this.setItem(res, false);
      runInAction(() => {
        this.editingItem = "success";
      });
      history.push(window.location.pathname.replace("/edit", ""));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, faxHelper);
      runInAction(() => {
        this.editingItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.error(error);
      throw error;
    }
  };

  newItemModerator = (projectId: string): AddProjectModeratorData => ({
    id: projectId,
    userEmail: "",
    role: ModeratorRole.None,
    details: null,
  });

  addingModerator: ApiProcessingStatus = null;
  addModerator = async <T>(data: AddProjectModeratorData, helpers: FormikHelpers<T>) => {
    if (this.addingModerator === true) return;
    this.addingModerator = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.projectsMap.get(data.id)?.data;
    if (!item) {
      this.addingModerator = "faulted";
      return;
    }

    // Check Child Exists
    const moderator =
      item.moderators?.filter((x) => x.moderator?.email === data.userEmail) ?? [];
    if (moderator.length) {
      this.addingModerator = "success";
      return;
    }

    try {
      const res = await Api.AddProjectModerator(data);
      runInAction(() => {
        item.moderators ??= [];
        item.moderators.push(res);
        this.addingModerator = "success";
        history.push("..");
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.addingModerator = new ApiProcessingError(err as AxiosError, "faulted");
      });
      throw err;
    }
  };

  removingModerator: ApiProcessingStatus = null;
  removeModerator = async (id: string, projId: string) => {
    if (this.removingModerator === true) return;
    this.removingModerator = true;

    // Check Item Exists
    const item = this.projectsMap.get(projId)?.data;
    if (!item) {
      this.removingModerator = "faulted";
      return;
    }

    // Check Child Exists
    const moderator = item.moderators?.filter((x) => x.id === id) ?? [];
    if (!moderator.length) {
      this.removingModerator = "success";
      return;
    }

    try {
      await Api.RemoveProjectModerator({ id });
      runInAction(() => {
        item.moderators = item.moderators?.filter((x) => x != moderator[0]);
        this.removingModerator = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.removingModerator = "faulted";
      });
      throw err;
    }
  };

  newItemProduct = (projectId: string): AddProjectProductData => ({
    projectId,
    cost: 0,
    costCurrencyName: "USD",
    price: 0,
    priceCurrencyName: "USD",
    productId: null,
    status: ProjectProductStatus.InProgress,
    details: null,
  });

  addingProduct: ApiProcessingStatus = null;
  addProduct = async <T>(data: AddProjectProductData, helpers: FormikHelpers<T>) => {
    if (this.addingProduct === true) return;
    this.addingProduct = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.projectsMap.get(data.projectId)?.data;
    if (!item) {
      this.addingProduct = "faulted";
      return;
    }

    try {
      const res = await Api.AddProjectProduct(data);
      runInAction(() => {
        item.products ??= [];
        item.products.push(res);
        this.addingProduct = "success";
        history.push(window.location.pathname.replace("/new-product", ""));
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.addingProduct = new ApiProcessingError(err as AxiosError, "faulted");
      });
      throw err;
    }
  };

  editingProduct: ApiProcessingStatus = null;
  editProduct = async <T>(
    data: EditProjectProductData,
    projId: string,
    helpers: FormikHelpers<T>
  ) => {
    if (this.editingProduct === true) return;
    this.editingProduct = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.projectsMap.get(projId)?.data;
    if (!item) {
      this.editingProduct = "faulted";
      return;
    }

    // Check Child Exists
    const product = item.products?.filter((x) => x.id === data.id) ?? [];
    if (!product.length) {
      this.editingProduct = "faulted";
      return;
    }

    try {
      await Api.EditProjectProduct(data);
      runInAction(() => {
        product[0].details = data.details;
        product[0].cost = data.cost;
        product[0].costCurrencyName = data.costCurrencyName;
        product[0].price = data.price;
        product[0].priceCurrencyName = data.priceCurrencyName;
        product[0].status = data.status;
        this.editingProduct = "success";
        history.push(window.location.pathname.replace("/edit", ""));
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.editingProduct = "faulted";
      });
      throw err;
    }
  };

  removingProduct: ApiProcessingStatus = null;
  removeProduct = async (id: string, projId: string) => {
    if (this.removingProduct === true) return;
    this.removingProduct = true;

    // Check Item Exists
    const item = this.projectsMap.get(projId)?.data;
    if (!item) {
      this.removingProduct = "faulted";
      return;
    }

    // Check Child Exists
    const product = item.products?.filter((x) => x.id === id) ?? [];
    if (!product.length) {
      this.removingProduct = "success";
      return;
    }

    try {
      await Api.RemoveProjectProduct({ id });
      runInAction(() => {
        item.products = item.products?.filter((x) => x != product[0]);
        this.removingProduct = "success";
        history.push(window.location.pathname.replace(/\/prd.*/, ""));
      });
    } catch (err) {
      runInAction(() => {
        this.removingProduct = "faulted";
      });
      throw err;
    }
  };

  newItemSubscription = (projectId: string): AddProjectSubscriptionData => ({
    projectId,
    autoRenew: false,
    startAt: new Date(),
    endAt: new Date(),
    price: 0,
    priceCurrencyName: "USD",
    subscriptionId: null,
  });

  addingSubscription: ApiProcessingStatus = null;
  addSubscription = async <T>(
    data: AddProjectSubscriptionData,
    helpers: FormikHelpers<T>
  ) => {
    if (this.addingSubscription === true) return;
    this.addingSubscription = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.projectsMap.get(data.projectId)?.data;
    if (!item) {
      this.addingSubscription = "faulted";
      return;
    }

    try {
      const res = await Api.AddProjectSubscription(data);
      runInAction(() => {
        item.subscriptions ??= [];
        item.subscriptions.push(res);
        this.addingSubscription = "success";
        history.push(window.location.pathname.replace("/new-subscription", ""));
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.addingSubscription = new ApiProcessingError(err as AxiosError, "faulted");
      });
      throw err;
    }
  };

  editingSubscription: ApiProcessingStatus = null;
  editSubscription = async <T>(
    data: EditProjectSubscriptionData,
    projId: string,
    helpers: FormikHelpers<T>
  ) => {
    if (this.editingSubscription === true) return;
    this.editingSubscription = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.projectsMap.get(projId)?.data;
    if (!item) {
      this.editingSubscription = "faulted";
      return;
    }

    // Check Child Exists
    const subscription = item.subscriptions?.filter((x) => x.id === data.id) ?? [];
    if (!subscription.length) {
      this.editingSubscription = "faulted";
      return;
    }

    try {
      await Api.EditProjectSubscription(data);
      runInAction(() => {
        subscription[0].details = data.details;
        subscription[0].price = data.price;
        subscription[0].priceCurrencyName = data.priceCurrencyName;
        subscription[0].startAt = data.startAt;
        subscription[0].endAt = data.endAt;
        subscription[0].autoRenew = data.autoRenew;
        this.editingSubscription = "success";
        history.push(window.location.pathname.replace("/edit", ""));
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.editingSubscription = "faulted";
      });
      throw err;
    }
  };

  removingSubscription: ApiProcessingStatus = null;
  removeSubscription = async (id: string, projId: string) => {
    if (this.removingSubscription === true) return;
    this.removingSubscription = true;

    // Check Item Exists
    const item = this.projectsMap.get(projId)?.data;
    if (!item) {
      this.removingSubscription = "faulted";
      return;
    }

    // Check Child Exists
    const subscription = item.subscriptions?.filter((x) => x.id === id) ?? [];
    if (!subscription.length) {
      this.removingSubscription = "success";
      return;
    }

    try {
      await Api.RemoveProjectSubscription({ id });
      runInAction(() => {
        item.subscriptions = item.subscriptions?.filter((x) => x != subscription[0]);
        this.removingSubscription = "success";
        history.push(window.location.pathname.replace(/\/sub.*/, ""));
      });
    } catch (err) {
      runInAction(() => {
        this.removingSubscription = "faulted";
      });
      throw err;
    }
  };

  cancelingSubscription: ApiProcessingStatus = null;
  cancelSubscription = async (data: CancelProjectSubscriptionData) => {
    if (this.cancelingSubscription === true) return;
    this.cancelingSubscription = true;

    // Check Item Exists
    const item = this.projectsMap.get(data.id)?.data;
    if (!item) {
      this.cancelingSubscription = "faulted";
      return;
    }

    // Check Child Exists
    const subscription = item.subscriptions?.filter((x) => x.id === data.id) ?? [];
    if (!subscription.length) {
      this.cancelingSubscription = "faulted";
      return;
    }

    if (subscription[0].isCanceled) {
      this.cancelingSubscription = "success";
      return;
    }

    try {
      await Api.CancelProjectSubscription(data);
      runInAction(() => {
        subscription[0].isCanceled = true;
        subscription[0].cancelationReason = data.reason;
        this.cancelingSubscription = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.cancelingSubscription = "faulted";
      });
      throw err;
    }
  };

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }
}
