import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CompanyDetails } from "App/Api/CompanyAgent";
import { useEffect } from "react";
import { FormatDate } from "Utils/DateUtils";

function CompaniesList() {
  const { companies, loadingItems, loadItems } = useStore("CompanyStore");
  useEffect(() => {
    if (!loadingItems) loadItems();
  });
  return (
    <>
      <Summary>
        {loadingItems === true && <LoaderSection title="Loading Companies..." />}
        {loadingItems === "success" && <For each={companies}>{ItemSummary}</For>}
        {loadingItems === "faulted" && (
          <button className="bg-primary-6 text-white">
            An Error Occurred! Click Here To Refresh.
          </button>
        )}
      </Summary>
    </>
  );
}

function ItemSummary(item: CompanyDetails) {
  return (
    <Summary.Card to={item.id} key={item.id}>
      <Summary.Card.Title>{item.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("info-circle")}
        title="Support Email:"
        info={item.supportEmail ?? "-"}
      />
      <Summary.Card.Detail
        icon={solid("info-circle")}
        title="Support Phone:"
        info={item.supportPhone ?? "-"}
      />
      <Summary.Card.FooterImage
        fallbackIcon={solid("building")}
        src={item.photoUrl}
        // src="http://placekitten.com/300/500"
        // src="http://placekitten.com/300/300"
        // src="http://via.placeholder.com/640x360"
      />
    </Summary.Card>
  );
}

export default observer(CompaniesList);
