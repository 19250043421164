import { ServerError } from "App/Models";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import AppMessages from "../../Utils/AppMessages";
import { RootStore } from "./Stores";

const k = "SessionAuthKey";

export class CommonStore {
  // =================================
  // App Settings
  error: ServerError | null = null;
  appLoaded = false;
  forwardedRef: string | null = null;
  isOnline: boolean;

  get AppIsOnline() {
    return this.isOnline;
  }

  RefreshOnlineStatus = () => {
    this.isOnline = navigator.onLine;
    if (this.isOnline) toast.info(AppMessages.OnlineMessage);
    else toast.warning(AppMessages.OfflineMessage);
  };

  setServerError = (error: ServerError | null) => {
    this.error = error;
  };

  setAppLoadingStatus = (isLoading: boolean) => {
    this.appLoaded = !isLoading;
  };

  StoreCurrentPath = () => {
    this.forwardedRef = window.location.pathname;
  };

  // =================================
  // Authorization
  get token(): string | null {
    return window.localStorage.getItem(k);
  }

  setToken = (value: string | null) => {
    if (value) {
      window.localStorage.setItem(k, value);
    } else {
      window.localStorage.removeItem(k);
    }
  };

  // =================================
  // Currency
  SupportedCurrencies: string[] = ["USD"];
  get CurrentCurrency() {
    return window.localStorage.getItem("cur") ?? "USD";
  }

  setCurrentCurrency = (value: string | null) => {
    window.localStorage.setItem("cur", value ?? "USD");
  };

  readonly rootStore: RootStore;
  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.rootStore = root;
    this.isOnline = navigator.onLine;
  }
}
