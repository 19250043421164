import * as Yup from "yup";
import {
  ApiPromise,
  Gender,
  UserDetailsToAdmin,
  ApiQueryRecord,
  User,
  Address,
  ApiVoidPromise,
} from "App/Models";
import { Nullable } from "Utils/HelperTypes";
import YupValidations from "Utils/YupValidations";
import AxiosRequests from "Utils/Axios";

// *******************************************************
// * User Management                                     *
// *******************************************************

const basePath = "/UserManagement";

// *****************************************
// * Create New User

export interface CreateNewUserData {
  displayName: string;
  email: string;
  gender: Gender;
  phoneNumber?: Nullable<string>;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
}

export const CreateNewUserDataValidator: Yup.SchemaOf<CreateNewUserData> = Yup.object({
  displayName: YupValidations.RequiredName("Display Name"),
  email: YupValidations.RequiredEmail(),
  gender: YupValidations.RequiredEnum("Gender", Gender),
  phoneNumber: YupValidations.OptionalPhone(),
  firstName: YupValidations.OptionalName("First Name"),
  lastName: YupValidations.OptionalName("Last Name"),
});

export interface Api {
  CreateNewUser: ApiPromise<UserDetailsToAdmin, CreateNewUserData>;
}

// *****************************************
// * Update User

export interface UpdateUserData {
  displayName?: Nullable<string>;
  email?: Nullable<string>;
  phoneNumber?: Nullable<string>;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  gender?: Gender;
}

export const UpdateUserDataValidator: Yup.SchemaOf<UpdateUserData> = Yup.object({
  displayName: YupValidations.RequiredName("Display Name"),
  email: YupValidations.RequiredEmail(),
  phoneNumber: YupValidations.OptionalPhone(),
  firstName: YupValidations.OptionalName("First Name"),
  lastName: YupValidations.OptionalName("Last Name"),
  gender: YupValidations.RequiredEnum("Gender", Gender),
});

// *****************************************
// * Manage Address

export interface AddEditUserAddressData {
  email: string;
  address?: Nullable<Address>;
}

export interface RemoveUserAddressData {
  email: string;
}

export interface Api {
  AddEditUserAddress: ApiVoidPromise<AddEditUserAddressData>;
  RemoveUserAddress: ApiVoidPromise<RemoveUserAddressData>;
}

export interface Api {
  UpdateUser: ApiPromise<UserDetailsToAdmin, UpdateUserData>;
}

// *****************************************
// * List Users

export interface ListUsersQuery extends ApiQueryRecord {
  adminUsers?: Nullable<boolean>;
}

export interface Api {
  ListUsers: ApiPromise<UserDetailsToAdmin[], ListUsersQuery>;
}

// *****************************************
// * Register New Admin

export interface RegisterNewAdminData {
  displayName: string;
  email: string;
  phoneNumber?: Nullable<string>;
  password: string;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  gender: Gender;
}

export const RegisterNewAdminDataValidator: Yup.SchemaOf<RegisterNewAdminData> = Yup.object({
  displayName: YupValidations.RequiredName("Display Name"),
  email: YupValidations.RequiredEmail(),
  phoneNumber: YupValidations.OptionalPhone(),
  password: YupValidations.ComplexPassword(),
  firstName: YupValidations.OptionalName("First Name"),
  lastName: YupValidations.OptionalName("Last Name"),
  gender: YupValidations.RequiredEnum("Gender", Gender),
});

export interface Api {
  RegisterNewAdmin: ApiPromise<UserDetailsToAdmin, RegisterNewAdminData>;
}

// *****************************************
// * Search By Email

export interface SearchByEmailQuery extends ApiQueryRecord {
  email: string;
}

export interface Api {
  SearchByEmail: ApiPromise<User[], SearchByEmailQuery>;
}

// *****************************************
// * Agent
// *****************************************

export const Api: Api = {
  CreateNewUser: async (data: CreateNewUserData) =>
    await AxiosRequests.post<UserDetailsToAdmin>(basePath, data),
  UpdateUser: async (data: UpdateUserData) =>
    await AxiosRequests.put<UserDetailsToAdmin>(basePath, data),
  AddEditUserAddress: async (data: AddEditUserAddressData) =>
    await AxiosRequests.put(`${basePath}/address`, data),
  RemoveUserAddress: async () => await AxiosRequests.put(`${basePath}/address`),
  ListUsers: async (query: ListUsersQuery) =>
    await AxiosRequests.get<UserDetailsToAdmin[]>(basePath, query),
  RegisterNewAdmin: async (data: RegisterNewAdminData) =>
    await AxiosRequests.post<UserDetailsToAdmin>(`${basePath}/admin`, data),
  SearchByEmail: async (query: SearchByEmailQuery) =>
    await AxiosRequests.get<User[]>(basePath, query),
};
