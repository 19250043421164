import TextInput, { TextInputProps } from "../UI/TextInput";
import { useField } from "formik";
import ErrorBoundary from "Utils/ErrorBoundary";
import Label from "Components/UI/Label";
import ClassNames from "Utils/ClassNames";

export interface FormikTextInputProps extends Omit<TextInputProps, "status"> {
  name: string;
  showInvalid?: boolean;
  showValid?: boolean;
  fieldsetClassName?: string;
  // label?: { val: string; className: string; float: boolean };
  children?: React.ReactNode | JSX.Element;
}

export default function FormikTextInput(props: FormikTextInputProps) {
  const {
    name,
    title,
    showInvalid,
    showValid,
    variant,
    onChange,
    onBlur,
    border,
    onKeyDown,
    onSubmit,
    children,
    fieldsetClassName,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);
  return (
    <ErrorBoundary>
      <fieldset
        className={ClassNames("h-14 mb-4", fieldsetClassName, {
          "min-h-[30px]": !title, // default
          "min-h-[80px]": title,
        })}
      >
        {title && (
          <Label
            variant={
              meta.touched
                ? showInvalid && meta.error
                  ? "danger"
                  : showValid && !meta.error
                  ? "success"
                  : variant
                : variant
            }
            className="pl-2"
          >
            {title}
          </Label>
        )}
        <TextInput
          {...rest}
          {...field}
          border={{
            rounded: border.rounded,
            width: border.width,
            color: meta.touched
              ? showInvalid && !!meta.error
                ? "danger"
                : showValid && !meta.error
                ? "success"
                : variant
              : variant,
          }}
          id={props.name + "Field"}
          key={props.name}
          variant={
            meta.touched
              ? meta.error
                ? "danger"
                : showValid && !meta.error
                ? "success"
                : variant
              : variant
          }
          data-formik-error={!!meta.error}
          onBlur={(e) => {
            field.onBlur(e);
            if (onBlur) onBlur(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (meta.error) {
                e.preventDefault();
                helpers.setTouched(true);
                return false;
              }
            }
            if (onKeyDown) onKeyDown(e);
          }}
          onSubmit={(e) => {
            if (meta.error) {
              e.preventDefault();
              helpers.setTouched(true);
              return false;
            }
            if (onSubmit) onSubmit(e);
          }}
          icon={props.icon}
        />
        <span
          className={ClassNames("pl-3 text-danger-7 dark:text-danger-5", {
            hidden: !meta.touched || !meta.error,
          })}
        >
          {meta.error}
        </span>
        {children}
      </fieldset>
    </ErrorBoundary>
  );
}
