import { FaxErrorHandler } from "../../Utils/FormikErrorUtils";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { EmptyFaxErrors } from "../../Utils/FormikErrorUtils";
import { Nullable } from "../../Utils/HelperTypes";
import { RootStore } from "./Stores";
import { ApiProcessingStatus, RemovalStatus, RestoreStatus } from "App/Models";
import { history } from "index";
import {
  AddSubscriptionCategoryData,
  AddSubscriptionData,
  Api,
  Subscription,
  SubscriptionCategory,
} from "App/Api/SubscriptionAgent";

export class SubscriptionStore {
  itemsCategoriesMap = new Map<
    string,
    { data: SubscriptionCategory; detailsLoaded: boolean }
  >();

  get categories() {
    if (this.itemsCategoriesMap.size)
      return _.sortBy(Array.from(this.itemsCategoriesMap.values()), (x) => x);
    return [];
  }

  filterBy: Nullable<string> = null;

  private setItemCategory = (value: SubscriptionCategory, detailsLoaded = true) => {
    this.itemsCategoriesMap.set(value.name, { data: value, detailsLoaded });
  };

  loadingCategories: ApiProcessingStatus = null;
  loadCategories = async () => {
    if (this.loadingCategories === true) return;
    this.loadingCategories = true;

    try {
      const res = await Api.ListSubscriptionCategories({});
      res.forEach((x) => this.setItemCategory(x));
      runInAction(() => (this.loadingCategories = "success"));
    } catch (er) {
      runInAction(() => (this.loadingCategories = "faulted"));
      throw er;
    }
  };

  addingCategory: ApiProcessingStatus = null;
  addCategory = async <T>(value: AddSubscriptionCategoryData, helpers: FormikHelpers<T>) => {
    if (this.addingCategory === true) return;
    this.addingCategory = true;

    const existsLocally = this.itemsCategoriesMap.get(value.name);
    if (existsLocally) {
      this.addingCategory = "success";
      return;
    }

    EmptyFaxErrors(helpers);
    helpers.setSubmitting(true);

    try {
      const res = await Api.AddSubscriptionCategory(value);
      this.setItemCategory(res, true);
      runInAction(() => (this.addingCategory = "success"));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => (this.addingCategory = "faulted"));
      throw error;
    } finally {
      helpers.setSubmitting(false);
    }
  };

  deleteCategory = async (name: string) => {
    try {
      const res = await Api.RemoveSubscriptionCategory({ name });
      if (res === 0 || res === 2) {
        runInAction(() => {
          this.itemsCategoriesMap.delete(name);
          this.filterBy = null;
        });
        return;
      }
      const category = this.itemsCategoriesMap.get(name);
      if (!category) return;
      runInAction(() => {
        category.data.removedAt = new Date();
        category.data.isRemoved = true;
      });
    } catch (err) {
      toast.error(`Couldn't remove category: ${name}`);
      console.error(err);
      throw err;
    }
  };

  restoreCategory = async (name: string) => {
    const category = this.itemsCategoriesMap.get(name);
    if (!category || !category.data.isRemoved) return;
    try {
      const res = await Api.RestoreSubscriptionCategory({ name });
      if (res === RestoreStatus.NotFound) {
        runInAction(() => {
          this.itemsCategoriesMap.delete(name);
          this.filterBy = null;
        });
        return;
      }
      if (res === RestoreStatus.Restored) {
        runInAction(() => {
          category.data.removedAt = null;
          category.data.isRemoved = false;
          this.filterBy = null;
        });
        return;
      }
    } catch (err) {
      toast.error(`Couldn't restore category: ${name}`);
      console.error(err);
      throw err;
    }
  };

  // =========================
  // Subscriptions
  itemsMap = new Map<string, { data: Subscription; detailsLoaded?: boolean | "loading" }>();

  get subscriptions() {
    return _.sortBy(
      Array.from(this.itemsMap.values()).map((x) => x.data),
      (x) => x.name
    );
  }

  itemsByCategory = (category?: Nullable<string>) =>
    category?.length
      ? this.subscriptions.filter((x) => x.categoryName === category)
      : this.subscriptions;

  setItem = (value: Subscription, detailsLoaded = true) => {
    if (value.id) {
      const current = this.itemsMap.get(value.id);
      if (current) {
        current.data = {
          ...current.data,
          ..._.omitBy(value, _.isNull),
        };
        if (!current.detailsLoaded) current.detailsLoaded = detailsLoaded;
      } else this.itemsMap.set(value.id, { data: value, detailsLoaded });
    }
  };

  loadingItems: ApiProcessingStatus = null;
  loadItems = async () => {
    if (this.loadingItems === true) return;
    this.loadingItems = true;

    try {
      const res = await Api.ListSubscription({});
      _.forEach(res, (x) => this.setItem(x));
      runInAction(() => (this.loadingItems = "success"));
    } catch (error) {
      runInAction(() => (this.loadingItems = "faulted"));
      console.error(error);
      throw error;
    }
  };

  addingItem: ApiProcessingStatus = null;
  addItem = async <T>(value: AddSubscriptionData, helpers: FormikHelpers<T>) => {
    if (this.addingItem === true) return;
    this.addingItem = true;
    EmptyFaxErrors(helpers);

    try {
      const res = await Api.AddSubscription(value);
      this.setItem(res);
      history.push(window.location.pathname.replace("/new", `/${res.id}`));
      runInAction(() => (this.addingItem = "success"));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => (this.addingItem = "faulted"));
    }
  };

  removingItem: ApiProcessingStatus = null;
  removeItem = async (id: string) => {
    if (this.removingItem === true) return;
    this.removingItem = true;

    const existsLocally = this.itemsMap.get(id);
    if (!existsLocally) {
      this.removingItem = "success";
      history.push("..");
      return;
    }

    try {
      const res = await Api.RemoveSubscription({ id });
      if (res === RemovalStatus.NotFound || res === RemovalStatus.Removed) {
        runInAction(() => {
          this.itemsMap.delete(id);
          this.removingItem = "success";
        });
        history.push("..");
        return;
      } else {
        runInAction(() => {
          existsLocally.data.isRemoved = true;
          existsLocally.data.removedAt = new Date();
        });
      }
      runInAction(() => (this.removingItem = "success"));
    } catch (error) {
      runInAction(() => (this.removingItem = "faulted"));
    }
  };

  restoringItem: ApiProcessingStatus = null;
  restoreItem = async (id: string) => {
    if (this.restoringItem === true) return;
    this.restoringItem = true;

    const existsLocally = this.itemsMap.get(id);
    if (!existsLocally) {
      this.restoringItem = "faulted";
      history.push("..");
      return;
    }

    if (!existsLocally.data.isRemoved) {
      this.restoringItem = "success";
      return;
    }

    try {
      const res = await Api.RestoreSubscription({ id });
      if (res === RestoreStatus.NotFound) {
        runInAction(() => {
          this.itemsMap.delete(id);
        });
        history.push("..");
      } else if (res === RestoreStatus.Restored) {
        runInAction(() => {
          existsLocally.data.isRemoved = false;
          existsLocally.data.removedAt = null;
        });
      }
      runInAction(() => {
        this.restoringItem = "success";
      });
    } catch (error) {
      runInAction(() => (this.restoringItem = "faulted"));
    }
  };

  editingItem: ApiProcessingStatus = null;
  editItem = async <T>(data: Subscription, helpers: FormikHelpers<T>) => {
    if (this.editingItem === true) return;
    this.editingItem = true;
    EmptyFaxErrors(helpers);

    const existsLocally = this.itemsMap.get(data.id);
    if (!existsLocally) {
      this.editingItem = "faulted";
      history.push("..");
      return;
    }

    try {
      const res = await Api.EditSubscription({ data });
      this.setItem(res);
      runInAction(() => {
        this.editingItem = "success";
      });
      history.push(window.location.pathname.replace("/edit", ""));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => (this.editingItem = "faulted"));
    }
  };

  readonly rootStore: RootStore;
  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.rootStore = root;
  }
}
