import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
//import Leaf from "Components/Project/LeafOld";
import LoaderSection from "Components/UI/LoaderSection";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect } from "react";
import { ProjectDetails } from "App/Api/ProjectAgent";

export default observer(function ProjectsList() {
  const { projects, loadingItems, loadItems } = useStore("ProjectStore");
  useEffect(() => {
    if (!loadingItems) loadItems();
  });

  return (
    <>
      <Summary>
        {loadingItems === true && <LoaderSection title="Loading Projects..." />}
        {loadingItems === "success" && (
          <For each={projects}>
            {(item) => (
              <>
                <ProjectSummary project={item} />
              </>
            )}
          </For>
        )}
      </Summary>
    </>
  );
});

function ProjectSummary(props: { project: ProjectDetails }) {
  const { getItem } = useStore("CompanyStore");

  return (
    <Summary.Card key={props.project.id} to={props.project.id}>
      <Summary.Card.Title>{props.project.name}</Summary.Card.Title>
      {props.project.name && (
        <Summary.Card.Detail
          icon={solid("info-circle")}
          title="Details:"
          info={props.project.name}
        />
      )}
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Company:"
        info={getItem(props.project.companyId)?.data.name}
      />
      <Summary.Card.Detail />
      <Summary.Card.FooterImage fallbackIcon={solid("building")} />
    </Summary.Card>
  );
}
