import {
  DetailedHTMLProps,
  HTMLAttributes,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import ClassNames from "Utils/ClassNames";
import TableStyles from "./Table.module.css";

function Table(props: TableProps) {
  const { className, ...rest } = props;
  return <table className={ClassNames(className, TableStyles.table)} {...rest} />;
}

export function TableRow(props: TableRowProps) {
  const { className, ...rest } = props;
  return <tr className={ClassNames(className, TableStyles.tableRow)} {...rest} />;
}

Table.Row = TableRow;

export function TableHeading(props: TableSectionProps) {
  const { className, ...rest } = props;
  return (
    <thead className={ClassNames(className, TableStyles.tableHeadingSection)} {...rest} />
  );
}

Table.Heading = TableHeading;

export function TableHeadRow(props: TableRowProps) {
  const { className, ...rest } = props;
  return (
    <Table.Row className={ClassNames(className, TableStyles.tableHeadingRow)} {...rest} />
  );
}

TableHeading.Row = TableHeadRow;

export function TableHeadingCell(props: TableHeadingCellProps) {
  const { className, ...rest } = props;
  return <th className={ClassNames(className, TableStyles.tableHeadingCell)} {...rest} />;
}

TableHeading.Cell = TableHeadingCell;

export function TableData(props: TableSectionProps) {
  const { className, ...rest } = props;
  return <tbody className={ClassNames(className, TableStyles.tableDataSection)} {...rest} />;
}

Table.Data = TableData;

export function TableDataRow(props: TableRowProps) {
  const { className, ...rest } = props;
  return <Table.Row className={ClassNames(className, TableStyles.tableDataRow)} {...rest} />;
}

TableData.Row = TableDataRow;

export function TableDataCell(props: TableDataCellProps) {
  const { className, ...rest } = props;
  return <td className={ClassNames(className, TableStyles.tableDataCell)} {...rest} />;
}

TableData.Cell = TableDataCell;

export function TableFooter(props: TableSectionProps) {
  const { className, ...rest } = props;
  return (
    <tfoot className={ClassNames(className, TableStyles.tableFooterSection)} {...rest} />
  );
}

Table.Footer = TableFooter;

export function TableFooterRow(props: TableRowProps) {
  const { className, ...rest } = props;
  return (
    <Table.Row className={ClassNames(className, TableStyles.tableFooterRow)} {...rest} />
  );
}

TableFooter.Row = TableFooterRow;

export function TableFooterCell(props: TableDataCellProps) {
  const { className, ...rest } = props;
  return <td className={ClassNames(className, TableStyles.tableFooterCell)} {...rest} />;
}

TableFooter.Cell = TableFooterCell;

export { Table, TableStyles };

export type TableProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>;

export type TableSectionProps = DetailedHTMLProps<
  HTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
>;

export type TableRowProps = DetailedHTMLProps<
  HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>;

export type TableHeadingCellProps = DetailedHTMLProps<
  ThHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;

export type TableDataCellProps = DetailedHTMLProps<
  TdHTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>;
