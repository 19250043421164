import Button, { ButtonProps } from "../UI/Button";
import { FormikSubmitField } from "./FormikSubmitField";

export default function FormikSubmitButton(
  props: Omit<ButtonProps, "type"> & { isValid: boolean }
) {
  const { onSubmit, onClick, ...rest } = props;
  return (
    <FormikSubmitField>
      {({ handleSubmit }) => (
        <>
          <Button
            {...rest}
            type="submit"
            onClick={(e) => {
              if (!handleSubmit(e)) return false;
              if (onClick) onClick(e);
            }}
            onSubmit={(e) => {
              if (!handleSubmit(e)) return false;
              if (onSubmit) onSubmit(e);
            }}
          />
        </>
      )}
    </FormikSubmitField>
  );
}
