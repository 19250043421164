import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PopUpActionStatus, PopUpStore, usePopUp } from "Components/Contexts/PopUpContext";
import { FlexRow } from "Components/Grid/FlexBox";
import FontAwesome from "Components/UI/FontAwesome";
import Spinner from "Components/UI/Spinner";
import ClassNames from "Utils/ClassNames";
import { Nullable } from "Utils/HelperTypes";
import { DialogProps, DivProps, ParagraphProps } from "Utils/ReactElementsTypes";
import { ButtonProps } from "Utils/ReactElementsTypes";
import { NodeOrJsxChild, RenderNodeOrJsxChild } from "Utils/NodeOrJsx";

function PopUpModal(
  props: Omit<DivProps, "children"> & { children?: NodeOrJsxChild<PopUpStore> }
) {
  const popUpStore = usePopUp();
  if (!popUpStore.isOpen) return null;
  const { className, children, ...rest } = props;
  return (
    <div
      {...rest}
      className={ClassNames(
        "visible fixed block top-2 right-2 bottom-2 left-2 z-[999999] p-4",
        "rounded-tl-leaf-sm rounded-br-leaf-sm cursor-default w-fit h-fit min-w-[316px] max-w-[320px] sm:max-w-[400px] max-h-[98vh]",
        "text-start",
        className
      )}
      style={{ zIndex: 999998 }}
    >
      {RenderNodeOrJsxChild(popUpStore, children)}
    </div>
  );
}

function PopUpDialog(
  props: Omit<DialogProps, "children"> & { children?: NodeOrJsxChild<PopUpStore> }
) {
  const popUpStore = usePopUp();
  if (!popUpStore.isOpen) return null;
  const { className, children, ...rest } = props;
  return (
    <dialog
      {...rest}
      className={ClassNames(
        "visible fixed block top-2 right-2 bottom-2 left-2 z-[999999] p-4",
        "rounded-tl-leaf-sm rounded-br-leaf-sm cursor-default w-fit h-fit min-w-[316px] max-w-[320px] sm:max-w-[400px] max-h-[98vh]",
        "text-start",
        className
      )}
      style={{ zIndex: 999999 }}
    >
      {RenderNodeOrJsxChild(popUpStore, children)}
    </dialog>
  );
}

function ModalDialogHeading(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <FlexRow className={ClassNames("border-b border-gray-3 pb-3", className)} {...rest} />
  );
}

PopUpModal.Heading = ModalDialogHeading;
PopUpDialog.Heading = ModalDialogHeading;

function ModalDialogTitle(props: ParagraphProps) {
  const { className, ...rest } = props;
  return (
    <p {...rest} className={ClassNames("inline mr-3 text-2xl text-secondary-5", className)} />
  );
}

PopUpModal.Title = ModalDialogTitle;
PopUpDialog.Title = ModalDialogTitle;

function ModalDialogCloseButton() {
  const {
    id,
    close,
    actions: { cancel },
  } = usePopUp();
  return (
    <button
      id={id + "close-btn"}
      className={ClassNames(
        "transition-all ml-auto px-2 w-8 h-8",
        "rounded-tl-xl rounded-br-xl focus:rounded-tr-xl focus:rounded-bl-xl focus:rounded-tl-none focus:rounded-br-none",
        "border border-gray-4 text-gray-4",
        "hover:text-gray-5 hover:border-gray-5",
        "focus:text-gray-6 focus:border-gray-6 focus-visible:outline-dashed focus-visible:outline-2",
        "active:text-gray-7 active:border-gray-7"
      )}
      type="button"
      autoFocus
      onClick={() => {
        if (cancel) cancel().then(() => close());
        else close();
      }}
    >
      <FontAwesome icon={solid("close")} size="lg" />
    </button>
  );
}

PopUpModal.CloseButton = ModalDialogCloseButton;
PopUpDialog.CloseButton = ModalDialogCloseButton;

function ModalDialogDetails(props: DivProps) {
  const { className, ...rest } = props;
  return (
    <div {...rest} className={ClassNames("py-3 pr-4 overflow-y-auto h-fit", className)} />
  );
}

PopUpModal.Details = ModalDialogDetails;
PopUpDialog.Details = ModalDialogDetails;

function ModalDialogMessage(props: ParagraphProps) {
  const { className, ...rest } = props;
  return <p {...rest} className={ClassNames(className, "text-lg text-secondary-7/80")} />;
}

PopUpModal.Message = ModalDialogMessage;
PopUpDialog.Message = ModalDialogMessage;

function ModalDialogFooter(props: DivProps) {
  const { className, ...rest } = props;
  return <div {...rest} className={ClassNames("border-t border-gray-3 py-2", className)} />;
}

PopUpModal.Footer = ModalDialogFooter;
PopUpDialog.Footer = ModalDialogFooter;

export type PopUpActionButtonOnClick = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  store: PopUpStore
) => void;

export type ModalDialogActionButtonProps = Omit<ButtonProps, "onClick" | "className"> & {
  onClick?: PopUpActionButtonOnClick;
  className?: string | ((prop: Nullable<PopUpActionStatus>) => string);
};

function ModalDialogActionButton(props: ModalDialogActionButtonProps) {
  const { className, onClick, ...rest } = props;
  const popUpStore = usePopUp();
  return (
    <button
      className={ClassNames(
        "group transition-all",
        "min-w-[94px] py-1 px-4 mt-1 mx-1.5 float-right group",
        "rounded-br-3xl rounded-tl-3xl",
        "hover:rounded-br-none hover:rounded-tl-none",
        "hover:rounded-bl-3xl hover:rounded-tr-3xl",
        "focus:rounded-br-none focus:rounded-tl-none",
        "focus:rounded-bl-3xl focus:rounded-tr-3xl",
        typeof className === "function" ? className(popUpStore.actionStatus) : className
      )}
      onClick={(e) => (onClick ? onClick(e, popUpStore) : null)}
      {...rest}
    />
  );
}

PopUpModal.ActionButton = ModalDialogActionButton;
PopUpDialog.ActionButton = ModalDialogActionButton;

function ModalDialogActionButtonOk(props: Omit<ModalDialogActionButtonProps, "id">) {
  const { id, actionStatus } = usePopUp();
  const { children, onClick, ...rest } = props;
  return (
    <ModalDialogActionButton
      disabled={actionStatus?.includes("Processing")}
      id={id + "ok-btn"}
      onClick={(e, h) => {
        if (onClick) {
          onClick(e, h);
          return;
        }

        h.setActionStatus("Processing-ok");

        if (!h.actions.ok) {
          h.setActionStatus("Done-ok");
          h.close();
          return;
        }

        h.actions
          .ok()
          .then(() => {
            h.setActionStatus("Done-ok");
            h.close();
          })
          .catch(() => {
            h.setActionStatus("Faulted-ok");
          });
      }}
      {...rest}
    >
      {actionStatus === "Processing-ok" ? <Spinner size="lg" /> : children}
    </ModalDialogActionButton>
  );
}

ModalDialogActionButton.Ok = ModalDialogActionButtonOk;

function ModalDialogActionButtonDelete(props: { onClick?: PopUpActionButtonOnClick }) {
  return (
    <ModalDialogActionButton.Ok
      className={(status) =>
        ClassNames(
          "border border-danger-5",
          "enabled:hover:bg-danger-6 enabled:focus:bg-danger-7 enabled:active:bg-danger-8",
          "enabled:hover:border-danger-6 enabled:focus:border-danger-7 enabled:active:border-danger-8",
          {
            "bg-danger-5 text-white": !status?.includes("roces"),
            "text-danger-6": status === "Processing-ok",
          }
        )
      }
      onClick={props.onClick}
    >
      <FontAwesome icon={solid("trash")} size="lg" className="group-hover:inline hidden" />
      <span className="group-hover:hidden inline ">Delete</span>
    </ModalDialogActionButton.Ok>
  );
}

ModalDialogActionButton.Delete = ModalDialogActionButtonDelete;

function ModalDialogActionButtonCancel(
  props: Omit<ModalDialogActionButtonProps, "id" | "children">
) {
  const { id, actionStatus } = usePopUp();
  const { onClick, ...rest } = props;
  return (
    <ModalDialogActionButton
      className={(status) =>
        ClassNames(
          "border border-gray-5",
          "enabled:hover:bg-gray-6 enabled:focus:bg-gray-7 enabled:active:bg-gray-8",
          "enabled:hover:border-gray-6 enabled:focus:border-gray-7 enabled:active:border-gray-8",
          {
            "bg-gray-5 text-white": !status?.includes("roces"),
            "text-gray-6": status === "Processing-cancel",
          }
        )
      }
      disabled={actionStatus?.includes("Processing")}
      hidden={actionStatus === "Processing-ok"}
      id={id + "cancel-btn"}
      onClick={(e, h) => {
        if (onClick) {
          onClick(e, h);
          return;
        }

        h.setActionStatus("Processing-cancel");

        if (!h.actions.cancel) {
          h.setActionStatus("Done-cancel");
          h.close();
          return;
        }

        h.actions
          .cancel()
          .then(() => {
            h.setActionStatus("Done-cancel");
            h.close();
          })
          .catch(() => {
            h.setActionStatus("Faulted-cancel");
          });
      }}
      {...rest}
    >
      {actionStatus === "Processing-cancel" && <Spinner size="lg" />}
      {actionStatus !== "Processing-cancel" && (
        <>
          <FontAwesome icon={solid("x")} size="lg" className="group-hover:inline hidden" />
          <span className="group-hover:hidden inline ">Cancel</span>
        </>
      )}
    </ModalDialogActionButton>
  );
}

ModalDialogActionButton.Cancel = ModalDialogActionButtonCancel;

export { PopUpModal, PopUpDialog };
