import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import { VairantStyle, Width } from "./UI";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: VairantStyle;
  width?: Width;
  shadow?: boolean;
  rounded?: boolean | "full";
}

export default function Button(props: ButtonProps) {
  const { variant, width, rounded, shadow, className, ...rest } = props;
  return (
    <button
      {...rest}
      className={classNames(
        className,
        "font-bold",
        "py-2 px-5",
        "transition-all",
        {
          "rounded-full": rounded === "full",
          "rounded-md": rounded === true,
        },
        { "shadow-ui": shadow },
        [
          { "w-full": width === "full" },
          { "w-64": width === "1" },
          { "w-32": width === "2" },
          { "w-20": width === "3" },
        ],
        [
          {
            [classNames(
              "bg-primary-5 bg-gradient-to-l",
              "from-gray-2 text-gray-1",
              "hover:from-secondary-5 focus-visible:from-secondary-4",
              "active:bg-secondary-6",
              [
                "disabled:bg-primary-7 disabled:hover:from-gray-2",
                "disabled:focus:bg-primary-9 disabled:focus:from-gray-2",
              ]
            )]: variant === "primary-gradient",
            [classNames(
              "bg-secondary-5 bg-gradient-to-l from-gray-2 text-white",
              "hover:from-accent-5 focus-visible:from-accent-4",
              "active:bg-accent-6",
              [
                "disabled:bg-secondary-7 disabled:hover:from-gray-2",
                "disabled:focus:bg-secondary-9 disabled:focus:from-gray-2",
              ]
            )]: variant === "secondary-gradient",
            [classNames(
              "bg-accent-5 bg-gradient-to-l from-gray-2 text-white",
              "hover:from-primary-5 focus-visible:from-primary-4",
              "active:bg-primary-6",
              [
                "disabled:bg-accent-7 disabled:hover:from-gray-2",
                "disabled:focus:bg-accent-9 disabled:focus:from-gray-2",
              ]
            )]: variant === "accent-gradient",
          },
        ]
      )}
    />
  );
}
