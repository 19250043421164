import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Form, Formik } from "formik";
import FormikAlert from "Components/Forms/FormikAlert";
import FormikSubmitButton from "Components/Forms/FormikSubmitButton";
import FormikTextInput from "Components/Forms/FormikTextInput";
import FontAwesome from "Components/UI/FontAwesome";
import { WithFormikErrors } from "Utils/FormikErrorUtils";
import { observer } from "mobx-react-lite";
import * as AccountAgent from "App/Api/AccountAgent";
import { NavLink } from "react-router-dom";
import { useStore } from "App/Stores/StoreContext";
import useQuery from "Utils/ReactUtils";

export default observer(function PassResetForm() {
  const { resetPassword, accountProcessingResult } = useStore("AccountStore");
  const Query = useQuery();

  if (accountProcessingResult === "success")
    return (
      <>
        <h2 className="font-jaldi text-primary-7 text-4xl mb-8">New Password!</h2>
        <h2 className="font-jaldi text-primary-7 text-xl mb-8 max-w-md text-center ">
          You{"'"}re password is reset successfully!
          <hr className="my-4" />
          <NavLink
            to="/login"
            className="text-secondary-4 hover:text-primary-5 focus:text-primary-7 border rounded-full px-2 py-1 block border-primary-6"
          >
            Login!
          </NavLink>
        </h2>
      </>
    );

  return (
    <>
      <h2 className="font-jaldi text-primary-7 text-4xl mb-8">New Password!</h2>
      <Formik
        validationSchema={AccountAgent.ResetPasswordDataValidator}
        initialValues={WithFormikErrors<AccountAgent.ResetPasswordData>({
          email: Query.get("email"),
          token: Query.get("token"),
          newPassword: null,
          confirmNewPassword: null,
        })}
        onSubmit={resetPassword}
        validateOnBlur
        validateOnChange
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit} className="w-full px-3 max-w-sm">
            <FormikTextInput
              name="newPassword"
              placeholder="New Password"
              type="password"
              icon={{ prop: solid("key"), position: "pre" }}
              border={{ width: 1, rounded: "full" }}
              showInvalid
              shadow
            />
            <FormikTextInput
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              type="password"
              icon={{ prop: solid("key"), position: "pre" }}
              border={{ width: 1, rounded: "full" }}
              showInvalid
              shadow
            />
            <FormikAlert className="mb-6" />
            <FormikSubmitButton
              disabled={isSubmitting}
              variant="primary-gradient"
              isValid={isValid}
              width="full"
              shadow
              rounded="full"
            >
              {isSubmitting && (
                <FontAwesome className="mr-2 animate-spin" icon={solid("spinner")} />
              )}
              Send
            </FormikSubmitButton>
            <div className="mt-4">
              Remembered your password?
              <strong>
                <NavLink
                  to="/login"
                  className="text-secondary-4 hover:text-primary-5 focus:text-primary-7"
                >
                  {" "}
                  Login!
                </NavLink>
              </strong>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
});
