import { AddressValidator } from "App/Models";
import { useStore } from "App/Stores/StoreContext";
import FormikAlert from "Components/Forms/FormikAlert";
import { FormikSubmitField } from "Components/Forms/FormikSubmitField";
import AddEdit from "Components/Project/AddEdit";
import LoaderSection from "Components/UI/LoaderSection";
import { Form, Formik } from "formik";
import { pushToNotFound } from "index";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

function UserAddress() {
  const { getItem, addEditAddress } = useStore("UsersStore");
  const itemId = useParams()["userEmail"] ?? pushToNotFound();
  const item = useMemo(() => getItem(itemId), [itemId, getItem]);

  return (
    <>
      <AddEdit>
        {!item && <LoaderSection title="Loading item..." />}
        {item && (
          <Formik
            onSubmit={(v, h) => {
              h.setSubmitting(true);
              addEditAddress({ address: v, email: itemId }, h).finally(() => {
                h.setSubmitting(false);
              });
            }}
            initialValues={
              item.address ?? {
                country: "",
                provinceState: "",
                city: null,
                building: null,
                street: null,
                postalZipCode: null,
              }
            }
            validationSchema={AddressValidator}
            enableReinitialize
          >
            {({ handleSubmit, handleBlur, handleChange, handleReset }) => (
              <Form
                onSubmit={handleSubmit}
                onBlur={handleBlur}
                onReset={handleReset}
                onChange={handleChange}
              >
                <AddEdit.TextField name="country" placeholder="country" label="country" />
                <AddEdit.TextField name="provinceState" placeholder="State" label="State" />
                <AddEdit.TextField name="City" placeholder="City" label="City" />
                <AddEdit.TextField name="building" placeholder="Building" label="Building" />
                <AddEdit.TextField name="street" placeholder="Street" label="Street" />
                <AddEdit.TextField
                  name="postalZipCode"
                  placeholder="Zip Code"
                  label="Zip Code"
                />

                <FormikAlert />

                <AddEdit.Actions>
                  <FormikSubmitField>
                    {({ field, isSubmitting }) => (
                      <AddEdit.Actions.Save {...field} disabled={isSubmitting} />
                    )}
                  </FormikSubmitField>
                  <AddEdit.Actions.Cancel />
                </AddEdit.Actions>
              </Form>
            )}
          </Formik>
        )}
      </AddEdit>
    </>
  );
}

export default observer(UserAddress);
