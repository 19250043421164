import { EnumObject, EnumKeys, EnumValues } from "Utils";
import { boolean, date, mixed, number, string } from "yup";
import { Enum } from "./HelperTypes";
import ValidationMessages, { MaxStringMessage, MinStringMessage } from "./ValidationMessages";
import { PhoneRegex } from "./ValidationRegex";

export function RequiredString(propName: string) {
  return string().nullable().required(ValidationMessages.Required(propName));
}

export function RequiredEmail(propName = "Email") {
  return RequiredString(propName).email(ValidationMessages.Email(propName));
}

export function RequiredName(propName = "Name", maxLength = 64) {
  return RequiredString(propName).max(
    maxLength,
    ValidationMessages.MaxString(propName, maxLength)
  );
  //  RequiredString(propName).matches(NameRegex, ValidationMessages.Name(propName));
}

export function OptionalName(propName = "Name", maxLength = 64) {
  return string()
    .nullable()
    .optional()
    .max(maxLength, ValidationMessages.MaxString(propName, maxLength));
  //  RequiredString(propName).matches(NameRegex, ValidationMessages.Name(propName));
}

export function RequiredEnum<T extends Enum>(propName: string, enums: T) {
  return mixed()
    .required()
    .nullable()
    .oneOf(
      [...EnumKeys(enums), ...EnumValues(enums, "number")],
      ValidationMessages.OneOfEnum(propName)
    );
}

export function Money(propName = "Amount") {
  return number()
    .required()
    .positive(ValidationMessages.PositiveValue(propName))
    .max(999999999.99, ValidationMessages.MaxValue(propName, 999999999.99));
}

export function Currency(propName = "Currency") {
  return RequiredString(propName).default("USD");
}

export function ComplexPassword(propName = "Password") {
  return RequiredString(propName)
    .min(8, MinStringMessage(propName, 8))
    .max(64, MaxStringMessage(propName, 64))
    .matches(/[A-Z]/, propName + " must contain an upper case.")
    .matches(/[a-z]/, propName + " must contain a lower case.")
    .matches(/\d/, propName + " must contain a number.")
    .matches(/\W/, propName + "must contain special character.");
}

export function OptionalDetails(propName = "Details") {
  return string().nullable().optional().max(256, ValidationMessages.MaxString(propName, 256));
}

export function OptionalEmail(propName = "Email") {
  return string().nullable().optional().email(ValidationMessages.Email(propName));
}

export function OptionalPhone(propName = "Phone") {
  return string()
    .nullable()
    .optional()
    .matches(PhoneRegex, ValidationMessages.NotAPhoneNumber(propName))
    .max(24, ValidationMessages.MaxString(propName, 24));
}

export function RequiredDate(propName: string) {
  return date().nullable().required(ValidationMessages.Required(propName));
}

export function OptionalDate() {
  return date().nullable().optional();
}

export function RequiredBoolean(propName: string, def = false) {
  return boolean().required(ValidationMessages.Required(propName)).default(def);
}

export function OptionalBoolean(def = false) {
  return boolean().optional().default(def);
}

export function IsTrue(propName: string, message: string, def = false) {
  return RequiredBoolean(propName, def).isTrue(message);
}

export function IsFalse(propName: string, message: string, def = false) {
  return RequiredBoolean(propName, def).isFalse(message);
}

export default {
  RequiredString,
  RequiredEmail,
  RequiredName,
  OptionalName,
  ComplexPassword,
  RequiredEnum,
  Money,
  OptionalDetails,
  OptionalEmail,
  OptionalPhone,
  Currency,
  RequiredDate,
  OptionalDate,
  RequiredBoolean,
  IsTrue,
  IsFalse,
};
