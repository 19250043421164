import { observer } from "mobx-react-lite";
import { useStore } from "App/Stores/StoreContext";
import { For } from "Utils/For";
import Summary from "Components/Project/Summary";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Details from "Components/Project/Details";
import PopUp from "Components/Project/PopUp";
import { useParams } from "react-router-dom";
import { FormatDate } from "Utils/DateUtils";
import { Nullable } from "Utils/HelperTypes";
import { ModeratorRole, RemovalStatus } from "App/Models";
import { ProjectProductDetails, ProjectSubscriptionDetails } from "App/Api/ProjectAgent";
import { ProjectModeratorDetails } from "App/Api/ProjectAgent";
import { NavLink } from "react-router-dom";
import ClassNames from "Utils/ClassNames";

export default observer(function ProjectDetails() {
  const id = useParams()["projId"]!;
  const { getItem, deleteItem } = useStore("ProjectStore");
  const { getItem: getComp } = useStore("CompanyStore");
  const item = getItem(id);
  const data = item!.data;

  return (
    <Details>
      <Details.Item>
        <Details.Item.Main>
          <Details.Item.Main.Title>{data.name}</Details.Item.Main.Title>
          <Details.Item.Main.DetailsList
            values={[
              {
                icon: solid("info"),
                title: "Created On",
                info: FormatDate(data.createdAt) ?? "-",
              },
            ]}
          />
          <Details.Item.Main.Actions>
            <Details.Item.Main.Actions.Edit />
            <PopUp
              actions={{
                ok: () =>
                  deleteItem(data.id).then((r) =>
                    r === RemovalStatus.RequireFinalization ? 0 : 1
                  ),
              }}
            >
              <PopUp.Open.AsDeleteButton />
              <PopUp.DeleteDialog
                title="Deleting Project!"
                message={`Are you sure you want to remove '${
                  data.name ?? ""
                }' from projects?`}
              />
            </PopUp>
          </Details.Item.Main.Actions>
        </Details.Item.Main>
        <Details.Item.Extra>
          <NavLink to={`/app/companies/${data.companyId}`} role="button">
            <Details.Item.Extra.Detail>
              <Details.Item.Extra.Detail.Title>Company: </Details.Item.Extra.Detail.Title>
              <Details.Item.Extra.Detail.Info>
                {getComp(data.companyId)?.data.name ?? "-"}
              </Details.Item.Extra.Detail.Info>
            </Details.Item.Extra.Detail>
          </NavLink>
        </Details.Item.Extra>
      </Details.Item>
      <Details.SubDetails>
        {/* <ProjectProjectDetails id={data.id} details={data.projects} /> */}
        <ProjectSubscriptionDetailsSection id={data.id} details={data.subscriptions} />
        <ProjectProductDetailsSection id={data.id} details={data.products} />
        <ProjectModeratorDetailsSection id={data.id} details={data.moderators} />
      </Details.SubDetails>
    </Details>
  );
});

function ProjectModeratorDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectModeratorDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-moderator`} />
        <Details.ChildList.Legend.Heading>Moderators</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectModeratorSummary}</For>
    </Details.ChildList>
  );
}

function ProjectModeratorSummary(item: ProjectModeratorDetails) {
  return (
    <Summary.Card key={item.id}>
      <Summary.Card.Title>{item.moderator?.displayName}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Role"
        info={ModeratorRole[item.role]}
      />
      <Summary.Card.Detail>
        <Summary.Card.Detail.Icon icon={solid("at")} />
        <Summary.Card.Detail.Title>Email</Summary.Card.Detail.Title>
        <Summary.Card.Detail.Info>
          <a href={`mailto:${item.moderator?.email ?? ""}`}>{item.moderator?.email}</a>
        </Summary.Card.Detail.Info>
      </Summary.Card.Detail>
      <Summary.Card.Detail>
        <Summary.Card.Detail.Icon icon={solid("phone")} />
        <Summary.Card.Detail.Title>Phone</Summary.Card.Detail.Title>
        <Summary.Card.Detail.Info>
          {item.moderator?.phoneNumber ? (
            <a href={`tel:${item.moderator?.phoneNumber ?? ""}`}>
              {item.moderator?.phoneNumber}
            </a>
          ) : (
            "-"
          )}
        </Summary.Card.Detail.Info>
      </Summary.Card.Detail>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />

      <SummaryActions
        email={item.moderator?.email ?? ""}
        modId={item.id}
        projId={item.projectId}
      />
    </Summary.Card>
  );
}

function SummaryActions(props: { email: string; modId: string; projId: string }) {
  const { removeModerator } = useStore("ProjectStore");
  return (
    <div>
      <NavLink to={`/app/users/${props.email}`}>
        <button
          className={ClassNames(
            "group transition-all",
            "px-4 py-2 min-w-[94px]",
            "rounded-full font-bold",
            "bg-gray-5 text-white",
            "hover:bg-gray-6 focus-visible:bg-gray-7 active:bg-gray-7"
          )}
        >
          Details
        </button>
      </NavLink>
      <PopUp
        actions={{
          ok: () => removeModerator(props.modId, props.projId).then(() => true),
        }}
      >
        <PopUp.Open.AsDeleteButton className="float-right" />
        <PopUp.DeleteDialog
          title="Deleting Moderator!"
          message={`Are you sure you want to remove moderator '${props.email}' from this company?`}
        />
      </PopUp>
    </div>
  );
}

function ProjectSubscriptionDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectSubscriptionDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-subscription`} />
        <Details.ChildList.Legend.Heading>Subscriptions</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectSubscriptionSummary}</For>
    </Details.ChildList>
  );
}

function ProjectSubscriptionSummary(item: ProjectSubscriptionDetails) {
  return (
    <Summary.Card key={item.id} to={`sub/${item.id}`}>
      <Summary.Card.Title>{item.subscription?.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category"
        info={item.subscription?.categoryName}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Start On"
        info={FormatDate(item.startAt)}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="End On"
        info={FormatDate(item.endAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}

function ProjectProductDetailsSection(props: {
  id?: Nullable<string>;
  details?: Nullable<ProjectProductDetails[]>;
}) {
  return (
    <Details.ChildList>
      <Details.ChildList.Legend>
        <Details.ChildList.Legend.AddButton to={`new-product`} />
        <Details.ChildList.Legend.Heading>Products</Details.ChildList.Legend.Heading>
      </Details.ChildList.Legend>
      <For each={props.details}>{ProjectProductSummary}</For>
    </Details.ChildList>
  );
}

function ProjectProductSummary(item: ProjectProductDetails) {
  return (
    <Summary.Card key={item.id} to={`prd/${item.id}`}>
      <Summary.Card.Title>{item.product?.name}</Summary.Card.Title>
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Category"
        info={item.product?.categoryName}
      />
      <Summary.Card.Detail
        icon={solid("circle")}
        title="Created At"
        info={FormatDate(item.createdAt)}
      />
      <Summary.Card.FooterImage fallbackIcon={solid("briefcase")} asDetail />
    </Summary.Card>
  );
}
