import {
  AddEditUserAddressData,
  CreateNewUserData,
  RegisterNewAdminData,
  UpdateUserData,
} from "App/Api/UserManagementAgent";
import { ApiProcessingError, ApiProcessingStatus, UserDetailsToAdmin } from "App/Models";
import { EmptyFaxErrors, FaxErrorHandler } from "Utils/FormikErrorUtils";
import { FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import _ from "lodash";
import { MaybeNull, Nullable } from "Utils/HelperTypes";
import { RootStore } from "./Stores";
import { history } from "index";
import { Api } from "App/Api/UserManagementAgent";

export class UsersStore {
  // =========================
  // Users
  usersMap = new Map<MaybeNull<string>, UserDetailsToAdmin>();
  getItem = (email: string) => this.usersMap.get(email);
  getNewOrExistingItem = (email?: Nullable<string>): UserDetailsToAdmin =>
    email && email.length ? this.usersMap.get(email) ?? this.newItem() : this.newItem();

  private newItem = (): UserDetailsToAdmin => ({
    email: "",
    displayName: "",
    gender: 0,
    firstName: null,
    lastName: null,
  });

  get users() {
    return _.sortBy(Array.from(this.usersMap.values()), (x) => x.email);
  }

  setItem = (value: UserDetailsToAdmin) => {
    const current = this.getItem(value.email);
    this.usersMap.set(value.email, current ? { ...current, ...value } : value);
  };

  loadingItems: ApiProcessingStatus = null;
  loadItems = async () => {
    if (this.loadingItems) return;
    this.loadingItems = true;
    try {
      const res = await Api.ListUsers({});
      res.forEach((x) => this.setItem(x));
      runInAction(() => (this.loadingItems = "success"));
    } catch (er) {
      runInAction(() => (this.loadingItems = "faulted"));
      throw er;
    }
  };

  addingNewItem: ApiProcessingStatus = null;
  addNewItem = async <T>(data: CreateNewUserData, helpers: FormikHelpers<T>) => {
    if (this.addingNewItem === true) return;
    this.addingNewItem = true;
    EmptyFaxErrors(helpers);
    try {
      const res = await Api.CreateNewUser(data);
      this.setItem(res);
      runInAction(() => {
        this.addingNewItem = "success";
      });
      history.push(window.location.pathname.replace("/new", `/${res.email}`));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => {
        this.addingNewItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.log(error);
      throw error;
    }
  };

  editingItem: ApiProcessingStatus = null;
  editItem = async <T>(data: UpdateUserData, faxHelper: FormikHelpers<T>) => {
    if (this.editingItem === true) return;
    this.editingItem = true;

    const item = this.usersMap.get(data.email);

    if (!item) {
      this.editingItem = "faulted";
      return;
    }

    try {
      const res = await Api.UpdateUser(data);
      this.setItem(res);
      runInAction(() => {
        this.editingItem = "success";
      });
      history.push(window.location.pathname.replace("/edit", ""));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, faxHelper);
      runInAction(() => {
        this.editingItem = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.error(error);
      throw error;
    }
  };

  addingNewAdmin: ApiProcessingStatus = null;
  addNewAdmin = async <T>(data: RegisterNewAdminData, helpers: FormikHelpers<T>) => {
    if (this.addingNewAdmin === true) return;
    this.addingNewAdmin = true;
    EmptyFaxErrors(helpers);
    try {
      const res = await Api.RegisterNewAdmin(data);
      this.setItem(res);
      runInAction(() => {
        this.addingNewAdmin = "success";
      });
      history.push(window.location.pathname.replace("/new", `/${res.email}`));
    } catch (error) {
      await FaxErrorHandler(error as AxiosError, helpers);
      runInAction(() => {
        this.addingNewAdmin = new ApiProcessingError(error as AxiosError, "faulted");
      });
      console.log(error);
      throw error;
    }
  };

  updatingAddress: ApiProcessingStatus = null;
  addEditAddress = async <T>(data: AddEditUserAddressData, helpers: FormikHelpers<T>) => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;
    EmptyFaxErrors(helpers);

    // Check Item Exists
    const item = this.usersMap.get(data.email);
    if (!item) {
      this.updatingAddress = "faulted";
      return;
    }

    try {
      await Api.AddEditUserAddress(data);
      runInAction(() => {
        this.usersMap.get(data.email)!.address = data.address;
        this.updatingAddress = "success";
        history.push("..");
      });
    } catch (err) {
      await FaxErrorHandler(err as AxiosError, helpers);
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };
  removeAddress = async (email: string) => {
    if (this.updatingAddress === true) return;
    this.updatingAddress = true;

    // Check Item Exists
    const item = this.usersMap.get(email);
    if (!item) {
      this.updatingAddress = "faulted";
      return;
    }

    // Check Child Exists
    if (!item.address) {
      this.updatingAddress = "success";
      return;
    }

    try {
      await Api.AddEditUserAddress({ email });
      runInAction(() => {
        item.address = null;
        this.updatingAddress = "success";
      });
    } catch (err) {
      runInAction(() => {
        this.updatingAddress = "faulted";
      });
      throw err;
    }
  };

  root: RootStore;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }
}
