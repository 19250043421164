import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { InputHTMLAttributes, MouseEventHandler } from "react";
import ClassNames from "../../Utils/ClassNames";
import FontAwesome from "./FontAwesome";
import { Border, Variant } from "./UI";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: {
    prop: IconProp;
    position: "pre" | "post";
    onClick?: MouseEventHandler<SVGSVGElement>;
  };
  type: "password" | "email" | "text";
  variant?: Variant;
  border: { width: Border; color?: Variant; rounded?: 1 | "full" };
  shadow?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const { icon, shadow, border, variant, className, ...rest } = props;
  return (
    <div
      className={ClassNames(
        "m-0 p-0 w-full",
        {
          "flex flex-row items-start": !!icon,
          "justify-start": icon?.position === "pre",
          "justify-end": icon?.position === "post",
        },
        {
          "text-gray-7 dark:text-gray-3": !variant, // default
          "text-danger-6 dark:text-danger-5": variant === "danger",
          "text-success-6 dark:text-success-5": variant === "success",
          "text-warning-6 dark:text-warning-5": variant === "warning",
          "text-info-6 dark:text-info-5": variant === "info",
          "text-primary-6 dark:text-primary-5": variant === "primary",
          "text-secondary-6 dark:text-secondary-4": variant === "secondary",
          "text-accent-6 dark:text-accent-5": variant === "accent",
        }
      )}
    >
      {icon && (
        <FontAwesome
          className={ClassNames(
            "absolute h-4 pt-[14px]",
            {
              "ml-3": icon.position === "pre",
              "mr-3": icon.position === "post",
            },
            {
              "text-gray-6 dark:text-gray-3": !variant, // default
              "text-danger-5 dark:text-danger-3": variant === "danger",
              "text-success-5 dark:text-success-3": variant === "success",
              "text-warning-5 dark:text-warning-3": variant === "warning",
              "text-info-5 dark:text-info-3": variant === "info",
              "text-primary-5 dark:text-primary-3": variant === "primary",
              "text-secondary-6 dark:text-secondary-3": variant === "secondary",
              "text-accent-6 dark:text-accent-3": variant === "accent",
            }
          )}
          icon={icon.prop}
          onClick={icon.onClick}
          size="lg"
        />
      )}
      <input
        {...rest}
        className={ClassNames(
          className,
          "w-full h-full align-center m-0 text-start",
          "focus:outline-none focus:ring-0 focus-visible:ring-0",
          "bg-white dark:bg-gray-8",
          {
            "placeholder:text-gray-4 ": !variant,
            "placeholder:text-danger-3": variant === "danger",
            "placeholder:text-success-3": variant === "success",
            "placeholder:text-warning-3": variant === "warning",
            "placeholder:text-info-3": variant === "info",
            "placeholder:text-primary-3": variant === "primary",
            "placeholder:text-secondary-3": variant === "secondary",
            "placeholder:text-accent-3": variant === "accent",
          },
          {
            border: border.width === 1 || border.width === "1",
            "border-2": border.width === 2 || border.width === "2",
            "border-0 border-b-2": border.width === "underline",
          },
          {
            "rounded-none": !border.rounded,
            "rounded-md": border.rounded === 1,
            "rounded-full": border.rounded === "full",
          },
          { "pl-8 pr-4": icon?.position === "pre" },
          { "pl-4 pr-8": icon?.position === "post" },
          { "shadow-ui": shadow },
          {
            [ClassNames(
              "border-gray-4 hover:border-gray-6 focus:border-gray-8 ",
              " dark:border-gray-6 dark:hover:border-gray-4 dark:focus:border-gray-2"
            )]: !border.color,
            [ClassNames(
              "border-primary-4 hover:border-primary-6 focus:border-primary-8 ",
              " dark:border-primary-6 dark:hover:border-primary-4 dark:focus:border-primary-2"
            )]: border.color === "primary",
            [ClassNames(
              "border-secondary-4 hover:border-secondary-6 focus:border-secondary-8 ",
              " dark:border-secondary-6 dark:hover:border-secondary-4 dark:focus:border-secondary-2"
            )]: border.color === "secondary",
            [ClassNames(
              "border-accent-4 hover:border-accent-6 focus:border-accent-8 ",
              " dark:border-accent-6 dark:hover:border-accent-4 dark:focus:border-accent-2"
            )]: border.color === "accent",
            [ClassNames(
              "border-danger-4 hover:border-danger-6 focus:border-danger-8 ",
              " dark:border-danger-6 dark:hover:border-danger-4 dark:focus:border-danger-2"
            )]: border.color === "danger",
            [ClassNames(
              "border-success-4 hover:border-success-6 focus:border-success-8 ",
              " dark:border-success-6 dark:hover:border-success-4 dark:focus:border-success-2"
            )]: border.color === "success",
            [ClassNames(
              "border-warning-4 hover:border-warning-6 focus:border-warning-8 ",
              " dark:border-warning-6 dark:hover:border-warning-4 dark:focus:border-warning-2"
            )]: border.color === "warning",
            [ClassNames(
              "border-info-4 hover:border-info-6 focus:border-info-8 ",
              " dark:border-info-6 dark:hover:border-info-4 dark:focus:border-info-2"
            )]: border.color === "info",
          }
        )}
      />
    </div>
  );
}
