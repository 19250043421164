import { UsersStore } from "./usersStore";
import { CommonStore } from "./commonStore";
import { CompanyStore } from "./companyStore";
import { ProjectStore } from "./projectStore";
import { ProductStore } from "./productStore";
import { SubscriptionStore } from "./subscriptionStore";
import { AccountStore } from "./accountStore";
import { DashboardStore } from "./dashboardStore";
import { NotificationStore } from "./notificationStore";

export interface RootStore {
  CommonStore: CommonStore;
  AccountStore: AccountStore;
  NotificationStore: NotificationStore;
  DashboardStore: DashboardStore;
  ProductStore: ProductStore;
  SubscriptionStore: SubscriptionStore;
  CompanyStore: CompanyStore;
  ProjectStore: ProjectStore;
  UsersStore: UsersStore;
}

export class RootStore implements RootStore {
  constructor() {
    this.CommonStore = new CommonStore(this);
    this.AccountStore = new AccountStore(this);
    this.NotificationStore = new NotificationStore(this);
    this.DashboardStore = new DashboardStore(this);
    this.ProductStore = new ProductStore(this);
    this.SubscriptionStore = new SubscriptionStore(this);
    this.CompanyStore = new CompanyStore(this);
    this.ProjectStore = new ProjectStore(this);
    this.UsersStore = new UsersStore(this);
  }

  prepareApp = () => {
    // Dashboard
    this.DashboardStore.loadStatistics();

    // Prepare Notification
    this.NotificationStore.loadNotifications();
    this.NotificationStore.runHubConnection();

    // Prepare Companies
    this.CompanyStore.loadItems();

    // Prepare Products
    this.ProductStore.loadCategories();
    this.ProductStore.loadItems();

    // Prepare Categories
    this.SubscriptionStore.loadCategories();
    this.SubscriptionStore.loadItems();
  };
}

const Stores = new RootStore();

export default Stores;
